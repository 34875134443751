import {
  FloatingFocusManager,
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { type PropsWithChildren, useState } from "react";
import { createPortal } from "react-dom";
import type { SearchResult } from "~features/search/schemas";
import { useWindowWidth } from "~hooks/use-window-width";
import { cn } from "~utils/class-names";
import styles from "./hover-tooltip.module.scss";

type HoverTooltipProps = PropsWithChildren<{
  link: string;
  source?: SearchResult.WebSource;
  className?: string;
}>;

// @todo composition으로 리팩토링 - trigger-tooltip 분리
export function HoverTooltip({
  link,
  source,
  children,
  className,
}: HoverTooltipProps) {
  const { isMobileSmallSize } = useWindowWidth();
  const [isOpen, setIsOpen] = useState(false);
  const { refs, context, floatingStyles } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "bottom-start",
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({ mainAxis: 8, crossAxis: 0 }),
      flip({ fallbackAxisSideDirection: "end" }),
      shift(),
    ],
  });

  const hover = useHover(context, {
    mouseOnly: true,
    delay: { open: 0, close: 60 },
  });

  const dismiss = useDismiss(context, {});
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    dismiss,
    role,
  ]);

  return (
    <>
      <div
        ref={refs.setReference}
        {...getReferenceProps()}
        className={className}
      >
        {children}
      </div>

      {!isMobileSmallSize && isOpen && source && (
        <FloatingFocusManager context={context} modal={false}>
          {createPortal(
            <a
              ref={refs.setFloating}
              href={link}
              style={floatingStyles}
              className={cn(styles.source_box)}
              {...getFloatingProps()}
            >
              <div className={styles.source_top}>
                <h5 className={styles.source_title}>
                  (출처 {source.id}) {source.title}
                </h5>

                <div className={styles.source_info}>
                  {source.favicon && (
                    <img
                      src={source.favicon}
                      alt={""}
                      className={styles.source_favicon}
                    />
                  )}
                  <div className={styles.source_text}>
                    {source.source && (
                      <p className={styles.item}>{source.source}</p>
                    )}
                    {Boolean(source.source && source.updatedAt) && (
                      <p className={styles.item}>&nbsp;•&nbsp;</p>
                    )}
                    {source.updatedAt && (
                      <p className={styles.item}>{source.updatedAt}</p>
                    )}
                  </div>
                </div>
              </div>

              <p className={styles.source_detail}>{source.snippet}</p>
            </a>,
            document.body,
          )}
        </FloatingFocusManager>
      )}
    </>
  );
}
