import { useMutation, useQueryClient } from "@tanstack/react-query";
import { P, match } from "ts-pattern";
import {
  KyClient,
  STATUS_CODES,
  isKyHttpError,
  parseKyHttpError,
} from "~clients/fetch";
import { useGuestModeStore } from "~features/guest-mode";
import { QUERY_KEYS } from "~features/providers/tanstack-query";
import { setDefaultErrorToast } from "~features/toast";
import { logger } from "~utils/logger";
import { type ChannelId, transformResponse } from "./create-channel.schema";

export type CreateChannelIdArgs = { personaId: string; xllmEnabled?: boolean };

export const useMutationCreateChannelId = () => {
  const queryClient = useQueryClient();
  const { enabled: isGuestMode, setShowMainModal } = useGuestModeStore();

  return useMutation({
    mutationFn: async ({
      personaId,
      xllmEnabled = false,
    }: CreateChannelIdArgs) => {
      return match({ personaId })
        .returnType<Promise<ChannelId>>()
        .with({ personaId: "" }, async () => {
          throw new Error("[useMutationCreateChannelId] Persona Not Found");
        })
        .otherwise(async ({ personaId }) => {
          return createChannelId({ personaId, xllmEnabled }).then(
            transformResponse,
          );
        });
    },

    onSuccess: async (data) => {
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.CHANNELS.getChannelQueryKey(data.channelId),
      });
    },

    onError: async (error) => {
      if (!isKyHttpError(error)) return;

      const {
        errorType,
        data: { status, response },
      } = await parseKyHttpError<unknown>(error);

      match({ status, response, isGuestMode })
        .with(
          {
            status: { code: STATUS_CODES.RATE_LIMIT },
            response: P.string,
            isGuestMode: false,
          },
          ({ response }) => {
            setDefaultErrorToast(response);
          },
        )
        .with(
          {
            status: { code: STATUS_CODES.RATE_LIMIT },
            response: P.string,
            isGuestMode: true,
          },
          () => {
            setShowMainModal(true);
          },
        )
        .otherwise(() => {
          logger.error({
            scope: "useMutationCreateChannelId",
            data: {
              errorType,
              status,
              response,
              isGuestMode,
            },
          });

          setDefaultErrorToast("오류가 발생했어요. 잠시 후 다시 시도해주세요.");
        });
    },
  });
};

/**
 * Example
 * - {@link https://stage-api.alan.est.ai/api/v1/channels}
 */
const API_PATH = "channels";

const createChannelId = async ({
  personaId,
  xllmEnabled,
}: CreateChannelIdArgs) => {
  return KyClient.post(API_PATH, {
    json: {
      persona_id: personaId,
      options: {
        xllm_enabled: xllmEnabled,
      },
    },
  }).json();
};
