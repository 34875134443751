import type { PropsWithChildren } from "react";
import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";
import { useModalVisible } from "./use-modal-visible";

import XllmIcon from "~assets/images/ico_xllm.svg";
import { UpdateNoticeImage } from "./images";
import styles from "./modal.module.scss";

type UpdateModalProps = PropsWithChildren<{
  isSmall?: boolean;
  onClickConfirm?: () => void;
  title: string;
  details: string;
  notice?: string;
  img?: string;
  fullWidthImg?: boolean;
  btnName?: string;
  btnColor?: string;
  imgWidthSize?: string;
  isTopCloseBtn?: boolean;
  isTodayCloseBtn?: boolean;
  isHideBottomBtn?: boolean;
  onClickCloseTemp?: () => void;
}>;

export function UpdateModal({
  onClickConfirm,
  isSmall,
  title,
  details,
  notice,
  img,
  fullWidthImg,
  btnName,
  btnColor = "black",
  imgWidthSize,
  isTopCloseBtn,
  isTodayCloseBtn,
  isHideBottomBtn,
  onClickCloseTemp,
  children,
}: UpdateModalProps) {
  const { updateModalVisible } = useModalVisible();
  return (
    <div
      className={cn(isSmall ? styles.small : styles.modal_wrap, styles.update)}
    >
      <div className={cn(styles.modal, fullWidthImg && styles.p_0)}>
        {isTopCloseBtn && (
          <div className={styles.modal_top}>
            <button
              type="button"
              className={styles.btn_close}
              onClick={createEventHandler({
                handler: onClickCloseTemp ?? onClickConfirm,
              })}
            >
              <span className="blind">닫기</span>
            </button>
          </div>
        )}
        <div className={styles.modal_contents}>
          {img ? (
            <img
              src={img}
              alt=""
              style={{ width: fullWidthImg ? "100%" : imgWidthSize }}
            />
          ) : (
            <img src={UpdateNoticeImage} alt="" />
          )}

          <h3 className={cn(styles.content_title, fullWidthImg && styles.p_lr)}>
            {title}
          </h3>
          <p className={cn(fullWidthImg && styles.p_lr)}>{details}</p>
          {notice && (
            <div className={styles.notice_wrap}>
              <p>{notice}</p>
            </div>
          )}
        </div>
        {children}
        {!isHideBottomBtn && (
          <div className={styles.modal_btn_wrap}>
            <button
              type="button"
              className={cn(styles[btnColor])}
              onClick={createEventHandler({ handler: onClickConfirm })}
            >
              <span> {btnName ? btnName : "확인"}</span>
            </button>
          </div>
        )}

        {isTodayCloseBtn && (
          <button
            type="button"
            className={styles.btn_today_close}
            onClick={createEventHandler({
              handler: () => updateModalVisible(false, { useStorage: true }),
            })}
          >
            <span>다시 보지 않기</span>
          </button>
        )}
      </div>
    </div>
  );
}

export function XllmUpdateBox() {
  return (
    <div className={styles.xllm_box}>
      <p className={styles.text_p3}>
        앨런에서 서비스하는 R1은 안전한 환경에서 제공합니다.
        <br />
        <span className={styles.ico_xllm}>
          <img src={XllmIcon} alt="AlYac xLLM" />
        </span>
        을 만나 더욱 강화된 보안으로
        <br />
        중요한 정보의 외부 유출 걱정 없이 사용해 보세요.
      </p>
    </div>
  );
}

export function XllmNoticeBox() {
  return (
    <div className={styles.xllm_box}>
      <p className={styles.text_p4}>
        LLM 정보 유출 차단은 &nbsp;
        <span className={styles.ico_xllm}>
          <img src={XllmIcon} alt="AlYac xLLM" />
        </span>
        과 함께합니다.
      </p>
    </div>
  );
}
