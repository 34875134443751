import { create } from "zustand";

type VirtualKeyboardStates = {
  isUsingVirtualKeyboard: boolean;
};

type VirtualKeyboardActions = {
  setUseVirtualKeyboard: (isUsingVirtualKeyboard: boolean) => void;
};

const INIT_STATES: VirtualKeyboardStates = {
  isUsingVirtualKeyboard: false,
};

export const useVirtualKeyboard = create<
  VirtualKeyboardStates & VirtualKeyboardActions
>((set) => ({
  ...INIT_STATES,
  setUseVirtualKeyboard: (isUsingVirtualKeyboard) =>
    set({ isUsingVirtualKeyboard }),
}));
