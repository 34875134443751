import { createFileRoute } from "@tanstack/react-router";
import { v } from "~libs/valibot";

const ShareToKakaoTalkSearchSchema = v.object({
  linkUrl: v.string(),
  title: v.string(),
  description: v.string(),
  imageUrl: v.string(),
  isYoutubePersona: v.boolean(),
});

export const Route = createFileRoute("/redirect/share/kakaotalk")({
  validateSearch: ShareToKakaoTalkSearchSchema,
});
