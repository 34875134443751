import { useMemo } from "react";
import { createPortal } from "react-dom";
import { redirectToOAuthLogin } from "~features/auth";
import { UpdateModal } from "~features/modals";
import { useGuestModeStore } from "../store";
import { getModalContents } from "./contents";

export function LoginSuggestionMainModal() {
  const { setShowMainModal } = useGuestModeStore();
  const content = getModalContents("main");

  return createPortal(
    <UpdateModal
      title={content.title}
      details={content.details}
      img={content.image}
      fullWidthImg={true}
      btnName="로그인"
      btnColor="primary"
      isTopCloseBtn={true}
      onClickCloseTemp={() => {
        setShowMainModal(false);
      }}
      onClickConfirm={() => {
        redirectToOAuthLogin();
      }}
    />,
    document.body,
  );
}

export function LoginSuggestionSidebarModal() {
  const { setShowSidebarModal } = useGuestModeStore();
  const content = useMemo(() => getModalContents("sidebar"), []);

  return createPortal(
    <UpdateModal
      title={content.title}
      details={content.details}
      img={content.image}
      fullWidthImg={true}
      btnName="로그인"
      btnColor="primary"
      isTopCloseBtn={true}
      onClickCloseTemp={() => {
        setShowSidebarModal(false);
      }}
      onClickConfirm={() => {
        redirectToOAuthLogin();
      }}
      isSmall={true}
    />,
    document.body,
  );
}
