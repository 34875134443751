import {
  Flip,
  ToastContainer,
  type ToastContainerProps,
  toast,
} from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./toastify-override.scss";

import { CONTAINER_IDS } from "./constants";

type ToastContainerCoreProps = Pick<
  ToastContainerProps,
  "position" | "containerId" | "theme" | "icon" | "autoClose"
>;

function ToastContainerCore({
  autoClose = 5000,
  ...props
}: ToastContainerCoreProps) {
  return (
    <ToastContainer
      enableMultiContainer
      autoClose={autoClose}
      closeOnClick
      hideProgressBar
      transition={Flip}
      newestOnTop={true}
      rtl={false}
      pauseOnFocusLoss
      pauseOnHover
      draggable={false}
      closeButton={false}
      limit={1}
      {...props}
    />
  );
}

export function ToastContainerTop() {
  return (
    <ToastContainerCore
      containerId={CONTAINER_IDS.TOP}
      position={toast.POSITION.TOP_CENTER}
      theme={"light"}
    />
  );
}

export function ToastContainerBottom() {
  return (
    <ToastContainerCore
      containerId={CONTAINER_IDS.BOTTOM}
      position={toast.POSITION.BOTTOM_CENTER}
      icon={false}
      theme={"dark"}
      autoClose={2000}
    />
  );
}
