import { PromptButtons as Buttons } from "./buttons";
import {
  PromptForm as Form,
  PromptFormContainer as FormContainer,
  PromptTextarea as Textarea,
} from "./form";
import { PromptRoot as Root } from "./root";

export const Prompt = {
  Root,
  FormContainer,
  Form,
  Textarea,
  Buttons,
} as const;

export { PromptWithCompletion } from "./prompt-with-completion";
export {
  RawTextContext,
  useRawTextContext,
  WithRawTextContext,
} from "./raw-text.context";
export { usePromptContext } from "./context";
export { useMobileLayer } from "./use-mobile-layer";

export {
  useMutationSendPromptInChannel,
  useMutationSendPromptWithNewChannel,
} from "./mutations";
