import {
  FloatingFocusManager,
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { useLocation } from "@tanstack/react-router";
import { type PropsWithChildren, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useGuestModeStore } from "~features/guest-mode";
import { type Persona, usePersonas } from "~features/personas";
import { BottomSheet } from "~features/ui/bottom-sheet";
import { GuideTooltip } from "~features/ui/guide-tooltip";
import { MenuBox } from "~features/ui/popup-menu/menu-box";
import { xllmHooks } from "~features/xllm";
import { useWindowWidth } from "~hooks/use-window-width";
import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";
import styles from "./ai-model.module.scss";
import { XllmBox } from "./xllm-box";

type ModelProps = {
  id: string;
  name: string;
  subname?: string;
  description: string;
  selected?: boolean;
  classname?: string;
  onSelect: () => void;
  isOnXllm?: boolean;
};

export function Model({
  name,
  subname,
  description,
  classname,
  selected,
  onSelect,
  isOnXllm,
}: ModelProps) {
  return (
    <div className={styles.model_wrapper}>
      <button
        type="button"
        className={cn(styles.model, { [styles.selected]: selected }, classname)}
        onClick={onSelect}
      >
        <div className={styles.model_name_wrap}>
          <span className={styles.model_name}>
            {name} {subname && `(${subname})`}
          </span>
        </div>
        <p className={styles.model_description}>{description}</p>
      </button>
      {isOnXllm && <XllmBox />}
    </div>
  );
}

type ModelListProps = {
  models: Persona[];
  selectedModelId: string;
  selectModel: (persona: Persona) => void;
};

function ModelList({ models, selectedModelId, selectModel }: ModelListProps) {
  return (
    <div className={styles.model_list}>
      <div className={styles.model_list_title}>AI 모델</div>

      {models.map((model) => (
        <Model
          key={`ai-model-${model.id}`}
          id={model.id}
          name={model.name}
          subname={model.name === "R1" ? "Beta" : ""}
          isOnXllm={model.name === "R1"}
          description={model.description}
          selected={selectedModelId === model.id}
          onSelect={() => selectModel(model)}
        />
      ))}
    </div>
  );
}

type SelectButtonProps = PropsWithChildren<{
  name: string;
  classname?: string;
  buttonRef?: React.Ref<HTMLButtonElement>;
  onClick?: React.MouseEventHandler;
}>;

function SelectButton({
  name,
  classname,
  onClick,
  buttonRef,
  children,
}: SelectButtonProps) {
  const { enabled: xllmEnabled } = xllmHooks.useSanitizerContext();
  const { enabled: isGuestMode } = useGuestModeStore();

  return (
    <button
      ref={buttonRef}
      type="button"
      data-label="select-trigger"
      className={cn(styles.selected_model, classname)}
      onClick={onClick}
    >
      {/* TODO: persona에 xllm needed 추가? */}
      {name === "R1" && (
        <i
          className={cn(styles.badge_xllm, {
            [styles.on]: xllmEnabled && !isGuestMode,
          })}
        />
      )}
      <span>{name}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
      >
        <path d="M0.5 2L4 5.5L7.5 2" stroke="#1A212C" />
      </svg>
      {children}
    </button>
  );
}

const MODEL_GUIDE_STORAGE_KEY = "alan.model.guide.visibility.202502101035";

export function SelectedModel() {
  const { searchPersonas, persona, setPersona, searchDefault } = usePersonas();
  const visibleModel = useMemo(
    () =>
      searchPersonas.find((model) => model.id === persona.id)
        ? persona
        : searchDefault,
    [searchPersonas, persona, searchDefault],
  );

  const { isMobileSmallSize: isMobileSize } = useWindowWidth();

  const { pathname } = useLocation();
  const isGuideOpenInitValue = useMemo(() => {
    if (pathname !== "/") return false;

    const item = window.localStorage.getItem(MODEL_GUIDE_STORAGE_KEY);
    if (!item) return true;

    try {
      return JSON.parse(item) as boolean;
    } catch {
      return true;
    }
  }, [pathname]);
  const [isGuideOpen, setIsGuideOpen] = useState(isGuideOpenInitValue);

  const [isOpen, setIsOpen] = useState(false);
  const { refs, context, floatingStyles } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "bottom-start",
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({ mainAxis: 4, crossAxis: 0 }),
      flip({ fallbackAxisSideDirection: "end" }),
      shift(),
    ],
  });

  const dismiss = useDismiss(context, {});
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    dismiss,
    role,
  ]);

  const onClickMenu = createEventHandler({
    handler: () => {
      setIsOpen((prev) => !prev);
    },
  });

  const selectModel = (persona: Persona) => {
    setPersona(persona);
    setIsOpen(false);
  };

  const closeBottomSheet = () => {
    setIsOpen(false);
  };

  if (!visibleModel) return null;

  return isMobileSize ? (
    <>
      <SelectButton name={visibleModel.name} onClick={onClickMenu} />
      {isOpen &&
        createPortal(
          <BottomSheet.Root
            className={styles.mobile_sheet}
            closeBottomSheet={closeBottomSheet}
            isHideCloseButton={true}
          >
            <div ref={refs.setFloating} className={styles.model_list_wrap}>
              <ModelList
                models={searchPersonas}
                selectedModelId={persona.id}
                selectModel={selectModel}
              />
            </div>
          </BottomSheet.Root>,
          document.body,
        )}
    </>
  ) : (
    <>
      <div className={styles.btn_select_wrap}>
        <SelectButton
          buttonRef={refs.setReference}
          name={visibleModel.name}
          onClick={onClickMenu}
          {...getReferenceProps()}
        />
        {isGuideOpen && (
          <GuideTooltip
            title="AI 모델 선택"
            text={"원하는 용도에 맞는 AI를 선택하고, \n검색을 시작하세요."}
            onClose={() => {
              setIsGuideOpen(false);
              window.localStorage.setItem(
                MODEL_GUIDE_STORAGE_KEY,
                JSON.stringify(false),
              );
            }}
          />
        )}
      </div>
      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <MenuBox
            ref={refs.setFloating}
            width="260px"
            padding="0"
            floatingStyles={floatingStyles}
            {...getFloatingProps()}
          >
            <ModelList
              models={searchPersonas}
              selectedModelId={visibleModel.id}
              selectModel={selectModel}
            />
          </MenuBox>
        </FloatingFocusManager>
      )}
    </>
  );
}

export default SelectedModel;
