import { useChannel, useQueryChannel } from "~features/channel";
import styles from "./share-permissions.module.scss";
import { useMutationToggleShared } from "./use-mutation-toggle-shared";

const BLUE_COLOR = "#1179EF";
const DEFAULT_COLOR = "#1A212C";

const PrivateIcon = ({ color }: { color?: string }) => {
  const fillColor = color || "#1A212C";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
      <mask
        id="mask0"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#8C8F95" />
      </mask>
      <g mask="url(#mask0)">
        <path
          className={styles.color}
          d="M4.00008 14.6667C3.63341 14.6667 3.31953 14.5361 3.05841 14.275C2.7973 14.0139 2.66675 13.7 2.66675 13.3333V6.66666C2.66675 6.29999 2.7973 5.9861 3.05841 5.72499C3.31953 5.46388 3.63341 5.33332 4.00008 5.33332H4.66675V3.99999C4.66675 3.07777 4.99175 2.29166 5.64175 1.64166C6.29175 0.991656 7.07786 0.666656 8.00008 0.666656C8.9223 0.666656 9.70842 0.991656 10.3584 1.64166C11.0084 2.29166 11.3334 3.07777 11.3334 3.99999V5.33332H12.0001C12.3667 5.33332 12.6806 5.46388 12.9417 5.72499C13.2029 5.9861 13.3334 6.29999 13.3334 6.66666V13.3333C13.3334 13.7 13.2029 14.0139 12.9417 14.275C12.6806 14.5361 12.3667 14.6667 12.0001 14.6667H4.00008ZM4.00008 13.3333H12.0001V6.66666H4.00008V13.3333ZM6.00008 5.33332H10.0001V3.99999C10.0001 3.44443 9.80564 2.97221 9.41675 2.58332C9.02786 2.19443 8.55564 1.99999 8.00008 1.99999C7.44453 1.99999 6.9723 2.19443 6.58341 2.58332C6.19453 2.97221 6.00008 3.44443 6.00008 3.99999V5.33332Z"
          fill={fillColor}
        />
      </g>
    </svg>
  );
};

const ShareIcon = ({ color }: { color?: string }) => {
  const fillColor = color || "#1A212C";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
      <mask
        id="mask0"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0)">
        <path
          className={styles.color}
          d="M7.25 11V4.8875L5.3 6.8375L4.25 5.75L8 2L11.75 5.75L10.7 6.8375L8.75 4.8875V11H7.25ZM3.5 14C3.0875 14 2.73438 13.8531 2.44063 13.5594C2.14687 13.2656 2 12.9125 2 12.5V10.25H3.5V12.5H12.5V10.25H14V12.5C14 12.9125 13.8531 13.2656 13.5594 13.5594C13.2656 13.8531 12.9125 14 12.5 14H3.5Z"
          fill={fillColor}
        />
      </g>
    </svg>
  );
};

const PERMISSION_TYPE = {
  private: {
    isSharing: false,
    title: "비공개",
    description: "나만 볼 수 있어요.",
  },
  public: {
    isSharing: true,
    title: "공유 가능",
    description: "링크가 있는 모든 사람이 볼 수 있어요.",
  },
} as const;

type SharePermissionButtonProps = {
  Icon: typeof ShareIcon | typeof PrivateIcon;
  title: string;
  description: string;
  isSelected: boolean;
  onClick: () => void;
};

function SharePermissionButton({
  Icon,
  title,
  description,
  isSelected,
  onClick,
}: SharePermissionButtonProps) {
  return (
    <button
      type="button"
      className={styles.btn_permission}
      aria-label={`${title} button`}
      onClick={onClick}
    >
      <i className={styles.icon}>
        <Icon color={isSelected ? BLUE_COLOR : DEFAULT_COLOR} />
      </i>
      <div className={styles.text}>
        <span style={{ color: isSelected ? BLUE_COLOR : DEFAULT_COLOR }}>
          {title}
        </span>
        <p>{description}</p>
      </div>
    </button>
  );
}

type SharePermissionTitleProps = {
  title?: string;
};

export function SharePermissionTitle({ title }: SharePermissionTitleProps) {
  return <div className={styles.title}>{title ? title : "공유 권한 보기"}</div>;
}

export function SharePermissionToggle() {
  const { channelId } = useChannel();
  const { data: channel } = useQueryChannel(channelId);
  const { mutate } = useMutationToggleShared();
  const isChannelSharing = channel?.isSharing ?? false;

  return (
    <div className={styles.permission_wrapper}>
      <ul className={styles.permission_list}>
        {Object.entries(PERMISSION_TYPE).map(
          ([key, { title, description, isSharing }]) => (
            <li key={key}>
              <SharePermissionButton
                Icon={isSharing ? ShareIcon : PrivateIcon}
                title={title}
                description={description}
                isSelected={isChannelSharing === isSharing}
                onClick={
                  isChannelSharing === isSharing ? () => void 0 : () => mutate()
                }
              />
            </li>
          ),
        )}
      </ul>
    </div>
  );
}
