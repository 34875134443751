import {
  type Dispatch,
  type SetStateAction,
  createContext,
  useContext,
} from "react";

type ShareContextStates =
  | {
      isShareModalOpen: boolean;
      setIsShareModalOpen: Dispatch<SetStateAction<boolean>>;
    }
  | undefined;

export const ShareContext = createContext<ShareContextStates>(undefined);

export const useShareContext = () => {
  const context = useContext(ShareContext);
  if (!context) {
    throw new Error(
      "useShareContext must be used within a ShareContextProvider",
    );
  }

  return context;
};
