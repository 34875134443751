import { type PropsWithChildren, useMemo } from "react";
import YoutubeIcon from "~assets/images/ico_youtube_thumbnail.png";
import { Card } from "~features/ui/card";
import { shuffleArray } from "~features/utils/array";
import { useWindowWidth } from "~hooks/use-window-width";
import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";
import styles from "./suggestion-card.module.scss";
import { useSendSuggestion } from "./use-send-suggestion";

type SuggestionCardProps = PropsWithChildren<{
  onClick: () => void;
  as?: keyof JSX.IntrinsicElements;
  className?: string;
  isYoutube?: boolean;
}>;

function SuggestionCard({ onClick, children, isYoutube }: SuggestionCardProps) {
  return (
    <Card.Root
      onClick={onClick}
      onKeyUp={createEventHandler({
        handler: (e) => {
          if (e.key === "Enter") {
            onClick();
          }
        },
      })}
      tabIndex={0}
      aria-label="suggestion"
    >
      {isYoutube && (
        <div className={styles.youtube_title}>
          <img src={YoutubeIcon} alt="" />
          <span>유튜브 영상 요약해줘</span>
        </div>
      )}
      <Card.Title>{children}</Card.Title>
    </Card.Root>
  );
}

const youtubeSuggestions = [
  "youtube.com/watch?v=yOsHYsKGevI",
  "youtube.com/watch?v=rwD8sZUCpuk",
  "youtube.com/watch?v=5Ta-TBnJzO4",
];

const suggestions = [
  "🤖 AI 기술이 콘텐츠 마케팅에 미치는 영향은?",
  "🎧 가성비 좋은 무선 이어폰 추천해줘",
  "✒️ 효과적인 블로그 글쓰기 방법과 예시 알려줘",
  "🙎‍♂️ 성공적인 리더가 되기 위한 필요 조건은 뭐야?",
  "✈️ 이번 주말 제주도 여행 어떨까?",
  "🐶 반려동물 사료에 대한 시장조사를 해줘",
  "📑 반도체 기술에 대한 보고서를 써줘",
  "🧳 3박 4일 국내 여행지 코스 추천해줘",
  "🙏 베트남 여행에서 알아두면 좋을 문화 팁은?",
  "⌨️ 코딩 입문자를 위한 온라인 강의 추천해줘",
  "👨‍🏫 빠르게 영어 실력 늘리는 방법을 알려줘",
  "🖥️ 프로젝트 관리를 위한 필수 도구는 뭐가 있어?",
  "🍿 오늘 영화관에서 볼만한 영화 추천해줘",
  "💕 성수동 주말 데이트 코스 추천해줘",
  "🍴 오늘 날씨에 어울리는 저녁 추천해줘",
  "💲 직장인 재테크 포트폴리오 추천해줘",
  "🏃‍♀️ 출근 전에 할 수 있는 스트레칭 알려줘",
  "✍️ 자기소개서 잘 쓰는 법은?",
];

type SuggestionCardsProps = PropsWithChildren<{
  className?: string;
}>;

export function SuggestionCards({ className }: SuggestionCardsProps) {
  const { isMobileSmallSize } = useWindowWidth();
  const { sendSuggestion } = useSendSuggestion();

  const onClick = (suggestion: string) => () => {
    sendSuggestion(removeEmoji(suggestion));
  };

  const shuffledSuggestions = useMemo(
    () => shuffleArray(suggestions, isMobileSmallSize ? 3 : 6),
    [isMobileSmallSize],
  );

  const shuffledYoutubeSuggestion = useMemo(
    () => shuffleArray(youtubeSuggestions, 1)[0],
    [],
  );

  return (
    <ul
      className={cn(styles.container, className)}
      aria-label="suggestion list"
    >
      {shuffledYoutubeSuggestion && (
        <SuggestionCard
          key={`suggestion-card-${youtubeSuggestions}-0}`}
          as="li"
          onClick={onClick(shuffledYoutubeSuggestion)}
          isYoutube={true}
        >
          {shuffledYoutubeSuggestion.replace("https://www.", "")}
        </SuggestionCard>
      )}
      {shuffledSuggestions.map((suggestion, index) => (
        <SuggestionCard
          key={`suggestion-card-${suggestion}-${index + 1}`}
          as="li"
          onClick={onClick(suggestion)}
        >
          {suggestion}
        </SuggestionCard>
      ))}
    </ul>
  );
}

const EMOJI_REGEX = /^[^\dㄱ-힣A-z]+\s+/;

const removeEmoji = (suggestion: string) =>
  suggestion.replace(EMOJI_REGEX, "").trim();
