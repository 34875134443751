import { useQueryClient } from "@tanstack/react-query";
import throttle from "just-throttle";
import {
  type ChangeEvent,
  type FocusEvent,
  type KeyboardEvent,
  type MouseEvent,
  type PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { P, match } from "ts-pattern";
import { useUser } from "~features/auth";
import { useChannel, useQueryChannel } from "~features/channel";
import { Completions } from "~features/completions";
import { usePromptCompletions } from "~features/completions/use-prompt-completions";
import { useGuestModeStore } from "~features/guest-mode";
import {
  useMutationCancelMessage,
  useNativeBridgeMessages,
} from "~features/messages/hooks";
import { usePersonas } from "~features/personas";
import { QUERY_KEYS } from "~features/providers/tanstack-query";
import { useSendSearchTitle } from "~features/search";
import type { SearchResult } from "~features/search/schemas";
import { useSidebar } from "~features/ui/side-bar";
import { useUserAgent } from "~features/user-agent";
import { Status } from "~features/util-types/status";
import { SanitizerModal, xllmHooks } from "~features/xllm";
import { useWindowWidth } from "~hooks/use-window-width";
import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";
import { logger } from "~utils/logger";
import { IconVariants, PromptIcon } from "./icons";
import { Prompt, useRawTextContext } from "./index";
import { setModelSelectorToast } from "./model-selector-toast";
import {
  useCreateChannelAndNavigate,
  useMutationSendPromptWithNewChannel,
  useRequestValidation,
  validateRequestWithNewChannel,
} from "./mutations";
import styles from "./prompt.module.scss";
import { usePlaceholderWithRecommends } from "./use-placeholder-with-recommends";
import { categorizePrompt } from "./utils";

const MIN_TEXTAREA_HEIGHT = 46;
const MIN_TEXTAREA_PADDING = 7;

type PromptWithCompletionProps = PropsWithChildren<{
  className?: string;
  height?: string;
  focusOnMount?: boolean;
  onFocusTextarea?: () => void;
  beforeSendPrompt?: () => void | Promise<void>;
  afterSendPrompt?: () => void | Promise<void>;
}>;

/**
 * @TODO: Container-Presenter 분리해야 되나..
 */
export function PromptWithCompletion({
  className,
  height,
  children,
  ...props
}: PromptWithCompletionProps) {
  const { user } = useUser();
  const { enabled: isGuestMode } = useGuestModeStore();

  const { rawText, setRawText } = useRawTextContext();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [rows, setRows] = useState(1);
  const { isMobileSmallSize: isMobileSize } = useWindowWidth();

  const { placeholderText } = usePlaceholderWithRecommends(rawText);

  const { matchValidationResult } = useRequestValidation();
  const { persona, searchDefault, youtubePersona, setPersona, searchR1Id } =
    usePersonas();
  const { createChannelAndNavigate, getCreateChannelArgs } =
    useCreateChannelAndNavigate();
  const { mutate } = useMutationSendPromptWithNewChannel(user);
  const { requestNativeNotificationPermission } = useSendSearchTitle();

  const cancelSendingMutation = useMutationCancelMessage();

  const {
    isCompletionsVisible,
    setIsCompletionsVisible,
    setKeyword,
    completions,
  } = usePromptCompletions();
  const [focusedIndex, setFocusedIndex] = useState(-1);

  const { channelId } = useChannel();
  const { data: channel } = useQueryChannel(channelId);
  const queryClient = useQueryClient();
  const searchResults = queryClient.getQueryData<SearchResult.Page>(
    QUERY_KEYS.CHANNELS.getChannelMessagesQueryKey(channelId),
  );

  const {
    isModalOpen: isSanitizerModalOpen,
    checkSanitizer,
    enabled: xllmEnabled,
    setEnabled: setXllmEnabled,
  } = xllmHooks.useSanitizerContext();

  const { hasUrl, hasYoutubeUrl } = useMemo(
    () => categorizePrompt(rawText.trim()),
    [rawText],
  );

  const iconVariant = useMemo(() => {
    return match({ hasUrl, hasYoutubeUrl, isCompletionsVisible, isMobileSize })
      .with(
        { isMobileSize: true, isCompletionsVisible: true },
        () => IconVariants.Init,
      )
      .with({ hasYoutubeUrl: true }, () => IconVariants.Youtube)
      .with({ hasUrl: true }, () => IconVariants.Url)
      .otherwise(() => IconVariants.Search);
  }, [isCompletionsVisible, isMobileSize, hasUrl, hasYoutubeUrl]);

  useEffect(() => {
    if (!hasUrl && !hasYoutubeUrl) return;
    if (!searchDefault || !youtubePersona) return;
    if (persona.id === searchDefault.id || persona.id === youtubePersona.id)
      return;

    if (hasYoutubeUrl) {
      setModelSelectorToast("유튜브 요약은 Alan v2로만 제공돼요!");
      setPersona(youtubePersona);
      return;
    }

    if (hasUrl) {
      setModelSelectorToast("웹 페이지 요약은 Alan v2로만 제공돼요!");
      setPersona(searchDefault);
    }
  }, [
    hasUrl,
    hasYoutubeUrl,
    persona,
    searchDefault,
    youtubePersona,
    setPersona,
  ]);

  const { setIsOpen: setSidebarOpen } = useSidebar();

  useEffect(() => {
    props.focusOnMount && textareaRef.current?.focus();
  }, [props.focusOnMount]);

  const sendPrompt = async (prompt = "") => {
    textareaRef.current?.blur();

    const _prompt = (prompt || rawText || placeholderText).trim();

    /**
     * @todo 여기서 user query fetch?
     * @todo 비로그인 유저
     */
    const validationResult = validateRequestWithNewChannel({
      prompt: _prompt,
      user,
    });

    return matchValidationResult(validationResult, {
      withValid: async () => {
        const categorized = categorizePrompt(_prompt);

        const createChannelArgs = getCreateChannelArgs({
          personaId: persona.id,
          hasUrl: categorized.hasUrl,
          hasYoutubeUrl: categorized.hasYoutubeUrl,
          xllmEnabled:
            xllmEnabled && !isGuestMode && persona.id !== searchDefault.id,
        });

        return createChannelAndNavigate(createChannelArgs).then(
          ({ channelId, personaId, isForYoutubeSummary }) => {
            setSidebarOpen(false);

            mutate({
              prompt: _prompt,
              channelId,
              personaId,
              isForYoutubeSummary,
              youtubeId: categorized.youtubeId,
            });

            props.afterSendPrompt?.();

            requestNativeNotificationPermission();
          },
        );
      },

      createOAuthRedirectUrl: () => {
        const nextUrl = new URL(window.location.origin.concat("/search"));
        nextUrl.searchParams.set("q", _prompt);
        nextUrl.searchParams.set("pid", persona.id ?? "");
        try {
          window.sessionStorage.setItem("pid", persona.id ?? "");
          window.sessionStorage.setItem("xllmEnabled", String(xllmEnabled));
        } catch (error) {
          logger.error({
            scope: "PromptWithCompletion.sendPrompt.createOAuthRedirectUrl",
            data: {
              error,
              personaId: persona.id,
            },
          });
        }
        return nextUrl.href;
      },
    });
  };

  const { isMobile: isMobileApp, isWeb, isAndroid } = useUserAgent();

  useNativeBridgeMessages({
    isWeb,
    isAndroid,
    sendMessage: sendPrompt,
  });

  const throttleSetKeyword = useCallback(
    throttle(
      (keyword: string) => {
        setKeyword(keyword);
      },
      300,
      { leading: false },
    ),
    [],
  );

  const onChangeTextarea = createEventHandler<ChangeEvent<HTMLTextAreaElement>>(
    {
      handler: (e) => {
        const rawValue = e.currentTarget.value;
        setRawText(rawValue);

        setFocusedIndex(-1);
        const trimmedValue = rawValue.trim();
        if (rawValue.includes("\n") || trimmedValue.length === 0) {
          throttleSetKeyword("");
          return;
        }

        throttleSetKeyword(trimmedValue);
      },
    },
  );

  const onKeyUpTextarea = createEventHandler<
    KeyboardEvent<HTMLTextAreaElement>
  >({
    preventDefault: false,
    handler: (e) => {
      const {
        key,
        shiftKey,
        nativeEvent: { isComposing },
      } = e;

      match({ key, shiftKey, focusedIndex, isComposing })
        .with({ isComposing: true }, { shiftKey: true }, () => {
          return;
        })
        /** @description 사용자 입력 prompt 전송 */
        .with({ key: "Enter", focusedIndex: -1 }, async () => {
          e.preventDefault();

          if (
            persona.id === searchR1Id &&
            !(await checkSanitizer(rawText)).isOk
          ) {
            return;
          }

          sendPrompt();
          setIsCompletionsVisible(false);
        })
        /** @description 추천검색어 선택 및 전송 */
        .with({ key: "Enter" }, () => {
          e.preventDefault();
          const value = completions[focusedIndex].original;
          setRawText(value);
          setKeyword(value);
          setFocusedIndex(-1);
          sendPrompt(value);
        })
        .with(
          {
            key: "ArrowDown",
            focusedIndex: P.number.between(-1, completions.length - 2),
          },
          () => {
            e.preventDefault();
            setFocusedIndex((prev) => prev + 1);
          },
        )
        .with(
          {
            key: "ArrowUp",
            focusedIndex: P.number.between(0, completions.length - 1),
          },
          () => {
            e.preventDefault();
            setFocusedIndex((prev) => prev - 1);
          },
        );
    },
  });

  const onFocusTextarea = createEventHandler<FocusEvent<HTMLTextAreaElement>>({
    preventDefault: false,
    handler: () => {
      props.onFocusTextarea?.();
      !isCompletionsVisible && setIsCompletionsVisible(true);
      isMobileSize && setRows(2);
    },
  });

  const onBlurTextarea = createEventHandler<FocusEvent<HTMLTextAreaElement>>({
    handler: (_e) => {
      if (isMobileSize) return;
      if (!isCompletionsVisible) return;

      /** @todo 명시적으로 닫는 액션 필요 */
      window.setTimeout(() => {
        setIsCompletionsVisible(false);
      }, 250);
    },
  });

  const onClickTextarea = createEventHandler<MouseEvent<HTMLTextAreaElement>>({
    handler: () => {
      // todo
    },
  });

  const setKeywordAsTextValue = (original: string) => {
    setRawText(original);
    setKeyword(original);
  };

  const cancelPrompt = useCallback(async () => {
    const currentRespondingId = searchResults?.items
      .slice(-2)
      .find(
        ({ status }) => status === Status.INIT || status === Status.LOADING,
      )?.requestId;
    if (!currentRespondingId) return;

    cancelSendingMutation.mutate(currentRespondingId);
  }, [cancelSendingMutation, searchResults]);

  const promptFormPadding =
    height && Number.parseInt(height, 10) > MIN_TEXTAREA_HEIGHT
      ? `${(Number.parseInt(height, 10) - MIN_TEXTAREA_HEIGHT) / 2 + MIN_TEXTAREA_PADDING}px`
      : `${MIN_TEXTAREA_PADDING}px`;

  useEffect(() => {
    if (channelId.length === 0) return;
    const { xllmEnabled: xllmEnabledFromChannel } = channel || {
      xllmEnabled: false,
    };

    setXllmEnabled(xllmEnabledFromChannel);
  }, [channelId, channel, setXllmEnabled]);

  return (
    <Prompt.Root
      context={{
        textareaEnabled: true,
        placeholderText,
        sendPrompt,
        cancelPrompt,
        textareaRef,
        onChangeTextarea,
        onKeyUpTextarea,
        onFocusTextarea,
        onBlurTextarea,
        onClickTextarea,
        textareaRows: rows,
        isMobileSize,
        isSttEnabled: isMobileApp,
      }}
      className={cn(
        styles.prompt_wrapper,
        isCompletionsVisible && styles.h_full,
        className,
      )}
    >
      <Prompt.FormContainer
        className={cn(
          styles.prompt_container,
          !isMobileSize && styles.border_gradient,
        )}
      >
        <Prompt.Form
          className={cn(
            styles.prompt_form_wrapper,
            isMobileSize && styles.border_gradient,
          )}
        >
          <div
            className={cn(
              styles.prompt_form,
              isCompletionsVisible && styles.prompt_focusing,
              isCompletionsVisible &&
                rawText.length > 0 &&
                completions.length > 0 &&
                styles.prompt_border,
            )}
            style={{
              minHeight: height || `${MIN_TEXTAREA_HEIGHT}px`,
              paddingTop: promptFormPadding,
              paddingBottom: promptFormPadding,
            }}
          >
            {iconVariant !== IconVariants.Init && (
              <PromptIcon variants={iconVariant} />
            )}

            <Prompt.Textarea
              ref={textareaRef}
              className={cn(styles.prompt_textarea)}
            />

            <Prompt.Buttons
              ishideModel={hasUrl || (isMobileSize && !isCompletionsVisible)}
            />
          </div>
        </Prompt.Form>

        {isCompletionsVisible &&
          rawText.length > 0 &&
          completions.length > 0 && (
            <div className={styles.completions_wrapper}>
              <Completions.Root
                context={{
                  isMobileSize,
                  setKeywordAsTextValue,
                  textareaRef,
                }}
              >
                {completions.map((completion, idx) => (
                  <Completions.Item
                    key={`completion-${completion.original}`}
                    completion={completion}
                    className={cn(
                      idx === focusedIndex && styles.completion_focusing,
                    )}
                  />
                ))}
              </Completions.Root>
            </div>
          )}
      </Prompt.FormContainer>

      {children}

      {isSanitizerModalOpen && (
        <SanitizerModal
          sendPrompt={sendPrompt}
          rawText={rawText}
          setRawText={setRawText}
        />
      )}
    </Prompt.Root>
  );
}
