import { Link } from "@tanstack/react-router";
import type { PropsWithChildren } from "react";
import HistoryIcon from "~assets/images/ico_history.png";
import NewSearchIcon from "~assets/images/ico_new_search.png";
import { useUser } from "~features/auth";
import { useGuestModeStore } from "~features/guest-mode";
import { HistorySidebar } from "~features/history";
import { HomeButton } from "~features/ui/home-button";
import { useWindowWidth } from "~hooks/use-window-width";
import { cn } from "~utils/class-names";
import { SidebarBottom } from "./bottom";
import { MenuButton } from "./menu-button";
import styles from "./side-bar.module.scss";
import { useSidebar } from "./use-sidebar";
import { UserIcon, UserMenu } from "./user";

type SideBarProps = PropsWithChildren<{ className?: string }>;

export function SideBar({ className = "" }: SideBarProps) {
  const { isOpen, toggleIsOpen } = useSidebar();
  const { user } = useUser();
  const { isMobileSmallSize } = useWindowWidth();
  const { enabled: isGuestMode, setShowMainModal } = useGuestModeStore();

  return (
    <nav
      className={cn(
        styles.sidebar,
        { [styles.hidden]: isMobileSmallSize && !isOpen },
        className,
      )}
    >
      <div className={styles.top_wrapper}>
        <MenuButton onClick={toggleIsOpen} />
        <div className={styles.btn_wrap}>
          <Link
            to="/"
            preload={isGuestMode ? false : "intent"}
            className={styles.btn}
            aria-label="새 검색"
          >
            <img src={NewSearchIcon} alt="" />
          </Link>

          <Link
            to="/history"
            className={styles.btn}
            aria-label="내 검색 기록"
            preload={isGuestMode ? false : "intent"}
            onClick={(e) => {
              e.stopPropagation();

              if (isGuestMode) {
                e.preventDefault();
                setShowMainModal(true);
                return;
              }

              toggleIsOpen();
            }}
          >
            <img src={HistoryIcon} alt="history icon" />
          </Link>
        </div>
      </div>

      {!isGuestMode && user.name && (
        <div className={styles.bottom_wrapper}>
          <UserMenu>
            <UserIcon thumbnailUrl={""} displayName={user.name[0]} />
          </UserMenu>
        </div>
      )}

      {isOpen && (
        <div className={styles.full_screen}>
          <div onClick={toggleIsOpen} className={styles.dimmed} />

          <div className={styles.opened_sidebar}>
            <div className={styles.top_wrapper}>
              <MenuButton onClick={toggleIsOpen} />
              <HomeButton />
            </div>

            <HistorySidebar />

            <SidebarBottom />
          </div>
        </div>
      )}
    </nav>
  );
}
