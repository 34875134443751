import { useMemo } from "react";
import { useChannel, useQueryChannel } from "~features/channel";
import { usePersonas } from "~features/personas";
import { useQuerySearch } from "~features/search/use-query-search";
import { useQueryRequestSummary } from "~features/youtube-summary";
import type { ShareToKakaoTalkArgs } from "./share-to-kakao-talk";
import styles from "./sns-share-buttons.module.scss";
import { ATTRIBUTES_BY_SNS_TYPE, SnsTypes } from "./sns-types";
import { UrlCopyButton } from "./url-copy-button";

type SnsShareButtonProps = {
  type: SnsTypes;
  getUrlArgs?: ShareToKakaoTalkArgs;
};

const DEFAULT_IMAGE_URL = "https://alan.est.ai/images/alan-logo-800x400.png";

function SnsShareButton({ type, getUrlArgs }: SnsShareButtonProps) {
  const attributes = ATTRIBUTES_BY_SNS_TYPE[type];

  const url = attributes.getUrl(getUrlArgs);

  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className={styles.btn_share_on}
      draggable="false"
    >
      <img src={attributes.Icon} alt="" />
      <span>{attributes.title}</span>
    </a>
  );
}

const SNS_TYPES_ORDER = [
  SnsTypes.KAKAO,
  SnsTypes.FACEBOOK,
  SnsTypes.X,
  SnsTypes.THREAD,
] as const;

export function SnsShareButtons() {
  const { channelId } = useChannel();
  const { data: channel } = useQueryChannel(channelId);
  const { youtubePersonaId } = usePersonas();

  const { data: search } = useQuerySearch(channelId);
  const { data: summary } = useQueryRequestSummary();

  const args = useMemo(() => {
    const { title: channelTitle = "", personaId = "" } = channel ?? {};

    const {
      title: searchTitle = "",
      content = "",
      images = { items: [] },
    } = search?.items[0] ?? {};
    const { title: summaryTitle = "" } = summary?.video ?? {};

    return {
      title: channelTitle || summaryTitle || searchTitle,
      description: content.slice(0, 50),
      imageUrl: images.items?.[0]?.url || DEFAULT_IMAGE_URL,
      isYoutubePersona: personaId === youtubePersonaId,
    };
  }, [channel, search, summary, youtubePersonaId]);

  return (
    <>
      {SNS_TYPES_ORDER.map((type) => (
        <SnsShareButton key={type} type={type} getUrlArgs={args} />
      ))}
      <UrlCopyButton />
    </>
  );
}
