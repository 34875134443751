import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { KyClient } from "~clients/fetch";
import { useChannel } from "~features/channel";
import { QUERY_KEYS } from "~features/providers/tanstack-query";
import type { SearchResult } from "~features/search/schemas";
import { cloneObject } from "~utils/clone-object";

export const useMutationAnswerResume = () => {
  const queryClient = useQueryClient();
  const { channelId } = useChannel();
  const queryKey = useMemo(
    () => QUERY_KEYS.CHANNELS.getChannelMessagesQueryKey(channelId),
    [channelId],
  );

  return useMutation({
    mutationFn: requestResume,

    onMutate: async (messageId) => {
      queryClient.setQueryData(queryKey, (prevMessages: SearchResult.Page) => {
        const nextMessages = cloneObject(prevMessages);
        const targetMessage = nextMessages.items.find(
          (message) =>
            message.messageId?.assistant === messageId ||
            message.id === messageId,
        );
        if (!targetMessage) return prevMessages;
        targetMessage.questions = undefined;

        return nextMessages;
      });
    },

    onSuccess: () => {
      queryClient.refetchQueries({ queryKey });
    },
  });
};

/**
 * @example {@link https://api.alan.est.ai/api/v1/messages/66a7c69b4b16ba3080eeb57f/continue}
 */
const createApiPath = (messageId: string) => `messages/${messageId}/continue`;

const requestResume = async (messageId: string) => {
  KyClient.post(createApiPath(messageId));
};
