import { useEffect } from "react";
import { useAccessTokenStore } from "~features/auth";
import { useFingerprintStore } from "~features/fingerprint";
import { useUserAgent } from "~features/user-agent";
import { enrollDevice } from "./apis/enroll-device";
import { getAccessToken } from "./apis/get-access-token";
import { useGuestModeStore } from "./store";

export const useGuestModeSetup = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const { isWeb } = useUserAgent();
  const { fingerprintId } = useFingerprintStore();
  const { enabled, setEnabled, setShowSidebarModal } = useGuestModeStore();
  const { setAccessToken } = useAccessTokenStore();

  useEffect(() => {
    if (!isWeb || fingerprintId.length === 0 || isLoggedIn || enabled) return;

    setShowSidebarModal(true);
    setEnabled(true);

    enrollDevice({ fingerprintId }).then((res) => {
      if (!res.isOk) {
        return;
      }

      getAccessToken({ fingerprintId }).then((res) => {
        if (res.accessToken.length === 0) return;
        setAccessToken(res);
      });
    });
  }, [
    isWeb,
    fingerprintId,
    isLoggedIn,
    enabled,
    setEnabled,
    setAccessToken,
    setShowSidebarModal,
  ]);
};
