/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './../../../routes/__root'
import { Route as IndexImport } from './../../../routes/index'
import { Route as SearchIndexImport } from './../../../routes/search/index'
import { Route as ChannelsIndexImport } from './../../../routes/channels/index'
import { Route as SearchChannelIdImport } from './../../../routes/search/$channelId'
import { Route as ChannelsChannelIdImport } from './../../../routes/channels/$channelId'
import { Route as SummaryYoutubeChannelIdImport } from './../../../routes/summary/youtube/$channelId'
import { Route as RedirectShareKakaotalkImport } from './../../../routes/redirect/share/kakaotalk'

// Create Virtual Routes

const MaintenanceLazyImport = createFileRoute('/maintenance')()
const HistoryIndexLazyImport = createFileRoute('/history/')()
const OnboardingWinLazyImport = createFileRoute('/onboarding/win')()

// Create/Update Routes

const MaintenanceLazyRoute = MaintenanceLazyImport.update({
  id: '/maintenance',
  path: '/maintenance',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../../../routes/maintenance.lazy').then((d) => d.Route),
)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const HistoryIndexLazyRoute = HistoryIndexLazyImport.update({
  id: '/history/',
  path: '/history/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../../../routes/history/index.lazy').then((d) => d.Route),
)

const SearchIndexRoute = SearchIndexImport.update({
  id: '/search/',
  path: '/search/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../../../routes/search/index.lazy').then((d) => d.Route),
)

const ChannelsIndexRoute = ChannelsIndexImport.update({
  id: '/channels/',
  path: '/channels/',
  getParentRoute: () => rootRoute,
} as any)

const OnboardingWinLazyRoute = OnboardingWinLazyImport.update({
  id: '/onboarding/win',
  path: '/onboarding/win',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../../../routes/onboarding/win.lazy').then((d) => d.Route),
)

const SearchChannelIdRoute = SearchChannelIdImport.update({
  id: '/search/$channelId',
  path: '/search/$channelId',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../../../routes/search/$channelId.lazy').then((d) => d.Route),
)

const ChannelsChannelIdRoute = ChannelsChannelIdImport.update({
  id: '/channels/$channelId',
  path: '/channels/$channelId',
  getParentRoute: () => rootRoute,
} as any)

const SummaryYoutubeChannelIdRoute = SummaryYoutubeChannelIdImport.update({
  id: '/summary/youtube/$channelId',
  path: '/summary/youtube/$channelId',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../../../routes/summary/youtube/$channelId.lazy').then(
    (d) => d.Route,
  ),
)

const RedirectShareKakaotalkRoute = RedirectShareKakaotalkImport.update({
  id: '/redirect/share/kakaotalk',
  path: '/redirect/share/kakaotalk',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../../../routes/redirect/share/kakaotalk.lazy').then(
    (d) => d.Route,
  ),
)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/maintenance': {
      id: '/maintenance'
      path: '/maintenance'
      fullPath: '/maintenance'
      preLoaderRoute: typeof MaintenanceLazyImport
      parentRoute: typeof rootRoute
    }
    '/channels/$channelId': {
      id: '/channels/$channelId'
      path: '/channels/$channelId'
      fullPath: '/channels/$channelId'
      preLoaderRoute: typeof ChannelsChannelIdImport
      parentRoute: typeof rootRoute
    }
    '/search/$channelId': {
      id: '/search/$channelId'
      path: '/search/$channelId'
      fullPath: '/search/$channelId'
      preLoaderRoute: typeof SearchChannelIdImport
      parentRoute: typeof rootRoute
    }
    '/onboarding/win': {
      id: '/onboarding/win'
      path: '/onboarding/win'
      fullPath: '/onboarding/win'
      preLoaderRoute: typeof OnboardingWinLazyImport
      parentRoute: typeof rootRoute
    }
    '/channels/': {
      id: '/channels/'
      path: '/channels'
      fullPath: '/channels'
      preLoaderRoute: typeof ChannelsIndexImport
      parentRoute: typeof rootRoute
    }
    '/search/': {
      id: '/search/'
      path: '/search'
      fullPath: '/search'
      preLoaderRoute: typeof SearchIndexImport
      parentRoute: typeof rootRoute
    }
    '/history/': {
      id: '/history/'
      path: '/history'
      fullPath: '/history'
      preLoaderRoute: typeof HistoryIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/redirect/share/kakaotalk': {
      id: '/redirect/share/kakaotalk'
      path: '/redirect/share/kakaotalk'
      fullPath: '/redirect/share/kakaotalk'
      preLoaderRoute: typeof RedirectShareKakaotalkImport
      parentRoute: typeof rootRoute
    }
    '/summary/youtube/$channelId': {
      id: '/summary/youtube/$channelId'
      path: '/summary/youtube/$channelId'
      fullPath: '/summary/youtube/$channelId'
      preLoaderRoute: typeof SummaryYoutubeChannelIdImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/maintenance': typeof MaintenanceLazyRoute
  '/channels/$channelId': typeof ChannelsChannelIdRoute
  '/search/$channelId': typeof SearchChannelIdRoute
  '/onboarding/win': typeof OnboardingWinLazyRoute
  '/channels': typeof ChannelsIndexRoute
  '/search': typeof SearchIndexRoute
  '/history': typeof HistoryIndexLazyRoute
  '/redirect/share/kakaotalk': typeof RedirectShareKakaotalkRoute
  '/summary/youtube/$channelId': typeof SummaryYoutubeChannelIdRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/maintenance': typeof MaintenanceLazyRoute
  '/channels/$channelId': typeof ChannelsChannelIdRoute
  '/search/$channelId': typeof SearchChannelIdRoute
  '/onboarding/win': typeof OnboardingWinLazyRoute
  '/channels': typeof ChannelsIndexRoute
  '/search': typeof SearchIndexRoute
  '/history': typeof HistoryIndexLazyRoute
  '/redirect/share/kakaotalk': typeof RedirectShareKakaotalkRoute
  '/summary/youtube/$channelId': typeof SummaryYoutubeChannelIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/maintenance': typeof MaintenanceLazyRoute
  '/channels/$channelId': typeof ChannelsChannelIdRoute
  '/search/$channelId': typeof SearchChannelIdRoute
  '/onboarding/win': typeof OnboardingWinLazyRoute
  '/channels/': typeof ChannelsIndexRoute
  '/search/': typeof SearchIndexRoute
  '/history/': typeof HistoryIndexLazyRoute
  '/redirect/share/kakaotalk': typeof RedirectShareKakaotalkRoute
  '/summary/youtube/$channelId': typeof SummaryYoutubeChannelIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/maintenance'
    | '/channels/$channelId'
    | '/search/$channelId'
    | '/onboarding/win'
    | '/channels'
    | '/search'
    | '/history'
    | '/redirect/share/kakaotalk'
    | '/summary/youtube/$channelId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/maintenance'
    | '/channels/$channelId'
    | '/search/$channelId'
    | '/onboarding/win'
    | '/channels'
    | '/search'
    | '/history'
    | '/redirect/share/kakaotalk'
    | '/summary/youtube/$channelId'
  id:
    | '__root__'
    | '/'
    | '/maintenance'
    | '/channels/$channelId'
    | '/search/$channelId'
    | '/onboarding/win'
    | '/channels/'
    | '/search/'
    | '/history/'
    | '/redirect/share/kakaotalk'
    | '/summary/youtube/$channelId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  MaintenanceLazyRoute: typeof MaintenanceLazyRoute
  ChannelsChannelIdRoute: typeof ChannelsChannelIdRoute
  SearchChannelIdRoute: typeof SearchChannelIdRoute
  OnboardingWinLazyRoute: typeof OnboardingWinLazyRoute
  ChannelsIndexRoute: typeof ChannelsIndexRoute
  SearchIndexRoute: typeof SearchIndexRoute
  HistoryIndexLazyRoute: typeof HistoryIndexLazyRoute
  RedirectShareKakaotalkRoute: typeof RedirectShareKakaotalkRoute
  SummaryYoutubeChannelIdRoute: typeof SummaryYoutubeChannelIdRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  MaintenanceLazyRoute: MaintenanceLazyRoute,
  ChannelsChannelIdRoute: ChannelsChannelIdRoute,
  SearchChannelIdRoute: SearchChannelIdRoute,
  OnboardingWinLazyRoute: OnboardingWinLazyRoute,
  ChannelsIndexRoute: ChannelsIndexRoute,
  SearchIndexRoute: SearchIndexRoute,
  HistoryIndexLazyRoute: HistoryIndexLazyRoute,
  RedirectShareKakaotalkRoute: RedirectShareKakaotalkRoute,
  SummaryYoutubeChannelIdRoute: SummaryYoutubeChannelIdRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/maintenance",
        "/channels/$channelId",
        "/search/$channelId",
        "/onboarding/win",
        "/channels/",
        "/search/",
        "/history/",
        "/redirect/share/kakaotalk",
        "/summary/youtube/$channelId"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/maintenance": {
      "filePath": "maintenance.lazy.tsx"
    },
    "/channels/$channelId": {
      "filePath": "channels/$channelId.tsx"
    },
    "/search/$channelId": {
      "filePath": "search/$channelId.tsx"
    },
    "/onboarding/win": {
      "filePath": "onboarding/win.lazy.tsx"
    },
    "/channels/": {
      "filePath": "channels/index.tsx"
    },
    "/search/": {
      "filePath": "search/index.tsx"
    },
    "/history/": {
      "filePath": "history/index.lazy.tsx"
    },
    "/redirect/share/kakaotalk": {
      "filePath": "redirect/share/kakaotalk.tsx"
    },
    "/summary/youtube/$channelId": {
      "filePath": "summary/youtube/$channelId.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
