import { v } from "~libs/valibot";
import { TimestampSchema } from "./youtube-summary-shared.schema";

export enum SummaryStatus$Server {
  requestReceived = "received_request",
  loadingInfo = "get_video_info",
  complete = "complete_summary",
  error = "error",
  lengthExceeded = "length_error",
  videoNotFound = "video_not_found_error",
  scriptsNotFound = "scripts_not_found_error",
  liveStreamingVideo = "live_stream_error",
  invalidData = "invalid_data",
  contentFilterError = "content_filter",
  jsonParsingError = "json_parsing_error",
}

export const YoutubeSummaryServerSchemas = {
  Init: v.null(),

  RequestReceived: v.object({
    type: v.literal(SummaryStatus$Server.requestReceived),
  }),

  LoadingInfo: v.object({
    type: v.literal(SummaryStatus$Server.loadingInfo),
    video_id: v.string(),
    title: v.optional(v.string()),
  }),

  Complete: v.object({
    type: v.literal(SummaryStatus$Server.complete),
    video_id: v.string(),
    title: v.string(),

    /**
     * Youtube 자막 API 데이터
     * @todo 변동 가능성 큼
     * @
     */
    metadata: v.array(
      v.object({
        chapter_idx: v.union([v.string(), v.number()]),
        chapter_title: v.union([v.string(), v.number()]),
        scripts: v.array(
          v.object({ timestamp: TimestampSchema, content: v.string() }),
        ),
      }),
    ),

    /**
     * llm 응답
     * @todo 변동 가능성 큼
     */
    summary: v.object({
      total_summary: v.optional(v.array(v.string())),
      chapters: v.array(
        v.object({
          index: v.number(),
          title: v.string(),
          timestamp: TimestampSchema,
          summary: v.array(v.string()),
          detail: v.array(v.string()),
        }),
      ),
    }),

    suggest_questions: v.optional(v.array(v.string())),
  }),

  /**
   * @example
   ```json
   {
    "_id": "66b09951a65b3126800d37f9",
    "video_id": "ZcwA0xt8FlQ",
    "type": "error",
    "updated_at": "2024-08-05T09:20:17.757000"
   }
   ```
   */
  ServerError: v.object({
    type: v.literal(SummaryStatus$Server.error),
  }),

  VideoNotFoundError: v.object({
    type: v.literal(SummaryStatus$Server.videoNotFound),
    video_id: v.string(),
  }),

  /**
   * @example
   ```json
   {
    "_id": "66ac9aedca1a7e6ab48ecd6a",
    "video_id": "UH7wkvcf0ys",
    "type": "invalid_data",
    "title": "Facebook and memcached - Tech Talk",
    "length": "27:56",
    "description": "In this video from 2008, Mark Zuckerberg, CEO at Facebook, talks about how the company uses memcached for caching and storage performance.",
    "category_id": 28,
    "language": "Unknown",
    "updated_at": "2024-08-02T08:44:34.771000"
  }
   ```
   */
  InvalidDataError: v.object({
    type: v.literal(SummaryStatus$Server.invalidData),
    video_id: v.string(),
  }),

  ContentFilterError: v.object({
    type: v.literal(SummaryStatus$Server.contentFilterError),
  }),
} as const;
