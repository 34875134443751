/**
 * prod - https://api.alan.est.ai/api
 * stage - https://func08-api-estsoft-algpt-stage.azurewebsites.net/api
 * dev - https://gepeto-api-function.azurewebsites.net/api
 */
type API_BASE_URLS =
  | "https://api.alan.est.ai/api"
  | "https://func08-api-estsoft-algpt-stage.azurewebsites.net/api"
  | "https://gepeto-api-function.azurewebsites.net/api";

export const API_BASE_URL: API_BASE_URLS = import.meta.env.VITE_API_URL ?? "";

export const API_VERSION_PREFIXES = {
  v1: "/v1",
  v2: "/v2",
} as const;

export const HEADER_KEY = {
  AUTHORIZATION: "Authorization",
  RETRY_COUNT: "X-Retry-Count",
} as const;

export const ERROR_TYPES = {
  KyHttpError: "KyHttpError",
  Unknown: "Unknown",
} as const;

/**
 * @todo constants에 정의된 에러 코드들 정리 필요
 */
export const ERROR_CODES = {
  EXPIRED_TOKEN: "E0003",
  INVALID_TOKEN: "E1002",
} as const;

export const STATUS_CODES = {
  /** 토큰 만료 등 토큰은 있는데 비정상 */
  UNAUTHORIZED: 401,
  /** 토큰 등 인증정보가 아예 없음 */
  FORBIDDEN: 403,

  NOT_FOUND: 404,

  REQUEST_TIMEOUT: 408,

  /** 사용량 초과 */
  RATE_LIMIT: 429,

  SERVER_ERROR: 500,
} as const;

export const EXCEPTION_PATHS = {
  SERVER_MAINTENANCE: "/maintenance",
  SERVER_ERROR: "/error",
  NOT_FOUND: "/404",
} as const;
