import {
  type ChangeEvent,
  type KeyboardEvent,
  type MouseEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { match } from "ts-pattern";
import ArrowImg from "~assets/images/ico_related_questions_arrow.png";
import LoadingIcon from "~assets/images/ico_step_loading.png";
import { useUser } from "~features/auth";
import { useChannel, useQueryChannel } from "~features/channel";
import { useDeprecatedPersonaContext, usePersonas } from "~features/personas";
import { Prompt, RawTextContext } from "~features/prompt";
import {
  useMutationSendPromptInChannel,
  useRequestValidation,
  validateRequestInChannel,
} from "~features/prompt/mutations";
import { categorizePrompt } from "~features/prompt/utils";
import { setSharingWarningToast } from "~features/share";
import { createInfoToast, setDefaultErrorToast } from "~features/toast";
import { CONTAINER_IDS } from "~features/toast/constants";
import { SearchButton } from "~features/ui/prompt/buttons";
import { SanitizerModal, xllmHooks } from "~features/xllm";
import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";
import type { SearchResult } from "../schemas";
import { useIsPendingContext } from "../use-is-pending.context";
import { useSendSearchTitle } from "../use-send-search-title";

import styles from "./question.module.scss";

type SearchResultRelatedQuestionsProps = {
  title: string;
  questions: SearchResult.Question[];
  className?: string;
};

const PLACEHOLDERS = {
  DEFAULT: "궁금한 내용을 이어서 검색하세요.",
  DEPRECATED: "관련된 질문을 입력할 수 없어요.",
} as const;

/** @todo Providers - Presenter 분리 */
export function SearchResultRelatedQuestions({
  title = "관련된 질문",
  questions,
  className,
}: SearchResultRelatedQuestionsProps) {
  const { user } = useUser();

  const { channelId } = useChannel();
  const { data: channel, isSuccess: isSuccessQueryChannel } =
    useQueryChannel(channelId);

  const { isPending } = useIsPendingContext();
  const [rawText, setRawText] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { requestNativeNotificationPermission } = useSendSearchTitle();

  const { isDeprecated } = useDeprecatedPersonaContext();
  const { matchValidationResult } = useRequestValidation();
  const { searchR1Id } = usePersonas();
  const {
    isModalOpen: isSanitizerModalOpen,
    checkSanitizer,
    isPendingSanitizer,
    setEnabled,
  } = xllmHooks.useSanitizerContext();
  const { mutate } = useMutationSendPromptInChannel({
    channelId,
    userId: user.id,
    personaId: channel?.personaId ?? "",
    xllmEnabled: channel?.xllmEnabled ?? false,
  });

  /** @todo 서버단에서 실패한 경우도 처리해야 하는지 */
  const isQuestionsLoading = useMemo(() => questions.length === 0, [questions]);

  const sendPrompt = useCallback(
    async (prompt?: string) => {
      if (isPending) return;
      if (!channel?.personaId) return;

      const _prompt = (prompt || rawText || "").trim();

      const validationResult = validateRequestInChannel({
        prompt: _prompt,
        user,
        channelId,
        personaId: channel.personaId,
      });
      const { hasYoutubeUrl } = categorizePrompt(_prompt);
      if (hasYoutubeUrl) {
        setDefaultErrorToast("유튜브 요약은 상단 검색창에서 요청할 수 있어요.");
        return;
      }

      return matchValidationResult(validationResult, {
        withValid: () => {
          if (channel?.isSharing) setSharingWarningToast();

          mutate(_prompt);
          requestNativeNotificationPermission();
        },
      });
    },
    [
      isPending,
      user,
      channelId,
      channel?.personaId,
      channel?.isSharing,
      rawText,
      matchValidationResult,
      mutate,
      requestNativeNotificationPermission,
    ],
  );

  const checkSanitizerAndSend = useCallback(
    async (prompt: string) => {
      if (channel?.xllmEnabled && channel?.personaId === searchR1Id) {
        createInfoToast({
          containerId: CONTAINER_IDS.BOTTOM,
          toastId: "xllm-in-channel-questions",
        })("첫 질문의 설정에 따라 xLLM 보호 모드가 동작합니다");

        if (!(await checkSanitizer(prompt)).isOk) {
          return;
        }
      }

      sendPrompt(prompt);
      setRawText("");
      return;
    },
    [
      channel?.xllmEnabled,
      channel?.personaId,
      searchR1Id,
      checkSanitizer,
      sendPrompt,
    ],
  );

  const onChangeTextarea = createEventHandler<ChangeEvent<HTMLTextAreaElement>>(
    {
      handler: (e) => {
        const rawValue = e.currentTarget.value;
        setRawText(rawValue);
      },
    },
  );

  const onKeyUpTextarea = createEventHandler<
    KeyboardEvent<HTMLTextAreaElement>
  >({
    preventDefault: false,
    handler: async (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();

        await checkSanitizerAndSend(rawText);
      }
    },
  });

  const onClickTextarea = createEventHandler<MouseEvent>({
    handler: () => {
      if (isDeprecated) {
        setDefaultErrorToast(
          "이전 대화 모드에서는 추가 질문을 할 수 없습니다.",
        );
      }
    },
    stopPropagation: false,
  });

  useEffect(() => {
    if (isSuccessQueryChannel) {
      setEnabled(channel.xllmEnabled);
    }
  }, [isSuccessQueryChannel, channel?.xllmEnabled, setEnabled]);

  return (
    <RawTextContext.Provider value={{ rawText, setRawText }}>
      <Prompt.Root
        context={{
          textareaEnabled: !isDeprecated,
          sendPrompt,
          placeholderText: isDeprecated
            ? PLACEHOLDERS.DEPRECATED
            : PLACEHOLDERS.DEFAULT,
          textareaRef,
          onChangeTextarea,
          onKeyUpTextarea,
          textareaRows: 1,
        }}
        className={cn(className)}
      >
        <div className={styles.question_wrapper}>
          <h4 className={styles.question_title}>{title}</h4>
          <div className={styles.question_box}>
            <Prompt.FormContainer className={cn()}>
              <Prompt.Form>
                <ul className={styles.question_list}>
                  {match({ isQuestionsLoading, isDeprecated })
                    .with({ isDeprecated: true }, () => null)
                    .with({ isQuestionsLoading: true }, () => (
                      <QuestionsLoading />
                    ))
                    .otherwise(() =>
                      questions.map(({ question }) => (
                        <li
                          key={`search-result-${question}`}
                          className={styles.question}
                        >
                          <ArrowIcon />
                          <p
                            onClick={() => {
                              sendPrompt(question);
                            }}
                          >
                            {question}
                          </p>
                        </li>
                      )),
                    )}

                  <li
                    className={cn(styles.question, styles.question_prompt, {
                      [styles.disabled]: isDeprecated,
                    })}
                    onClick={onClickTextarea}
                  >
                    <ArrowIcon
                      className={cn(rawText.length > 0 ? "" : styles.disabled)}
                    />
                    <Prompt.Textarea
                      ref={textareaRef}
                      className={cn({
                        [styles.textarea_disabled]: isDeprecated,
                      })}
                    />
                    {!isDeprecated && (
                      <Prompt.Buttons ishideModel={true}>
                        <SearchButton
                          onSubmit={() => checkSanitizerAndSend(rawText)}
                          isDisabled={isPendingSanitizer}
                        />
                      </Prompt.Buttons>
                    )}
                  </li>
                </ul>
              </Prompt.Form>
            </Prompt.FormContainer>
          </div>
        </div>
      </Prompt.Root>

      {isSanitizerModalOpen && (
        <SanitizerModal
          sendPrompt={sendPrompt}
          rawText={rawText}
          setRawText={setRawText}
        />
      )}
    </RawTextContext.Provider>
  );
}

const QuestionsLoading = memo(function QuestionsLoading() {
  return (
    <li className={styles.question_loading}>
      <span className={styles.icon}>
        <img src={LoadingIcon} alt="" />
      </span>
      <p>관련된 질문을 생성하고 있어요.</p>
    </li>
  );
});

const ArrowIcon = memo(function ArrowIcon({
  className,
}: {
  className?: string;
}) {
  return (
    <span className={cn(styles.ico_arrow, className)}>
      <img src={ArrowImg} alt="" />
    </span>
  );
});
