import { useMemo } from "react";
import { create } from "zustand";
import { useWindowWidth } from "~hooks/use-window-width";
import type { SearchResult } from "../schemas";

type SelectWebSourceStates = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;

  selectedUrl: string;
  selectUrl: (url: string) => void;

  selectedWebSource?: SearchResult.WebSource;
  selectWebSource: (webSource?: SearchResult.WebSource) => void;
};

const useWebSourceBottomSheetStates = create<SelectWebSourceStates>((set) => ({
  isOpen: false,
  setIsOpen: (isOpen) => set({ isOpen }),

  selectedUrl: "",
  selectUrl: (url) => set({ selectedUrl: url }),

  selectedWebSource: undefined,
  selectWebSource: (webSource) => set({ selectedWebSource: webSource }),
}));

export const useWebSourceBottomSheet = () => {
  const bottomSheetStates = useWebSourceBottomSheetStates();

  const { isMobileSmallSize } = useWindowWidth();
  const enabled = useMemo(() => isMobileSmallSize, [isMobileSmallSize]);

  return {
    enabled,
    ...bottomSheetStates,
  };
};
