import { useMutation, useQueryClient } from "@tanstack/react-query";
import { KyClient } from "~clients/fetch";
import { useChannel } from "~features/channel";
import { type Feedbacks, VotedTypes } from "./message-feedback.schema";
import {
  type SendFeedbackMessage,
  transformRequest,
} from "./send-feedback-message.schema";
import { getQueryKeyFeedbacks } from "./use-query-feedback";

export const useMutationSendFeedbackMessage = () => {
  const queryClient = useQueryClient();
  const { channelId } = useChannel();

  return useMutation({
    mutationFn: sendFeedbackMessage,

    onMutate: async ({ messageId, reasonsIds, feedbackMessage }) => {
      const queryKey = getQueryKeyFeedbacks({
        channelId,
        messageIds: [messageId],
      });

      queryClient.setQueryData<Feedbacks>(queryKey, (prev) => {
        const nextFeedbacks = new Map(prev ?? []);
        nextFeedbacks.set(messageId, {
          type: VotedTypes.DOWN,
          downReasons: reasonsIds,
          message: feedbackMessage,
        });
        return nextFeedbacks;
      });

      return {
        queryKey,
      };
    },

    onError: (_err, { messageId }, context) => {
      if (!context?.queryKey) return;

      queryClient.setQueryData<Feedbacks>(context.queryKey, (prev) => {
        const nextFeedbacks = new Map(prev ?? []);
        nextFeedbacks.delete(messageId);
        return nextFeedbacks;
      });
    },
  });
};

/**
 * @example
 * - PUT {@link https://func08-api-estsoft-algpt-stage.azurewebsites.net/api/v2/thumbs}
 */
const API_PATH = "api/v2/thumbs";

const sendFeedbackMessage = async (request: SendFeedbackMessage) => {
  KyClient.put(API_PATH, {
    prefixUrl: import.meta.env.VITE_API_BASE_URL,
    json: transformRequest(request),
  });
};
