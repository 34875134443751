import { type InferOutput, v } from "~libs/valibot";

export enum VotedTypes {
  UP = "UP",
  DOWN = "DOWN",
}

/**
 * feedbacks response
 * 
 * @example
 * 
 ```json
  [
    {
      "_id": "66a33beecd798ccceb4b5bbe",
      "user_id": "65af73b6583a64a91bd6e002",
      "message_id": "6686069b43c8739fe1c26db3",
      "request_id": "",
      "type": "UP",
      "selected_ids": null,
      "message": null,
      "created_at": "2024-07-26T06:02:22.663000"
    },
    {
      "_id": "66a33bf5cd798ccceb4b5bbf",
      "user_id": "65af73b6583a64a91bd6e002",
      "message_id": "66860788297d39cf4ef62e8a",
      "request_id": "",
      "type": "UP",
      "selected_ids": null,
      "message": null,
      "created_at": "2024-07-26T06:02:29.506000"
    },
    {
      "_id": "66a33bf8cd798ccceb4b5bc0",
      "user_id": "65af73b6583a64a91bd6e002",
      "message_id": "668607e0cba61522b4052e6a",
      "request_id": "",
      "type": "DOWN",
      "selected_ids": null,
      "message": "",
      "created_at": "2024-07-26T06:02:35.665000"
    },
    {
      "_id": "66a33c00cd798ccceb4b5bc1",
      "user_id": "65af73b6583a64a91bd6e002",
      "message_id": "6686085beacc653ad07b2ffe",
      "request_id": "",
      "type": "DOWN",
      "selected_ids": [
          "64c9f6ded4c34048a4d0ab6b"
      ],
      "message": "651651",
      "created_at": "2024-07-26T06:02:46.509000"
    },
    {
      "_id": "66a33c08cd798ccceb4b5bc2",
      "user_id": "65af73b6583a64a91bd6e002",
      "message_id": "6686098218447053d02c57f2",
      "request_id": "",
      "type": "UP",
      "selected_ids": null,
      "message": null,
      "created_at": "2024-07-26T06:02:48.457000"
    },
    {
      "_id": "66a33c0acd798ccceb4b5bc3",
      "user_id": "65af73b6583a64a91bd6e002",
      "message_id": "66860a04437f853f8aa4cec9",
      "request_id": "",
      "type": "DOWN",
      "selected_ids": [
          "64c9f6b4d4c34048a4d0ab6a"
      ],
      "message": "",
      "created_at": "2024-07-26T06:02:52.664000"
    },
    {
      "_id": "66a33c0ecd798ccceb4b5bc4",
      "user_id": "65af73b6583a64a91bd6e002",
      "message_id": "66860a39437f853f8aa4ceca",
      "request_id": "",
      "type": "DOWN",
      "selected_ids": [
          "64c9f678d4c34048a4d0ab69"
      ],
      "message": "ㅈ4ㄷ2",
      "created_at": "2024-07-26T06:02:58.224000"
    }
  ]
 ```
 */
const FeedbacksSchema$Server = v.array(
  v.object({
    message_id: v.string(),
    type: v.enum(VotedTypes),
    /** down 선택 이유 */
    selected_ids: v.nullable(v.array(v.string())),
    message: v.nullable(v.string()),

    /** @description 아래는 미사용 properties */
    // _id: vString(),
    // user_id: vString(),
    // created_at: vString(),
    // request_id: vString(),
  }),
);

/** @todo Feedbacks에서 추론하는 방법? */
export type Feedback = {
  type: VotedTypes;
  downReasons: string[];
  message: string;
};
export type Feedbacks = InferOutput<typeof FeedbacksSchema>;

const FeedbacksSchema = v.pipe(
  FeedbacksSchema$Server,

  v.transform((input) => {
    return new Map(
      input.map((feedback) => [
        feedback.message_id,
        {
          type: feedback.type,
          downReasons: feedback.selected_ids ?? [],
          message: feedback.message ?? "",
        } as Feedback,
      ]),
    );
  }),
);

export const transformResponse = (response: unknown) =>
  v.parse(FeedbacksSchema, response, { abortEarly: true });
