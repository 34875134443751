import { type MutableRefObject, createContext, useContext } from "react";
import type { SearchResult } from "~features/search/schemas";
import type { YoutubeSummary } from "./youtube-summary.schema";

export type VideoHandler = {
  seekAndPlay: (timeSec: number) => void;
};

type YoutubeSummaryContextStates = {
  channelId: string;
  channelChanged: boolean;
  summaryResult: YoutubeSummary;
  summaryStatus: {
    isStarted: boolean;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
  };
  isFetchedAfterMount: boolean;
  videoHandlerRef: MutableRefObject<VideoHandler | null>;

  /** @todo toolSteps/videos/images 제거 - text only chat */
  messages: SearchResult.Page;
  shouldShowVideoRelatedQuestions: boolean;
};

export const YoutubeSummaryContext =
  createContext<YoutubeSummaryContextStates | null>(null);

export const useYoutubeSummaryContext = () => {
  const context = useContext(YoutubeSummaryContext);
  if (!context) {
    throw new Error(
      "useYoutubeSummaryContext must be used within a YoutubeSummaryProvider",
    );
  }

  return context;
};
