import type { MouseEvent, PropsWithChildren } from "react";
import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";
import { Background } from "./background";
import styles from "./bottom-sheet.module.scss";
import { CloseButton } from "./close-button";
import { Container } from "./container";

type BottomSheetProps = PropsWithChildren<{
  closeBottomSheet: () => void;
  className?: string;
  isHideCloseButton?: boolean;
}>;

export function Root(props: BottomSheetProps) {
  const closeBottomSheet = createEventHandler<MouseEvent>({
    handler: () => {
      props.closeBottomSheet();
    },
  });

  return (
    <section className={cn(styles.dimmed, props.className)}>
      <Background closeBottomSheet={closeBottomSheet} />
      <Container>
        {!props.isHideCloseButton && (
          <CloseButton closeBottomSheet={closeBottomSheet} />
        )}

        {props?.children}
      </Container>
    </section>
  );
}
