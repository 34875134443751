import { useNavigate } from "@tanstack/react-router";
import type { MouseEvent } from "react";
import { useState } from "react";
import { useChannel } from "~features/channel";
import { useGuestModeStore } from "~features/guest-mode";
import { createInfoToast } from "~features/toast";
import { CONTAINER_IDS } from "~features/toast/constants";
import { GuideTooltip } from "~features/ui/guide-tooltip";
import styles from "./copy-channel-button.module.scss";
import { useMutationCopyChannel } from "./use-mutation-copy-channel";
import { useShareTooltips } from "./use-share-tooltips";

export function CopyChannelButton() {
  const { channelId } = useChannel();
  const { mutateAsync } = useMutationCopyChannel();
  const { enabled: isGuestMode, setShowMainModal } = useGuestModeStore();

  const [isClicked, setIsClicked] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const { isTooltipOpen, setIsTooltipOpen } = useShareTooltips((s) => ({
    isTooltipOpen: s.isCopyChannelGuideOpen,
    setIsTooltipOpen: s.setIsCopyChannelGuideOpen,
  }));

  const navigate = useNavigate();

  const onClick = (e: MouseEvent) => {
    e.stopPropagation();

    if (isCopied || isClicked) return;

    if (isGuestMode) {
      setShowMainModal(true);
      return;
    }

    setIsClicked(true);
    mutateAsync(channelId)
      .then(({ isCopySuccess, newChannelId }) => {
        if (!isCopySuccess) throw new Error("채널 복사하기에 실패했습니다");

        createCopySuccessToast();
        setIsCopied(true);

        window.setTimeout(() => {
          navigate({
            to: "/channels/$channelId",
            params: {
              channelId: newChannelId,
            },
          });
        }, 2_000);
      })
      .catch(() => {
        setIsCopied(false);
      })
      .finally(() => {
        setIsClicked(false);
      });
  };

  return (
    <div className={styles.btn_save_history_wrapper}>
      <button
        type="button"
        onClick={onClick}
        className={styles.btn_save_history}
        aria-label="save to my history"
      >
        {isCopied ? (
          <CopySuccessIcon />
        ) : (
          <CopyReadyIcon isClicked={isClicked} />
        )}
      </button>

      {isTooltipOpen && (
        <GuideTooltip
          isTop={true}
          title="내 검색 기록으로 추가하기"
          text={
            "다른 사람에게 공유받은 검색 결과를\n내 검색 결과로 추가할 수 있어요."
          }
          onClose={() => {
            setIsTooltipOpen(false);
          }}
        />
      )}
    </div>
  );
}

const createCopySuccessToast = () =>
  createInfoToast({
    containerId: CONTAINER_IDS.BOTTOM,
    toastId: "share-copy-success",
  })("내 검색 기록에 추가되었습니다.");

const CopySuccessIcon = () => (
  <svg
    role="img"
    aria-label="channel copied success"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path d="M2.75 7L6.75 11L13.25 4.5" stroke="#1A212C" strokeWidth="1.5" />
  </svg>
);

const CopyReadyIcon = ({ isClicked }: { isClicked: boolean }) => (
  <svg
    role="img"
    aria-label="ready to copy channel"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    style={{ opacity: isClicked ? 0.6 : 1 }}
  >
    <path
      d="M14 2C8.25 2 8.25 5.5 8.25 9"
      stroke="#1A212C"
      stroke-width="1.5"
      stroke-linecap="square"
    />
    <path
      d="M5.75 7.5L8.25 10L10.75 7.5"
      stroke="#1A212C"
      stroke-width="1.5"
      stroke-linecap="square"
    />
    <path
      d="M6 2H3C2.44772 2 2 2.44772 2 3V13C2 13.5523 2.44772 14 3 14H13C13.5523 14 14 13.5523 14 13V10"
      stroke="#1A212C"
      stroke-width="1.5"
      stroke-linecap="square"
    />
  </svg>
);
