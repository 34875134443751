import { useModal } from "./use-modal";

import styles from "./modal.module.scss";

export function NoticeModal() {
  const { modalContent, resetModalContents } = useModal();

  return (
    // modalData.defaultClose가 false면 배경 블러 있음
    <div
      className={`${styles.modal_wrap} ${styles.notice} ${
        (modalContent.hasCloseButton === undefined ||
          modalContent.hasCloseButton) &&
        styles.bg_transparent
      }`}
    >
      <div className={styles.modal}>
        {/* modalData.defaultClose가 false면 닫기버튼 없음 */}
        {(modalContent.hasCloseButton === undefined ||
          modalContent.hasCloseButton) && (
          <div className={styles.modal_top}>
            <button
              type="button"
              aria-label="close button"
              className={styles.btn_close}
              onClick={resetModalContents}
            >
              <span className="blind">닫기</span>
            </button>
          </div>
        )}

        <div className={styles.modal_contents}>
          {modalContent.img && <img src={modalContent.img} alt="" />}
          <h3 dangerouslySetInnerHTML={{ __html: modalContent.titleHtml }} />
          {modalContent.message && <p>{modalContent.message}</p>}
        </div>
        <div className={styles.modal_btn_wrap}>
          <button
            type="button"
            //검정버튼인 경우 black 클래스 추가
            className={modalContent.isButtonBgColorBlack ? "" : styles.primary}
            onClick={() =>
              modalContent.confirmEvent ? modalContent.confirmEvent() : ""
            }
          >
            <span>{modalContent.button}</span>
          </button>
        </div>
      </div>
    </div>
  );
}
