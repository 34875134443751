import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";
import { Button } from "../button";
import styles from "./guide-tooltip.module.scss";

type GuideTooltipProps = {
  title: string;
  text: string;
  isTop?: boolean;
  onClose: () => void;
};

export function GuideTooltip(props: GuideTooltipProps) {
  const closeTooltip = createEventHandler({
    handler: () => {
      props.onClose();
    },
  });

  return (
    <div className={cn(props.isTop && styles.top, styles.tooltip_wrapper)}>
      <strong className={styles.title}>{props.title}</strong>
      <strong className={styles.text}>{props.text}</strong>
      <Button
        overrideClass={styles.btn_close}
        ButtonType="onlyIcon"
        iconBefore={
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M13 3L3 13" stroke="white" />
            <path d="M13 13L3 3" stroke="white" />
          </svg>
        }
        onClick={closeTooltip}
        aria-label="close button"
      />
      <i className={styles.ico_arrow} aria-hidden>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
        >
          <path
            d="M5.2381 6L-2.62268e-07 0L10 -4.37114e-07L5.2381 6Z"
            fill="#1179EF"
          />
        </svg>
      </i>
    </div>
  );
}

export default GuideTooltip;
