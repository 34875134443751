import {
  MESSAGE_STATUS$SERVER,
  MESSAGE_STOP_REASON$SERVER,
  MESSAGE_USER_ROLE,
} from "~features/messages/constants";
import { type InferOutput, v } from "~libs/valibot";

enum MessageActionTypes$Api {
  INIT = "",
  /** keyword일때 speak ← string[] */
  KEYWORD = "keyword",
  SEARCH_WEB = "search_web",
  SEARCH_NEWS = "search_news",
  SEARCH_ZUM_NEWS = "search_zum_news",
  SEARCH_WEATHER = "search_weather",
  SUMMARIZE_URL = "summarize_url",
  SUMMARIZE_REFERENCES = "summarize_references",
}

const MessageActionSchema$Api = v.object({
  name: v.enum(MessageActionTypes$Api),
  speak: v.union([v.string(), v.array(v.string())]),
  created_at: v.string(),

  /** @description 미사용 */
  // arguments: v.unknown(),
});

/**
 * @example
 * ```json
 * {
    "number": 10,
    "source": "https://namu.wiki/w/%EC%97%90%EB%93%9C%EC%9B%8C%EB%93%9C%20%EB%A6%AC",
    "title": "에드워드 리 - 나무위키",
    "date": null,
    "content": "흑백요리사에서 에드워드 리와 마지막 결승전을 펼쳐 우승을 차지한 권성준이 에드워드 리에게 했던 \"잘근잘근 밟아주겠다\"는 방송 멘트가 다소 반감을 일으켜 \"건방진 ..."
  },
  {
    "number": 13,
    "source": "https://www.mk.co.kr/en/culture/11156611",
    "title": "Star chef Edward Lee (Korean name Lee Gyun), who won second ...",
    "date": "2024-11-01T15:23:41Z",
    "content": "He won the James Beard Award, also known as the Oscars for chefs, and served as the chef for a state dinner at the White House in 2023. Edward ..."
  }
 * ```
 */
const MessageReferenceSchema$Api = v.object({
  number: v.union([v.number(), v.string()]),
  source: v.string(),
  title: v.string(),
  date: v.optional(v.nullable(v.string())),
  content: v.string(),
  thumbnail: v.optional(v.nullable(v.string())),
});

/**
 * @example
 * ```json
 * {
    "title": "비오는 제주에서 카페는 못참지 | 제주패스 테마스토리 | 제주패스 ...",
    "link": "https://story.jejupass.com/web/storyType01/86",
    "image_url": "https://static-file.jejupass.com/download/119761",
    "imageWidth": 800,
    "imageHeight": 800,
    "thumbnail_url": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtA5wbpkp8ueQ6lIpv0TCzHxU2SdDpTRQk4NXrShwou5IXRgQ&s",
    "thumbnailWidth": 225,
    "thumbnailHeight": 225,
    "source": "제주패스",
    "number": 2
    }
 * ```
 */
const MessageImageSchema$Api = v.object({
  title: v.string(),
  link: v.string(),
  image_url: v.string(),
  imageWidth: v.number(),
  imageHeight: v.number(),
  thumbnail_url: v.string(),
  thumbnailWidth: v.number(),
  thumbnailHeight: v.number(),
  source: v.string(),
  number: v.number(),
});

const MessageVideoThumbnailSchema$Api = v.object({
  url: v.string(),
  width: v.number(),
  height: v.number(),
});

/**
 * @example
 * ```json
{
  "video_id": "01GRBpGBKCw",
  "title": "COMPLETE GUIDE to US Stock Market Investing! | Ankur Warikoo Hindi",
  "description": "If you do not know how to invest in US stocks, then this video will teach you A-Z of how to invest there, free of cost! This is how ...",
  "channel_title": "warikoo",
  "publish_time": "2년 전",
  "thumbnails": [
    {
      "url": "https://i.ytimg.com/vi/01GRBpGBKCw/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAR4dHFYkIKsU7aDzYx_P3chQSxfw",
      "width": 360,
      "height": 202
    },
    {
      "url": "https://i.ytimg.com/vi/01GRBpGBKCw/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAIZRWmUc-n2GJV42k6CNwtRJOJ_A",
      "width": 720,
      "height": 404
    }
  ]
}
 * ```
 */
const MessageVideoSchema$Api = v.object({
  video_id: v.string(),
  title: v.string(),
  description: v.string(),
  channel_title: v.string(),
  publish_time: v.nullable(v.string()),
  thumbnails: v.array(MessageVideoThumbnailSchema$Api),
});

const MessageSchema$Api = v.object({
  _id: v.string(),
  /** @description userMessage 일때 null */
  type: v.nullable(v.enum(MESSAGE_STATUS$SERVER)),
  channel_id: v.string(),
  request_id: v.string(),
  user_id: v.string(),
  persona_id: v.string(),
  userRole: v.enum(MESSAGE_USER_ROLE),
  content: v.string(),
  suggest_questions: v.array(v.string()),
  actions: v.array(MessageActionSchema$Api),
  think: v.optional(v.string()),
  /** @description userMessage/답변중 일때 null */
  stop_reason: v.nullable(v.enum(MESSAGE_STOP_REASON$SERVER)),
  references: v.optional(v.array(MessageReferenceSchema$Api), []),
  videos: v.optional(v.array(MessageVideoSchema$Api), []),
  images: v.optional(v.array(MessageImageSchema$Api), []),

  /** ISO DateTime - 2024-10-24T01:24:14.926000 */
  updated_at: v.string(),
});

const MessagesSchema$Api = v.object({
  messages: v.array(MessageSchema$Api),
  total: v.number(),
});

export const MessagesSchemas$Api = {
  Item: MessageSchema$Api,
  List: MessagesSchema$Api,
};

export type Message$Api = InferOutput<typeof MessageSchema$Api>;
