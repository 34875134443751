import { useCallback, useState } from "react";
import { createPortal } from "react-dom";
import XllmIcon from "~assets/images/ico_xllm.svg";
import { useGuestModeStore } from "~features/guest-mode";
import { UpdateModal } from "~features/modals";
import { NoticeXllmImage } from "~features/modals/images";
import { XllmNoticeBox } from "~features/modals/update";
import { createInfoToast } from "~features/toast";
import { CONTAINER_IDS } from "~features/toast/constants";
import { ToggleButton } from "~features/ui/toggle-button/toggle-button";
import { xllmHooks } from "~features/xllm";
import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";

import styles from "./xllm-box.module.scss";

export function XllmBox() {
  const { enabled, toggleEnabled } = xllmHooks.useSanitizerContext();
  const { enabled: isGuestMode, setShowMainModal } = useGuestModeStore();

  const toggleFn = useCallback(() => {
    if (isGuestMode) {
      setShowMainModal(true);
      return;
    }

    toggleEnabled();
    if (enabled) {
      setXllmDisabledToast(
        "xLLM 보호 모드를 끄면\nR1 사용시 중요 정보를 탐지할 수 없어요.",
      );
    }
  }, [enabled, isGuestMode, setShowMainModal, toggleEnabled]);

  // TODO: modal만 꺼지지 않고 메뉴까지 전부 꺼지는 이슈
  const [isPopupOpen, setIPopupsOpen] = useState(false);

  const onClickXllmPopup = () => {
    setIPopupsOpen(true);
  };

  return (
    <>
      <div
        className={cn(styles.xllm_box, {
          [styles.on]: enabled && !isGuestMode,
        })}
      >
        <button
          type="button"
          onClick={createEventHandler({ handler: onClickXllmPopup })}
        >
          <p className={styles.text}>
            <span className={cn(styles.ico_xllm)}>
              <img src={XllmIcon} alt="AlYac xLLM" />
            </span>
            보호 모드
            <span className={styles.ico_arrow}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
              >
                <path d="M2.25 7.5L5.75 4L2.25 0.5" stroke="#1A212C" />
              </svg>
            </span>
          </p>
        </button>
        <ToggleButton
          toggleFn={toggleFn}
          isChecked={enabled && !isGuestMode}
          checkedColor="green"
        />
      </div>

      {isPopupOpen &&
        createPortal(
          <UpdateModal
            title="R1 사용 시 xLLM으로 보호"
            details={
              "앨런에서 DeepSeek-R1 모델을 선택했을 때,\nxLLM의 보안 기술로 데이터를 안전하게 보호합니다.\n실시간으로 입력한 내용의 중요 정보를 탐지하고\n외부 유출을 차단하므로 안심하고 사용할 수 있어요."
            }
            img={NoticeXllmImage}
            imgWidthSize="274px"
            isTopCloseBtn
            isHideBottomBtn
            onClickConfirm={() => {
              setIPopupsOpen(false);
            }}
          >
            <XllmNoticeBox />
          </UpdateModal>,
          document.body,
        )}
    </>
  );
}

const setXllmDisabledToast = createInfoToast({
  containerId: CONTAINER_IDS.BOTTOM,
  toastId: "xllm-disabled",
});
