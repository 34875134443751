import { type MouseEvent, useCallback } from "react";
import { useCopyButton } from "~features/clipboard";
import { createInfoToast } from "~features/toast";
import { CONTAINER_IDS } from "~features/toast/constants";
import CopyIcon from "./images/img_share_copy.png";
import styles from "./sns-share-buttons.module.scss";
import { getSharableUrl } from "./utils";

export function UrlCopyButton() {
  const { copyUrl } = useUrlCopy();

  const onClickCopyButton = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();

      copyUrl();
    },
    [copyUrl],
  );

  return (
    <button
      type="button"
      onClick={onClickCopyButton}
      className={styles.btn_share_on}
      aria-label="copy link button"
    >
      <img src={CopyIcon} alt="icon" />
      <span>링크 복사</span>
    </button>
  );
}

export const useUrlCopy = () => {
  const { copy } = useCopyButton();

  const copyUrl = useCallback(() => {
    copy(getSharableUrl());
    createCopyInfoToast();
  }, [copy]);

  return {
    copyUrl,
  };
};

export const createCopyInfoToast = () =>
  createInfoToast({
    containerId: CONTAINER_IDS.BOTTOM,
    toastId: "sharing-copy-url",
  })("URL 복사 완료");
