import { STATUS_CODES } from "~clients/fetch";
import { MESSAGE_ERROR_CONTENTS } from "~features/messages/constants";
import { setDefaultErrorToast } from "~features/toast";
import { SummaryStatus$Server } from "./youtube-summary-server.schema";

const YOUTUBE_SUMMARY_CLIENT_ERRORS = {
  NOT_URL: "NOT_URL",
} as const;

/** @todo server error code/type -> client error code */
export const YOUTUBE_SUMMARY_ERROR_CODES = {
  INVALID_URL: "E0035",
  LENGTH_EXCEEDED: SummaryStatus$Server.lengthExceeded,
  NO_SCRIPTS: SummaryStatus$Server.scriptsNotFound,
  LIVE_STREAMING_VIDEO: SummaryStatus$Server.liveStreamingVideo,
  CONTENT_FILTERED_ERROR: SummaryStatus$Server.contentFilterError,
  VIDEO_NOT_FOUND_ERROR: SummaryStatus$Server.videoNotFound,
} as const;

export const YOUTUBE_SUMMARY_ERROR_TEXTS = {
  [YOUTUBE_SUMMARY_ERROR_CODES.INVALID_URL]:
    "지원하지 않는 링크입니다.\n URL을 다시 확인해주세요.",

  [YOUTUBE_SUMMARY_ERROR_CODES.LENGTH_EXCEEDED]:
    "지원하지 않는 영상입니다.\n 다른 영상 링크를 입력해주세요.",

  [YOUTUBE_SUMMARY_CLIENT_ERRORS.NOT_URL]: "입력한 값이 URL인지 확인해주세요",

  [YOUTUBE_SUMMARY_ERROR_CODES.NO_SCRIPTS]:
    "요약할 수 없는 영상입니다.\n 다른 영상으로 시도해주세요!",

  [YOUTUBE_SUMMARY_ERROR_CODES.LIVE_STREAMING_VIDEO]:
    "live 영상은 요약할 수 없어요.\n 다른 영상으로 시도해주세요!",

  [YOUTUBE_SUMMARY_ERROR_CODES.CONTENT_FILTERED_ERROR]:
    MESSAGE_ERROR_CONTENTS.content_filter,

  [YOUTUBE_SUMMARY_ERROR_CODES.VIDEO_NOT_FOUND_ERROR]:
    "찾을 수 없는 영상입니다.\n 다른 영상으로 시도해주세요!",

  [STATUS_CODES.SERVER_ERROR]:
    "일시적으로 요약 노트를 생성할 수 없습니다.\n 잠시 후 다시 시도해주세요.",
} as const;

/** @todo 에러코드별 handler 함수들 */
export const errorHandlers = {
  [YOUTUBE_SUMMARY_ERROR_CODES.INVALID_URL]: (message?: string) => {
    setDefaultErrorToast(
      message ??
        YOUTUBE_SUMMARY_ERROR_TEXTS[YOUTUBE_SUMMARY_ERROR_CODES.INVALID_URL],
    );
  },

  [YOUTUBE_SUMMARY_ERROR_CODES.LENGTH_EXCEEDED]: (message?: string) => {
    setDefaultErrorToast(
      message ??
        YOUTUBE_SUMMARY_ERROR_TEXTS[
          YOUTUBE_SUMMARY_ERROR_CODES.LENGTH_EXCEEDED
        ],
    );
  },

  [YOUTUBE_SUMMARY_ERROR_CODES.NO_SCRIPTS]: (message?: string) => {
    setDefaultErrorToast(
      message ??
        YOUTUBE_SUMMARY_ERROR_TEXTS[YOUTUBE_SUMMARY_ERROR_CODES.NO_SCRIPTS],
    );
  },

  [YOUTUBE_SUMMARY_CLIENT_ERRORS.NOT_URL]: (message?: string) => {
    setDefaultErrorToast(
      message ??
        YOUTUBE_SUMMARY_ERROR_TEXTS[YOUTUBE_SUMMARY_CLIENT_ERRORS.NOT_URL],
    );
  },

  [YOUTUBE_SUMMARY_ERROR_CODES.LIVE_STREAMING_VIDEO]: (message?: string) => {
    setDefaultErrorToast(
      message ??
        YOUTUBE_SUMMARY_ERROR_TEXTS[
          YOUTUBE_SUMMARY_ERROR_CODES.LIVE_STREAMING_VIDEO
        ],
    );
  },

  [YOUTUBE_SUMMARY_ERROR_CODES.CONTENT_FILTERED_ERROR]: () => {
    setDefaultErrorToast(
      YOUTUBE_SUMMARY_ERROR_TEXTS[
        YOUTUBE_SUMMARY_ERROR_CODES.CONTENT_FILTERED_ERROR
      ],
    );
  },

  [YOUTUBE_SUMMARY_ERROR_CODES.VIDEO_NOT_FOUND_ERROR]: () => {
    setDefaultErrorToast(
      YOUTUBE_SUMMARY_ERROR_TEXTS[
        YOUTUBE_SUMMARY_ERROR_CODES.VIDEO_NOT_FOUND_ERROR
      ],
    );
  },

  [STATUS_CODES.SERVER_ERROR]: (message?: string) => {
    // TODO: 팝업 UI
    setDefaultErrorToast(
      message ?? YOUTUBE_SUMMARY_ERROR_TEXTS[STATUS_CODES.SERVER_ERROR],
    );
  },
} as const;
