import { default as ky } from "ky";

const XLLM_API_URL = import.meta.env.VITE_XLLM_API_URL;

export const xllmClient = ky.create({
  prefixUrl: XLLM_API_URL,
  retry: 0,
  timeout: 3_000,
  keepalive: false,
  mode: "cors",
  headers: {
    Authorization: `Basic ${import.meta.env.VITE_XLLM_API_TOKEN}`,
  },
});
