import type { Timestamp } from "./youtube-summary-shared.schema";

const { floor } = Math;

export const seconds2Timestamp = (sec: number): Timestamp => {
  const hour = floor(sec / 3600);
  const min = floor(sec / 60);
  const secStr = String(floor(sec % 60)).padStart(2, "0");

  return `${hour}:${min}:${secStr}`;
};

export const timestamp2Seconds = (timestamp: Timestamp) => {
  const [hours, minutes, seconds] = timestamp.split(":").map(Number);
  return seconds + minutes * 60 + hours * 3600;
};
