import { useEffect, useMemo, useState } from "react";
import LoadingSuccessImg from "~assets/images/ico_check_success.png";
import LoadingImg from "~assets/images/ico_step_loading.png";
import { MarkdownRenderer } from "~features/markdown-renderer";
import markdownParagraphStyles from "~features/markdown-renderer/paragraph.module.scss";
import AccordionButton from "~features/ui/accordion-button/accordion-button";
import { Status } from "~features/util-types/status";
import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";
import type { SearchResult } from "../schemas";
import ReasoningIco from "./img/ico_reasoning.png";
import styles from "./tool-steps.module.scss";

type SearchResultToolStepsProps = {
  toolSteps: SearchResult.ToolSteps[];
  reasoning?: SearchResult.ToolSteps[];
  searchStatus: Status;
  title?: string;
};

export function SearchResultToolSteps({
  title = "답변 과정",
  toolSteps,
  reasoning,
  searchStatus,
}: SearchResultToolStepsProps) {
  const { isAnswerLoading, isAnswerCompleted } = useMemo(
    () => ({
      isAnswerLoading:
        searchStatus === Status.LOADING || searchStatus === Status.INIT,
      isAnswerCompleted: searchStatus === Status.SUCCESS,
    }),
    [searchStatus],
  );
  /** @todo re-render issue -> zustand로 변경 */
  const [isDetailOpen, setIsDetailOpen] = useState(isAnswerLoading);

  const summaryBadgeText = useMemo(() => {
    if (isAnswerLoading) return "답변 중";
    if (isAnswerCompleted) return "답변 완료";
    return "";
  }, [isAnswerLoading, isAnswerCompleted]);

  const toggleDetailOpen = createEventHandler({
    handler: () => {
      setIsDetailOpen((prev) => !prev);
    },
  });

  useEffect(() => {
    isAnswerLoading && setIsDetailOpen(true);
    isAnswerCompleted && setIsDetailOpen(false);
  }, [isAnswerLoading, isAnswerCompleted]);

  return (
    <details open={isDetailOpen} className={styles.accordion_wrapper}>
      <summary
        onClick={toggleDetailOpen}
        onKeyDown={toggleDetailOpen}
        className={styles.accordion_top}
      >
        <p className={styles.title}>{title}</p>

        <div className={styles.right_wrap}>
          {isDetailOpen && summaryBadgeText.length > 0 && (
            <SummaryBadge text={summaryBadgeText} />
          )}
          <AccordionButton isOpen={isDetailOpen} />
        </div>
      </summary>

      <div className={styles.accordion_contents}>
        <div className={styles.reasoning_process}>
          <ul className={styles.step_list}>
            {toolSteps?.map((step) => (
              <StepItem
                key={`search-process-${step.speak}`}
                step={step}
                format="text"
              />
            ))}
          </ul>
        </div>

        {reasoning && (
          <div className={styles.reasoning_process}>
            <div className={styles.reasoning_title}>
              <img src={ReasoningIco} alt="" aria-hidden />
              <span>R1 추론</span>
            </div>
            <ul className={styles.step_list}>
              {reasoning.map((step) => (
                <StepItem
                  key={`search-process-reasoning-${step.speak}`}
                  step={step}
                  format="markdown"
                />
              ))}
            </ul>
          </div>
        )}
      </div>
    </details>
  );
}

type StepItemProps = {
  step: SearchResult.ToolSteps;
  format: "text" | "markdown";
};

const StepItem: React.FC<StepItemProps> = ({ step, format }) => {
  return (
    <li
      className={cn(
        {
          [styles.loading]: step.status === Status.LOADING,
        },
        styles.step,
      )}
    >
      <span className={styles.icon}>
        {step.status === Status.SUCCESS ? <SuccessIcon /> : <LoadingIcon />}
      </span>

      {format === "markdown" ? (
        <MarkdownRenderer
          contents={step.speak}
          className={cn(markdownParagraphStyles.reasoning_step)}
        />
      ) : (
        <p className={styles.step_text}>{step.speak}</p>
      )}
    </li>
  );
};

type SummaryBadgeProps = {
  text: string;
};

const SummaryBadge = ({ text }: SummaryBadgeProps) => (
  <span className={styles.status_badge}>{text}</span>
);

const LoadingIcon = () => (
  <img
    className={styles.ico_loading}
    src={LoadingImg}
    alt="loading"
    aria-hidden
  />
);

const SuccessIcon = () => (
  <img src={LoadingSuccessImg} alt="loading" aria-hidden />
);
