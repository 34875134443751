import { useMutation, useQueryClient } from "@tanstack/react-query";
import { KyClient } from "~clients/fetch";
import { useChannel } from "~features/channel";
import type { Feedbacks } from "./message-feedback.schema";
import { type SendVote, transformRequest } from "./send-vote.schema";
import { getQueryKeyFeedbacks } from "./use-query-feedback";

export const useMutationSendVote = () => {
  const queryClient = useQueryClient();
  const { channelId } = useChannel();

  return useMutation({
    mutationFn: sendVote,

    onMutate: async ({ type, messageId }) => {
      const queryKey = getQueryKeyFeedbacks({
        channelId,
        messageIds: [messageId],
      });

      queryClient.setQueryData<Feedbacks>(queryKey, (prev) => {
        const nextFeedbacks = new Map(prev ?? []);
        nextFeedbacks.set(messageId, { type, downReasons: [], message: "" });
        return nextFeedbacks;
      });

      return {
        queryKey,
      };
    },

    /** @todo 업데이트가 바로 반영되지 않는 경우가 있어서, optimistic update 그대로 사용 */
    onSuccess: () => {
      // queryClient.invalidateQueries({ queryKey });
    },

    onError: (_err, { messageId }, context) => {
      if (!context?.queryKey) return;

      queryClient.setQueryData<Feedbacks>(context.queryKey, (prev) => {
        const nextFeedbacks = new Map(prev ?? []);
        nextFeedbacks.delete(messageId);
        return nextFeedbacks;
      });
    },
  });
};

/**
 * @example
 * - api POST {@link https://func08-api-estsoft-algpt-stage.azurewebsites.net/api/v2/thumbs}
 * - payload
 * ```json
 {
    "message_id": "66a367441e830f0a182e2811",
    "type": "UP"
  }
 ```

 ```json
 {
    "message_id": "66a367943b52882d505a6e0f",
    "type": "DOWN"
  }
 ```

 * - response
 ```json
 {
  "result":true,
  "inserted_id":"66a3679f6d189d68f93ff8bb"
 }
 ```
 */
const API_PATH = "api/v2/thumbs";

const sendVote = async (args: SendVote) => {
  KyClient.post(API_PATH, {
    prefixUrl: import.meta.env.VITE_API_BASE_URL,
    json: transformRequest(args),
  }).json();
};
