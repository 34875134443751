import { useState } from "react";
import { match } from "ts-pattern";
import { useChannel, useQueryChannel } from "~features/channel";
import { useIsPendingContext } from "~features/search/use-is-pending.context";
import { BottomSheet } from "~features/ui/bottom-sheet";
import { useWindowWidth } from "~hooks/use-window-width";
import { CopyChannelButton } from "./copy-channel-button";
import { ShareButton } from "./share-button";
import { ShareContext, useShareContext } from "./share-context";
import { ShareModal } from "./share-modal";
import {
  SharePermissionTitle,
  SharePermissionToggle,
} from "./share-permissions";
import styles from "./share.module.scss";
import { SnsShareButtons } from "./sns-share-buttons";

export function Share() {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const { channelId } = useChannel();
  const { data } = useQueryChannel(channelId);

  const { isPending } = useIsPendingContext();

  return (
    <div className={styles.share_wrapper}>
      <ShareContext.Provider
        value={{
          isShareModalOpen,
          setIsShareModalOpen,
        }}
      >
        {data?.isShared && <CopyChannelButton />}
        {!isPending && <ShareButton />}

        {isShareModalOpen && <ShareContents />}
      </ShareContext.Provider>
    </div>
  );
}

const SNS_SHARE_TITLE = "공유하기";

function ShareContents() {
  const { isMobileSmallSize } = useWindowWidth();
  const { channelId } = useChannel();
  const { data } = useQueryChannel(channelId);
  const { setIsShareModalOpen } = useShareContext();

  return match({
    isMobileSmallSize,
    isShared: data?.isShared ?? false,
    isSharing: data?.isSharing ?? false,
  })
    .with({ isMobileSmallSize: true, isShared: true }, () => (
      <BottomSheet.Root
        closeBottomSheet={() => setIsShareModalOpen(false)}
        isHideCloseButton
      >
        <div className={styles.contents}>
          <SharePermissionTitle title={SNS_SHARE_TITLE} />
          <div className={styles.share_wrap}>
            <SnsShareButtons />
          </div>
        </div>
      </BottomSheet.Root>
    ))
    .with({ isMobileSmallSize: true, isShared: false, isSharing: true }, () => (
      <BottomSheet.Root
        closeBottomSheet={() => setIsShareModalOpen(false)}
        isHideCloseButton
      >
        <div className={styles.contents}>
          <SharePermissionTitle />
          <SharePermissionToggle />
          <div className={styles.share_wrap}>
            <SnsShareButtons />
          </div>
        </div>
      </BottomSheet.Root>
    ))
    .with(
      { isMobileSmallSize: true, isShared: false, isSharing: false },
      () => (
        <BottomSheet.Root
          closeBottomSheet={() => setIsShareModalOpen(false)}
          isHideCloseButton
        >
          <div className={styles.contents}>
            <SharePermissionTitle />
            <SharePermissionToggle />
          </div>
        </BottomSheet.Root>
      ),
    )
    .with({ isMobileSmallSize: false, isShared: true }, () => (
      <ShareModal>
        <div className={styles.contents}>
          <SharePermissionTitle title={SNS_SHARE_TITLE} />
          <div className={styles.share_wrap}>
            <SnsShareButtons />
          </div>
        </div>
      </ShareModal>
    ))
    .with(
      { isMobileSmallSize: false, isShared: false, isSharing: true },
      () => (
        <ShareModal>
          <div className={styles.contents}>
            <SharePermissionTitle />
            <SharePermissionToggle />
            <div className={styles.share_wrap}>
              <SnsShareButtons />
            </div>
          </div>
        </ShareModal>
      ),
    )
    .with(
      { isMobileSmallSize: false, isShared: false, isSharing: false },
      () => (
        <ShareModal>
          <div className={styles.contents}>
            <SharePermissionTitle />
            <SharePermissionToggle />
          </div>
        </ShareModal>
      ),
    )
    .exhaustive();
}
