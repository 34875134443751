import type { PropsWithChildren } from "react";
import { cn } from "~utils/class-names";
import styles from "./bottom-sheet.module.scss";

type ContentTextProps = PropsWithChildren<{
  contentText: string;
}>;

export function ContentText({ contentText }: ContentTextProps) {
  return (
    <div
      className={cn(
        "font-normal text-alan-black-4 line-clamp-3 w-full h-max",
        styles.text,
      )}
    >
      {contentText}
    </div>
  );
}
