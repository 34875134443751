import { useCallback } from "react";
import { useUser } from "~features/auth";
import { usePersonas } from "~features/personas";
import { useUserAgent } from "~features/user-agent";
import { xllmHooks } from "~features/xllm";
import { cn } from "~utils/class-names";
import {
  useCreateChannelAndNavigate,
  useMutationSendPromptWithNewChannel,
  useRequestValidation,
  validateRequestWithNewChannel,
} from "../mutations";
import { categorizePrompt } from "../utils";
import { useRelatedSearchQueries } from "./related-search-queries.context";

import styles from "./related-search-queries.module.scss";

export function RelatedSearchQueries() {
  const { relatedQueries } = useRelatedSearchQueries();
  const { isMobile } = useUserAgent();

  const { user } = useUser();
  const { matchValidationResult } = useRequestValidation();
  const { mutate } = useMutationSendPromptWithNewChannel(user);
  const { persona } = usePersonas();
  const { createChannelAndNavigate, getCreateChannelArgs } =
    useCreateChannelAndNavigate();

  const { enabled: xllmEnabled } = xllmHooks.useSanitizerContext();

  const sendCompletion = useCallback(
    (completion: string) => {
      const validationResult = validateRequestWithNewChannel({
        prompt: completion,
        user,
      });

      const categorized = categorizePrompt(completion);
      const createChannelArgs = getCreateChannelArgs({
        personaId: persona.id,
        hasUrl: categorized.hasUrl,
        hasYoutubeUrl: categorized.hasYoutubeUrl,
        xllmEnabled,
      });

      return matchValidationResult(validationResult, {
        withValid: () => {
          createChannelAndNavigate(createChannelArgs).then(
            ({ channelId, personaId, isForYoutubeSummary }) => {
              mutate({
                prompt: completion,
                channelId,
                personaId,
                isForYoutubeSummary,
              });
            },
          );
        },
      });
    },
    [
      user,
      persona.id,
      xllmEnabled,
      getCreateChannelArgs,
      matchValidationResult,
      mutate,
      createChannelAndNavigate,
    ],
  );

  return (
    <div
      className={cn(styles.completions_wrap, {
        [styles.invisible_scrollbar]: isMobile,
      })}
    >
      <div className={styles.title}>연관검색어</div>
      <ul className={styles.completions}>
        {relatedQueries.map(({ original }) => (
          // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
          <li
            className={styles.completion_item}
            key={`completion-${original}`}
            onClick={() => sendCompletion(original)}
          >
            {original}
          </li>
        ))}
      </ul>
    </div>
  );
}
