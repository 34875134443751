import { Link } from "@tanstack/react-router";
import HistoryIcon from "~assets/images/ico_history.png";
import NewSearchIcon from "~assets/images/ico_new_search.png";
import YoutubeIcon from "~assets/images/ico_youtube_thumbnail.png";
import { useGuestModeStore } from "~features/guest-mode";
import { usePersonas } from "~features/personas";
import { useSidebar } from "~features/ui/side-bar";
import { GROUP_TITLES } from "./group-history-items";
import styles from "./history-sidebar.module.scss";
import { useHistory } from "./use-history";

export function HistorySidebar() {
  const { itemGroups } = useHistory();
  const { youtubePersonaId } = usePersonas();
  const { enabled: isGuestMode, setShowMainModal } = useGuestModeStore();
  const { setIsOpen } = useSidebar();

  return (
    <>
      <div className={styles.btn_wrap}>
        <Link
          to="/"
          preload={isGuestMode ? false : "intent"}
          className={styles.btn}
          onClick={() => setIsOpen(false)}
        >
          <img src={NewSearchIcon} alt="" />
          <span>새 검색</span>
        </Link>
        <Link
          to="/history"
          className={styles.btn}
          preload={isGuestMode ? false : "intent"}
          onClick={(e) => {
            e.stopPropagation();

            if (isGuestMode) {
              e.preventDefault();
              setShowMainModal(true);
              return;
            }

            setIsOpen(false);
          }}
          aria-label="history button"
        >
          <img src={HistoryIcon} alt="history icon" />
          <span>내 검색 기록</span>
        </Link>
      </div>

      <section className={styles.sidebar_history_wrapper}>
        {!isGuestMode &&
          itemGroups
            .filter(
              ({ title, items: groupItems }) =>
                title !== GROUP_TITLES.PREVIOUS && groupItems.length > 0,
            )
            .map(({ title, items: groupItems }) => (
              <div
                key={`sidebar-history-${title}`}
                className={styles.history_list}
              >
                <p className={styles.date}>{title}</p>

                {groupItems.map((item) => (
                  <Link
                    key={item.id}
                    to={`/${item.personaId === youtubePersonaId ? "summary/youtube" : "search"}/$channelId`}
                    params={{ channelId: item.channelId }}
                    className={styles.history}
                    activeProps={{ className: styles.select }}
                    activeOptions={{
                      exact: false,
                      includeSearch: false,
                      includeHash: false,
                    }}
                    preloadDelay={150}
                    resetScroll
                    onClick={() => setIsOpen(false)}
                  >
                    {item.personaId === youtubePersonaId && (
                      <img
                        className={styles.ico_youtube}
                        src={YoutubeIcon}
                        alt=""
                      />
                    )}
                    <span className={styles.title}>{item.title}</span>
                  </Link>
                ))}
              </div>
            ))}
      </section>
    </>
  );
}
