import { type UseQueryOptions, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { TIMES } from "~constants/times";
import { useQueryChannel } from "~features/channel";
import { messagesQueryOptionsFactory } from "~features/messages";
import { Status } from "~features/util-types/status";
import type { SearchResult } from "./schemas";
import { parseSearchResultsFromApi } from "./schemas/search-results.schemas";

export const useQuerySearch = (channelId: string) => {
  const { isSuccess } = useQueryChannel(channelId);

  const queryOption = useMemo(
    () =>
      Object.assign({}, getSearchQueryOptions(channelId), {
        enabled: isSuccess,
        refetchInterval(query) {
          const { data } = query.state;

          const lastItem = data?.items.slice(-1)[0];
          if (
            !lastItem ||
            lastItem.status !== Status.SUCCESS ||
            !lastItem.questions
          )
            return false;

          return lastItem.questions.length > 0 ? false : 1 * TIMES.SEC;
        },
      } as UseQueryOptions<SearchResult.Page>),
    [channelId, isSuccess],
  );

  return useQuery(queryOption);
};

export const getSearchQueryOptions = messagesQueryOptionsFactory({
  parseResponse: parseSearchResultsFromApi,
});
