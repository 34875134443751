import Markdown from "markdown-to-jsx";
import type { PropsWithChildren } from "react";
import { cn } from "~utils/class-names";
import { MarkdownCode, MarkdownCodeBlock } from "./code";
import {
  MarkdownHeading1,
  MarkdownHeading2,
  MarkdownHeading3,
  MarkdownHeading4,
  MarkdownHeading5,
  MarkdownHeading6,
} from "./headings";
import { MarkdownImage } from "./image";
import { MarkdownLink } from "./link";
import { MarkdownListItem, MarkdownOL, MarkdownUL } from "./lists";
import { MarkdownParagraph } from "./paragraph";
import { MarkdownTable } from "./table";

import styles from "./markdown-renderer.module.scss";

export type MarkdownRendererProps = {
  contents: string;
  className?: string;
  Link?: typeof MarkdownLink;
};

const getRefinedContents = (contents: string) =>
  contents
    .trim()
    .replace(
      /(\s*)\`\`\`([\w]*\n)((?:(?!\`\`\`)[\s\S])+?)\`\`\`/g,
      "\n$1```$2$3```\n",
    )
    .trim();

export function MarkdownRenderer({
  contents,
  className,
}: MarkdownRendererProps) {
  return (
    <PureMarkdownRenderer
      contents={contents}
      Link={MarkdownLink}
      className={className}
    />
  );
}

export const PureMarkdownRenderer = ({
  contents,
  Link,
  className,
}: MarkdownRendererProps) => {
  const refinedContents = getRefinedContents(contents);

  return (
    <Markdown
      options={{
        slugify: (str) => str,
        wrapper: ({ children }: MarkdownWrapperProps) => (
          <MarkdownWrapper className={className}>{children}</MarkdownWrapper>
        ),
        forceWrapper: true,
        forceBlock: true,
        disableParsingRawHTML: true,
        overrides: {
          p: { component: MarkdownParagraph },
          h1: { component: MarkdownHeading1 },
          h2: { component: MarkdownHeading2 },
          h3: { component: MarkdownHeading3 },
          h4: { component: MarkdownHeading4 },
          h5: { component: MarkdownHeading5 },
          h6: { component: MarkdownHeading6 },
          ul: {
            component: MarkdownUL,
          },
          ol: {
            component: MarkdownOL,
          },
          li: {
            component: MarkdownListItem,
          },
          a: {
            component: Link ?? "a",
          },
          pre: {
            component: MarkdownCodeBlock,
          },
          code: {
            component: MarkdownCode,
          },
          kbd: { component: MarkdownCode },
          table: {
            component: MarkdownTable,
          },
          img: {
            component: MarkdownImage,
          },
        },
      }}
    >
      {refinedContents}
    </Markdown>
  );
};

type MarkdownWrapperProps = PropsWithChildren<{ className?: string }>;

function MarkdownWrapper({ children, className }: MarkdownWrapperProps) {
  return <div className={cn(styles.markdown_wrap, className)}>{children}</div>;
}
