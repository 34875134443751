import { useEffect } from "react";
import { match } from "ts-pattern";
import { create } from "zustand";
import {
  type NativeMessageAccessTokenResponse,
  NativeMessageAccessTokenResponseDataSchema,
  NativeMessageTypes,
  useNativeBridge,
} from "~clients/native-bridge";
import { v } from "~libs/valibot";

type AccessTokenState = NativeMessageAccessTokenResponse & {
  setAccessToken: (accessToken: NativeMessageAccessTokenResponse) => void;
};

export const useAccessTokenStore = create<AccessTokenState>((set) => ({
  accessToken: "",
  tokenType: "",
  expiresIn: 0,
  setAccessToken: (accessToken: NativeMessageAccessTokenResponse) =>
    set({ ...accessToken }),
}));

export const useAccessToken = () => {
  const { accessToken, setAccessToken } = useAccessTokenStore();
  const { receivedMessage, resetReceivedMessage } = useNativeBridge();

  useEffect(() => {
    const { type = NativeMessageTypes._empty, data } = receivedMessage;
    if (type === NativeMessageTypes._empty) return;

    match({ type })
      .with(
        { type: NativeMessageTypes.setAccessToken },
        { type: NativeMessageTypes.accessTokenResponse },
        { type: NativeMessageTypes.refreshAccessTokenResponse },
        () => {
          const parseResult = v.safeParse(
            NativeMessageAccessTokenResponseDataSchema,
            data,
          );

          if (parseResult.success) setAccessToken(parseResult.output);
        },
      )
      .with({ type: NativeMessageTypes.initialize }, () => {
        // TODO: schema parser
        const accessToken = (data as { token?: string })?.token ?? "";
        if (accessToken)
          setAccessToken({ accessToken, tokenType: "bearer", expiresIn: 1800 });
      });

    resetReceivedMessage();
  }, [receivedMessage, resetReceivedMessage, setAccessToken]);

  return {
    accessToken,
    setAccessToken,
  };
};
