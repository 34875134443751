import type { Platforms } from "~features/user-agent";
import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";
import styles from "./app-download-button.module.scss";
import { getAppDetails } from "./utils";

type AppDownloadButtonProps = {
  isHome?: boolean;
  type: Platforms;
  onClick: () => void;
};

export const AppDownloadButton: React.FC<AppDownloadButtonProps> = (props) => {
  const { icon, text } = getAppDetails(props.type);

  const onClick = createEventHandler({
    handler: () => {
      props.onClick();
    },
  });

  return (
    <button
      type="button"
      className={cn(styles.app_download_button, {
        [styles.is_home]: props.isHome,
      })}
      onClick={onClick}
    >
      <img src={icon} alt={`${props.type} icon`} />
      <span className={styles.text}>Alan for {text}</span>
    </button>
  );
};

export default AppDownloadButton;
