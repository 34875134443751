import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

type ModalVisibleStates = {
  isModalVisible: boolean;
  isModalVisibleInSession: boolean;
};

type ModalVisibleActions = {
  updateModalVisible: (
    visibility: boolean,
    options: { useStorage: boolean },
  ) => void;
};

// TODO: 추후 config api 연동
export const useModalVisible = create<
  ModalVisibleStates & ModalVisibleActions
>()(
  persist(
    (set) => ({
      isModalVisible: true,
      isModalVisibleInSession: true,
      updateModalVisible: (isModalVisible, { useStorage }) => {
        set(
          useStorage
            ? { isModalVisible }
            : { isModalVisibleInSession: isModalVisible },
        );
      },
    }),
    {
      name: "alan.modals",
      version: 2025031201,
      storage: createJSONStorage(() => window.localStorage),
      partialize: (state) => ({ isModalVisible: state.isModalVisible }),
    },
  ),
);

(function removePrevStorage() {
  window.localStorage.removeItem("alan.modal.update.visibility");
  window.localStorage.removeItem("alan.model.guide.visibility.202502101035");
})();
