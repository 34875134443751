import { match } from "ts-pattern";
import {
  LoginImage1,
  LoginImage2,
  LoginImage3,
  LoginImage4,
  LoginImage5,
  LoginImage6,
  LoginImage7,
  LoginImage8,
  LoginImage9,
} from "~features/modals/images";
import { shuffleArray } from "~features/utils/array";
import type { ModalType } from "../store";

export const MAIN_MODAL_CONTENTS = [
  {
    image: LoginImage1,
    title: "Alan의 모든 기능을 활용하세요!",
    details:
      "지금 로그인하면 더 많은 검색, xLLM 보호 모드,\n 검색 기록 저장 등을 사용할 수 있어요.",
  },
];

export const SIDEBAR_POPUP_CONTENTS = [
  {
    image: LoginImage2,
    title: "더 많은 질문이 있으신가요?",
    details: "로그인하고 더 많은 검색으로\n 더 많은 인사이트를 얻어보세요.",
  },
  {
    image: LoginImage3,
    title: "휴대폰에서 시작한 검색을\n 컴퓨터에서 이어서 하세요.",
    details: "회원가입으로 크로스 디바이스 동기화가 가능합니다.",
  },
  {
    image: LoginImage4,
    title: "어디서나 Alan과 대화를 이어가세요.",
    details: "로그인하면 모든 기기에서\n검색 기록이 동기화됩니다.",
  },
  {
    image: LoginImage5,
    title: "더 안전한 사용을 원하시나요?",
    details: "로그인하고 xLLM 보호 모드를 \n활성화하세요.",
  },
  {
    image: LoginImage6,
    title: "검색 기록을 저장하려면\n로그인이 필요해요.",
    details: "언제든지 이전 검색 결과를 찾아보세요.",
  },
  {
    image: LoginImage7,
    title: "중요한 검색 결과를 저장하세요.",
    details: "회원가입하고 모든 검색 기록을\n안전하게 보관하세요.",
  },
  {
    image: LoginImage8,
    title: "검색 결과를 나중에도 보고 싶으신가요?",
    details: "로그인하면 모든 검색 기록이 \n자동으로 저장됩니다.",
  },
  {
    image: LoginImage9,
    title: "검색 결과를 공유하려면\n로그인이 필요해요.",
    details: "로그인하면 검색 결과를 공유할 수 있어요.",
  },
];

export const getModalContents = (modalType: ModalType) =>
  match(modalType)
    .returnType<{ title: string; details: string; image: string }>()
    .with("main", () => MAIN_MODAL_CONTENTS[0])
    .otherwise(() => shuffleArray(SIDEBAR_POPUP_CONTENTS, 1)[0]);
