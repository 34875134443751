import { useMutation, useQueryClient } from "@tanstack/react-query";
import { KyClient } from "~clients/fetch";
import { EMPTY_USER } from "~features/auth";
import { QUERY_KEYS } from "~features/providers/tanstack-query";

export const useMutationSignOut = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: fetchSignOut,

    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: QUERY_KEYS.AUTH.USER,
      });
      queryClient.setQueryData(QUERY_KEYS.AUTH.USER, () => EMPTY_USER);
    },
  });
};

const fetchSignOut = async () =>
  await KyClient.post("auth/sign-out", {
    // TODO: 상수 분리
    prefixUrl: import.meta.env.VITE_API_BASE_URL,
  }).json();
