import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { P, match } from "ts-pattern";
import { STATUS_CODES, isKyHttpError, parseKyHttpError } from "~clients/fetch";
import { useQueryChannel } from "~features/channel";
import { useGuestModeStore } from "~features/guest-mode";
import { QUERY_KEYS } from "~features/providers/tanstack-query";
import type { SearchResult } from "~features/search/schemas";
import { setDefaultErrorToast } from "~features/toast";
import { Status } from "~features/util-types/status";
import { cloneObject } from "~utils/clone-object";
import { logger } from "~utils/logger";
import { sendMessage } from "./send-message";

type Args = {
  userId: string;
  channelId: string;
  personaId: string;
  xllmEnabled: boolean;
};

export const useMutationSendPromptInChannel = ({
  channelId,
  userId,
  personaId,
  xllmEnabled,
}: Args) => {
  const queryClient = useQueryClient();
  const { enabled: isGuestMode, setShowMainModal } = useGuestModeStore();
  const navigate = useNavigate();
  const { data } = useQueryChannel(channelId);

  return useMutation({
    mutationFn: (prompt: string) =>
      sendMessage({
        message: prompt,
        userId,
        channelId,
        personaId,
      }),

    onMutate: async (prompt) => {
      const queryKey =
        QUERY_KEYS.CHANNELS.getChannelMessagesQueryKey(channelId);

      await queryClient.cancelQueries({ queryKey });

      if (data?.userId && data.userId !== userId) return;

      queryClient.setQueryData<SearchResult.Page>(queryKey, (prevMessages) => {
        const newMessages = cloneObject(
          prevMessages ?? {
            items: [],
            total: 0,
          },
        );

        if (newMessages.items.slice(-1)[0]) {
          newMessages.items.slice(-1)[0].questions = undefined;
        }

        const newMessage: SearchResult.Item = createNewMessage(prompt, {
          xllmEnabled,
        });
        newMessages.items.push(newMessage);
        newMessages.total += 1;

        return newMessages;
      });

      return {
        queryKey,
      };
    },

    onSuccess: async (response) => {
      if (!response.isChannelCopied) return;

      navigate({
        to: "/channels/$channelId",
        params: {
          channelId: response.copiedChannelId,
        },
      });
    },

    onError: async (error, _prompt, context) => {
      if (!context) return;

      if (!isKyHttpError(error)) {
        setDefaultErrorToast("오류가 발생했어요. 잠시 후 다시 시도해주세요.");
        return;
      }

      const {
        errorType,
        data: { status, response },
      } = await parseKyHttpError<unknown>(error);

      match({ status, response, isGuestMode })
        .with(
          {
            status: { code: STATUS_CODES.RATE_LIMIT },
            response: P.string,
            isGuestMode: false,
          },
          ({ response }) => {
            setDefaultErrorToast(response);
          },
        )
        .with(
          {
            status: { code: STATUS_CODES.RATE_LIMIT },
            response: P.string,
            isGuestMode: true,
          },
          () => {
            setShowMainModal(true);
          },
        )
        .otherwise(() => {
          logger.error({
            scope: "useMutationSendPromptInChannel",
            data: {
              errorType,
              status,
              response,
              isGuestMode,
            },
          });

          setDefaultErrorToast("오류가 발생했어요. 잠시 후 다시 시도해주세요.");
        });
    },

    onSettled: (_response, _error, _prompt, context) => {
      queryClient.refetchQueries({ queryKey: QUERY_KEYS.AUTH.USER });
      queryClient.refetchQueries({ queryKey: QUERY_KEYS.HISTORY.ROOT });

      if (!context) return;
      queryClient.refetchQueries({ queryKey: context.queryKey });
    },
  });
};

const INIT_MESSAGE: Omit<SearchResult.Item, "title" | "toolSteps"> = {
  id: "",
  messageId: { user: "", assistant: "" },
  requestId: "",
  status: Status.INIT,
  content: "",
};

const createNewMessage = (
  prompt: string,
  { xllmEnabled }: { xllmEnabled: boolean },
): SearchResult.Item =>
  Object.assign({}, INIT_MESSAGE, {
    title: prompt,
    toolSteps: xllmEnabled
      ? [
          {
            order: 0,
            speak:
              "xLLM으로 검색하신 내용에 중요 정보가 있는지 탐지하고 있어요.",
            status: Status.LOADING,
          },
        ]
      : [
          {
            order: 0,
            speak: "질문의 의도를 이해하고 있어요.",
            status: Status.LOADING,
          },
        ],
  });
