import type { FC, MouseEventHandler } from "react";
import { Button } from "~features/ui/button";
import styles from "./delete-button.module.scss";

type DeleteButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export const DeleteButton: FC<DeleteButtonProps> = ({ ...props }) => {
  return (
    <Button
      {...props}
      overrideClass={styles.btn_delete}
      ButtonType="onlyIcon"
      variant="radius"
      aria-label="delete button"
      iconBefore={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <circle cx="9" cy="9" r="9" fill="#AEB9C7" />
          <path
            d="M12.274 5.72656L5.72852 12.272"
            stroke="white"
            strokeWidth="1.05195"
          />
          <path
            d="M12.2734 12.272L5.72798 5.72656"
            stroke="white"
            strokeWidth="1.05195"
          />
        </svg>
      }
    />
  );
};
