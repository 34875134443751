import { type InferOutput, v } from "~libs/valibot";
import { VotedTypes } from "./message-feedback.schema";

export type SendVote = InferOutput<typeof SendVoteSchema>;

const SendVoteSchema = v.object({
  messageId: v.string(),
  type: v.enum(VotedTypes),
});

const SendVoteRequestSchema = v.pipe(
  SendVoteSchema,
  v.transform(({ type, messageId }) => ({
    message_id: messageId,
    type,
  })),
);

export const transformRequest = (request: SendVote) =>
  v.parse(SendVoteRequestSchema, request);
