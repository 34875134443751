import { create } from "zustand";

export type ModalType = "main" | "sidebar";

type GuestModeStates = {
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;

  showMainModal: boolean;
  setShowMainModal: (showMainModal: boolean) => void;

  showSidebarModal: boolean;
  setShowSidebarModal: (showSidebarModal: boolean) => void;
};

export const useGuestModeStore = create<GuestModeStates>((set) => ({
  enabled: false,
  setEnabled: (enabled) => set({ enabled }),

  showMainModal: false,
  setShowMainModal: (showMainModal) => set({ showMainModal }),

  showSidebarModal: true,
  setShowSidebarModal: (showSidebarModal) => set({ showSidebarModal }),
}));
