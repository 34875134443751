import BtnImg from "~assets/images/btn_menu.png";
import CloseImg from "~assets/images/ico_close.png";
import { Button } from "~features/ui/button";
import { useSidebar } from "~features/ui/side-bar";
import { useWindowWidth } from "~hooks/use-window-width";
import styles from "./menu-button.module.scss";

type MenuButtonProps = {
  onClick?: React.MouseEventHandler;
  onKeyDown?: React.KeyboardEventHandler;
};

const MenuButton: React.FC<MenuButtonProps> = (props) => {
  const { isMobileSmallSize } = useWindowWidth();
  const { isOpen } = useSidebar();
  const isSideBarVisibleOnMobile = isOpen && isMobileSmallSize;

  return (
    <Button
      overrideClass={styles.btn_menu}
      onClick={props.onClick}
      onKeyDown={props.onKeyDown}
      {...(isSideBarVisibleOnMobile ? {} : { size: "large" })}
      ButtonType="onlyIcon"
      aria-label="side menu"
      iconBefore={
        <img src={isSideBarVisibleOnMobile ? CloseImg : BtnImg} alt="" />
      }
      {...props}
    />
  );
};

export default MenuButton;
