import { RESPONSE_MESSAGE_TYPES } from "~clients/socket";
import { MESSAGE_USER_ROLE } from "~features/messages/constants";
import { v } from "~libs/valibot";

export enum SuggestionsLoadingStatus {
  LOADING = 0,
  SUCCESS = 1,
  FAILED = 2,
}

export const MessageCore$Server = v.object({
  /** message id */
  _id: v.string(),
  channel_id: v.string(),
  /** user 질문(request) id - 질문-답변 동일한 id */
  request_id: v.string(),
  user_id: v.string(),
  persona_id: v.string(),
  userRole: v.enum(MESSAGE_USER_ROLE),
  content: v.string(),
  suggest_questions: v.array(v.string()),
  references: v.optional(
    v.array(
      v.object({
        number: v.union([v.number(), v.string()]),
        source: v.string(),
        title: v.string(),
        date: v.optional(v.nullable(v.string())),
        content: v.string(),
        thumbnail: v.optional(v.nullable(v.string())),
      }),
    ),
  ),

  // 아래는 당장 쓰이지 않는 속성들
  // created_at: string(),
  // updated_at: string(),
  // gpt_info: object({})
});

export const MessageResponse$Server = v.object({
  ...MessageCore$Server.entries,
  /** user request는 null, response는 answer_complete */
  type: v.nullable(v.enum(RESPONSE_MESSAGE_TYPES)),
  actions: v.array(
    v.object({
      name: v.string(),
      arguments: v.object({}),
      speak: v.string(),
      created_at: v.string(),
    }),
  ),
  stop_reason: v.nullable(v.string()), // "stop", "length", "canceled"
});
