import { v, type InferOutput } from "~libs/valibot";

/** Domain */
const RefreshAccessTokenSchema = v.object({
  accessToken: v.string(),
  tokenType: v.string(),
  expiresIn: v.number(),
});

export type RefreshAccessToken = InferOutput<typeof RefreshAccessTokenSchema>;

/** React → Native */
export const RefreshAccessTokenRequestSchema = v.pipe(
  RefreshAccessTokenSchema,

  v.transform((data) => ({
    access_token: data.accessToken,
    token_type: data.tokenType,
    expires_in: data.expiresIn,
  })),
);

type RefreshAccessTokenRequest = InferOutput<
  typeof RefreshAccessTokenRequestSchema
>;

/** Native → React */
export const RefreshAccessTokenResponseDataSchema = v.pipe(
  v.object({
    access_token: v.string(),
    token_type: v.optional(v.string(), "bearer"),
    expires_in: v.optional(v.number(), 1800),
  }),

  v.transform(
    (data): RefreshAccessToken => ({
      accessToken: data.access_token,
      tokenType: data.token_type,
      expiresIn: data.expires_in,
    }),
  ),
);
