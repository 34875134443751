import {
  FloatingFocusManager,
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { useState } from "react";
import { AppDownloadButtons } from "~features/ui/app-download-button";
import MenuBox from "~features/ui/popup-menu/menu-box/menu-box";
import { createEventHandler } from "~utils/create-event-handler";
import styles from "./app-download.module.scss";
import DownloadIcon from "./images/ico_download.png";

export function AppDownload() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const onClickMenuButton = createEventHandler({
    handler: () => {
      setIsMenuOpen((prev) => !prev);
    },
  });
  const { refs, context, floatingStyles } = useFloating({
    open: isMenuOpen,
    onOpenChange: setIsMenuOpen,
    placement: "top-start",
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({ mainAxis: 16, crossAxis: 0 }),
      flip({ fallbackAxisSideDirection: "end" }),
      shift(),
    ],
  });

  const dismiss = useDismiss(context, {});
  const role = useRole(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    dismiss,
    role,
  ]);

  return (
    <div className={styles.app_download_wrapper}>
      <button
        className={styles.btn_app_list}
        ref={refs.setReference}
        {...getReferenceProps()}
        onClick={onClickMenuButton}
      >
        <img src={DownloadIcon} alt="icon" />
        <span>앱 다운로드</span>
      </button>
      <div className={styles.popup_menu}>
        {isMenuOpen && (
          <FloatingFocusManager context={context} modal={false}>
            <MenuBox
              ref={refs.setFloating}
              width="208px"
              padding="9px 8px"
              floatingStyles={floatingStyles}
              {...getFloatingProps()}
            >
              <div className={styles.app_list}>
                <AppDownloadButtons />
              </div>
            </MenuBox>
          </FloatingFocusManager>
        )}
      </div>
    </div>
  );
}
