import styles from "./alan-guide-tooltip.module.scss";

export const DEFAULT_GUIDE_TITLE = "활용 가이드";

export function DefaultGuideContent() {
  return (
    <ul className={styles.guide_list}>
      <li>답변의 정확도나 적절성에 일부 불확실성이 있을 수 있어요.</li>
      <li>개인정보와 같은 민감한 비공개 정보는 입력하지 말아주세요.</li>
      <li>명확하고 상세하게 검색하면 더 잘 대답해요.</li>
      <li>URL과 함께 질문하면 참고하여 답변할 수 있어요.</li>
      <li>
        현재는 하루에 정해진 검색 개수만큼만 검색할 수 있어요.
        <br />
        (검색, 관련된 질문 시 차감)
      </li>
      <li>품질 개선을 위해 검색어 및 답변이 활용될 수 있어요.</li>
    </ul>
  );
}
