import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

type ShareButtonTooltipsStates = {
  isShareButtonGuideOpen: boolean;
  setIsShareButtonGuideOpen: (isShareButtonGuideOpen: boolean) => void;

  isCopyChannelGuideOpen: boolean;
  setIsCopyChannelGuideOpen: (isCopyChannelGuideOpen: boolean) => void;
};

export const useShareTooltips = create<ShareButtonTooltipsStates>()(
  persist(
    (set, _get) => ({
      isShareButtonGuideOpen: true,
      setIsShareButtonGuideOpen: (isShareButtonGuideOpen) =>
        set({ isShareButtonGuideOpen }),

      isCopyChannelGuideOpen: true,
      setIsCopyChannelGuideOpen: (isCopyChannelGuideOpen) =>
        set({ isCopyChannelGuideOpen }),
    }),
    {
      name: "alan.share-tooltips",
      version: 2025031201,
      storage: createJSONStorage(() => window.localStorage),
    },
  ),
);
