import { type InferOutput, v } from "~libs/valibot";
import { VotedTypes } from "./message-feedback.schema";

/**
 * @example
 * - request payload
  ```json
  {
    "message_id": "66a367943b52882d505a6e0f",
    "type": "DOWN",
    "selected_ids": [
        "64c9f678d4c34048a4d0ab69",
        "64c9f6b4d4c34048a4d0ab6a",
        "64c9f6ded4c34048a4d0ab6b"
    ],
    "message": "asdfasdfasdf"
  }
  ```
 * 
  * - response
  */
export type SendFeedbackMessage = InferOutput<typeof SendFeedbackMessageSchema>;

const SendFeedbackMessageSchema = v.object({
  messageId: v.string(),
  reasonsIds: v.array(v.string()),
  feedbackMessage: v.string(),
});

const SendFeedbackMessageRequestSchema = v.pipe(
  SendFeedbackMessageSchema,

  v.transform(({ messageId, reasonsIds, feedbackMessage }) => ({
    message_id: messageId,
    type: VotedTypes.DOWN,
    selected_ids: reasonsIds,
    message: feedbackMessage,
  })),
);

export const transformRequest = (request: SendFeedbackMessage) =>
  v.parse(SendFeedbackMessageRequestSchema, request, { abortEarly: true });
