import type { PropsWithChildren } from "react";
import { BackButton } from "~features/ui/back-button";
import { HomeButton } from "~features/ui/home-button";
import { useMobileLayer } from "./use-mobile-layer";

import styles from "./mobile-layer.module.scss";

export function MobileLayer({ children }: PropsWithChildren) {
  const { setIsOpen } = useMobileLayer();
  const closeLayer = () => {
    setIsOpen(false);
  };

  return (
    <div className={styles.layer_mobile_prompt}>
      <div className={styles.top_wrap}>
        <div className={styles.btn_wrap}>
          <BackButton onClick={closeLayer} />
          <HomeButton onClick={closeLayer} />
        </div>
      </div>
      <div className={styles.prompt_wrap}>{children}</div>
    </div>
  );
}
