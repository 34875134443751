import { match } from "ts-pattern";
import { ulid } from "ulid";
import { SuggestionsLoadingStatus } from "~features/youtube-summary/message-list";
import { type InferOutput, v } from "~libs/valibot";
import { logger } from "~utils/logger";
import { timestamp2Seconds } from "./utils";
import { YoutubeSummaryServerSchemas } from "./youtube-summary-server.schema";

enum VideoTypes {
  youtube = "youtube",
}

export enum SummaryStatus {
  init = "init",
  start = "start",
  loading = "loading",
  success = "success",
  error = "error",
}

export type YoutubeSummary = InferOutput<typeof YoutubeSummarySchema>;

export const SUMMARY_RESULT_INIT: YoutubeSummary = {
  status: SummaryStatus.init,
  tldr: "",
  video: {
    id: "",
    type: VideoTypes.youtube,
    title: "",
    url: "",
  },
  summary: [],
  scripts: [],
  suggestions: [],
  suggestionsLoading: SuggestionsLoadingStatus.SUCCESS,
};

export const SUMMARY_RESULT_START: YoutubeSummary = Object.assign(
  {},
  SUMMARY_RESULT_INIT,
  {
    status: SummaryStatus.start,
  },
);

const SUMMARY_RESULT_ERROR: YoutubeSummary = Object.assign(
  {},
  SUMMARY_RESULT_INIT,
  {
    status: SummaryStatus.error,
  },
);

const YoutubeSummarySchema = v.pipe(
  YoutubeSummaryServerSchemas.Complete,

  v.transform((res) => {
    const {
      video_id,
      title,
      metadata: scripts,
      summary,
      suggest_questions,
    } = res;

    return {
      status: SummaryStatus.success,

      tldr: summary.total_summary?.join("\n\n") ?? "",

      video: {
        id: video_id,
        type: VideoTypes.youtube,
        title,
        url: `https://www.youtube.com/watch?v=${video_id}`,
      },

      /**
       * id: string
       * title: string
       * timestamp: Timestamp
       * timeSec: number
       * summary: {id: string, content: string}[]
       * script: string
       */
      summary: summary.chapters.map((chapter) => ({
        id: ulid(),
        title: chapter.title,
        timestamp: chapter.timestamp,
        timeSec: timestamp2Seconds(chapter.timestamp),
        summary: chapter.summary.map((line) => ({
          id: ulid(),
          content: line,
        })),
        script: chapter.detail ?? [],
      })),

      /**
       * id: string
       * title: string
       * timestamp: Timestamp
       * timeSec: number
       * scripts: {id: string, timestamp: Timestamp, timeSec: number, content: string}[]
       */
      scripts: scripts
        .filter((script) => script.scripts.length > 0)
        .map((ch) => ({
          id: ulid(),
          title: String(ch.chapter_title),
          timestamp: ch.scripts[0].timestamp,
          timeSec: timestamp2Seconds(ch.scripts[0].timestamp),
          scripts: ch.scripts.map((script) => ({
            id: ulid(),
            timestamp: script.timestamp,
            timeSec: timestamp2Seconds(script.timestamp),
            content: script.content,
          })),
        })),

      suggestions: suggest_questions ?? [],

      suggestionsLoading:
        (suggest_questions?.length ?? 0) > 0
          ? SuggestionsLoadingStatus.SUCCESS
          : SuggestionsLoadingStatus.LOADING,
    };
  }),
);

const SUMMARY_RESULT_LOADING: YoutubeSummary = Object.assign(
  {},
  SUMMARY_RESULT_INIT,
  {
    status: SummaryStatus.loading,
  },
);

const YoutubeSummaryLoadingSchema = v.pipe(
  YoutubeSummaryServerSchemas.LoadingInfo,
  v.transform((res): YoutubeSummary => {
    return Object.assign({}, SUMMARY_RESULT_LOADING, {
      video: { id: res.video_id, title: res.title ?? "" },
    } as Partial<YoutubeSummary>);
  }),
);

export const transformResponse = (res: unknown) => {
  return match(res)
    .returnType<YoutubeSummary>()
    .when(
      () =>
        v.safeParse(YoutubeSummaryServerSchemas.Complete, res, {
          abortEarly: true,
        }).success,
      () => {
        return v.parse(YoutubeSummarySchema, res, { abortEarly: true });
      },
    )
    .when(
      () =>
        v.safeParse(YoutubeSummaryServerSchemas.LoadingInfo, res, {
          abortEarly: true,
        }).success,
      () => {
        return v.parse(YoutubeSummaryLoadingSchema, res, {
          abortEarly: true,
        });
      },
    )
    .when(
      () =>
        v.safeParse(YoutubeSummaryServerSchemas.Init, res, {
          abortEarly: true,
        }).success,
      () => {
        return SUMMARY_RESULT_START;
      },
    )
    .when(
      () =>
        v.safeParse(YoutubeSummaryServerSchemas.RequestReceived, res, {
          abortEarly: true,
        }).success,
      () => {
        return SUMMARY_RESULT_START;
      },
    )
    .when(
      () =>
        v.safeParse(YoutubeSummaryServerSchemas.InvalidDataError, res, {
          abortEarly: true,
        }).success,
      () => {
        /** @todo 에러 로그 수집 */
        return SUMMARY_RESULT_ERROR;
      },
    )
    .when(
      () =>
        v.safeParse(YoutubeSummaryServerSchemas.VideoNotFoundError, res, {
          abortEarly: true,
        }).success,
      () => {
        /** @todo 에러 로그 수집 */
        return SUMMARY_RESULT_ERROR;
      },
    )
    .when(
      () =>
        v.safeParse(YoutubeSummaryServerSchemas.ContentFilterError, res, {
          abortEarly: true,
        }).success,
      () => {
        /** @todo 에러 로그 수집 */
        return SUMMARY_RESULT_ERROR;
      },
    )

    .when(
      () =>
        v.safeParse(YoutubeSummaryServerSchemas.ServerError, res, {
          abortEarly: true,
        }).success,
      () => {
        /** @todo 에러 로그 수집 */
        return SUMMARY_RESULT_ERROR;
      },
    )
    .otherwise(() => {
      logger.debug({
        scope: "YoutubeSummarySchema.transformResponse.otherwise",
        data: res as Record<string, unknown>,
      });
      return SUMMARY_RESULT_ERROR;
    });
};
