import { createInfoToast } from "~features/toast";
import { CONTAINER_IDS } from "~features/toast/constants";

const sharingWarningToast = createInfoToast({
  containerId: CONTAINER_IDS.BOTTOM,
  toastId: "share-warning-toast",
});

export const setSharingWarningToast = () => {
  window.setTimeout(() => {
    sharingWarningToast(
      "공유된 검색 페이지에서 이어지는 검색은\n함께 공유되니 주의하세요!",
    );
  }, 500);
};
