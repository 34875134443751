import type { MouseEvent } from "react";
import { useChannel, useQueryChannel } from "~features/channel";
import { useGuestModeStore } from "~features/guest-mode";
import { setDefaultErrorToast } from "~features/toast";
import { GuideTooltip } from "~features/ui/guide-tooltip";
import CheckIcon from "./images/ico_check.svg";
import ShareIcon from "./images/ico_share.svg";
import styles from "./share-button.module.scss";
import { useShareContext } from "./share-context";
import { useUrlCopy } from "./url-copy-button";
import { useMutationToggleShared } from "./use-mutation-toggle-shared";
import { useShareTooltips } from "./use-share-tooltips";

export function ShareButton() {
  const { channelId } = useChannel();
  const { data: channel } = useQueryChannel(channelId);
  const { isShareModalOpen, setIsShareModalOpen } = useShareContext();
  const { mutateAsync } = useMutationToggleShared();
  const { isTooltipOpen, setIsTooltipOpen } = useShareTooltips((s) => ({
    isTooltipOpen: s.isShareButtonGuideOpen,
    setIsTooltipOpen: s.setIsShareButtonGuideOpen,
  }));
  const { copyUrl } = useUrlCopy();
  const { enabled: isGuestMode, setShowMainModal } = useGuestModeStore();

  const toggleShareModalOpen = (e: MouseEvent) => {
    e.stopPropagation();

    if (isGuestMode) {
      setShowMainModal(true);
      return;
    }

    if (isShareModalOpen) {
      setIsShareModalOpen(false);
      return;
    }

    if (channel?.isShared) {
      setIsShareModalOpen(true);
      return;
    }

    if (!channel?.isSharing) {
      mutateAsync()
        .then(() => {
          copyUrl();
        })
        .catch(() => {
          setIsShareModalOpen(false);
          setDefaultErrorToast("공유 권한 변경 중 에러가 발생했습니다.");
        });
    }

    setIsShareModalOpen((prev) => !prev);
  };

  return (
    <div className={styles.btn_share_wrapper}>
      <button
        type="button"
        onClick={toggleShareModalOpen}
        className={styles.btn_share}
        aria-label="share button"
      >
        <img src={channel?.isSharing ? CheckIcon : ShareIcon} alt="icon" />
        <span>{channel?.isSharing ? "공유 중" : "공유하기"}</span>
      </button>

      {!channel?.isShared && isTooltipOpen && (
        <GuideTooltip
          isTop={true}
          title="검색 결과 공유하기"
          text={
            "이 검색 결과 페이지를 1초 만에 공유하세요! 공유한 링크에는 추가되는 관련 질문까지 최신 내용이 반영돼요."
          }
          onClose={() => {
            setIsTooltipOpen(false);
          }}
        />
      )}
    </div>
  );
}
