import type { FC } from "react";
import searchImg from "~assets/images/ico_search.png";
import { Button } from "~features/ui/button";
import { createEventHandler } from "~utils/create-event-handler";
import styles from "./search-button.module.scss";

type SearchButtonProps = {
  onSubmit?: () => void;
  isDisabled?: boolean;
};

export const SearchButton: FC<SearchButtonProps> = ({
  onSubmit,
  isDisabled = false,
}) => {
  const _onSubmit = createEventHandler({
    handler: () => {
      onSubmit?.();
    },
  });

  return (
    <Button
      onClick={_onSubmit}
      overrideClass={styles.btn_search}
      ButtonType="onlyIcon"
      aria-label="search button"
      iconBefore={<img src={searchImg} alt="검색 버튼" />}
      isDisabled={isDisabled}
    />
  );
};
