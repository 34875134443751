import type { PropsWithChildren } from "react";
import Slider, { type Settings } from "react-slick";
import { MOBILE_WIDTH_SMALL } from "~constants/sizes";
import type { List } from "~features/util-types/list";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styles from "./carousel-list.module.scss";
import "./slick.override.scss";

type CarouselListProps = PropsWithChildren<
  Pick<List, "total"> & { title: string }
>;

const SLIDER_SETTINGS: Settings = {
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  arrows: true,
  variableWidth: true,
  responsive: [
    {
      breakpoint: MOBILE_WIDTH_SMALL,
      settings: "unslick",
    },
  ],
  accessibility: false,
};

export function CarouselList({ title, total, children }: CarouselListProps) {
  const shouldUseSlider = total > 4;

  return (
    <div className={styles.carousel_wrapper}>
      <h4 className={styles.title}>
        {title} ({total})
      </h4>
      {shouldUseSlider ? (
        <Slider {...SLIDER_SETTINGS}>{children}</Slider>
      ) : (
        <div className={styles.slider_wrap}>{children}</div>
      )}
    </div>
  );
}
