import { MainPagePrompt } from "~features/prompt/main-page-prompt";
import { SuggestionCards } from "~features/suggestions";
import { HomePageFooter } from "~features/ui/footer";
import { MainTitle } from "~features/ui/titles";
import styles from "./home.module.scss";

const HomeContents = () => {
  return (
    <>
      <div className={styles.home_wrapper}>
        <div className={styles.home_wrap}>
          <MainTitle />
          <section className={styles.home_section}>
            <div className={styles.prompt_wrap}>
              <MainPagePrompt />
            </div>
            <div className={styles.suggestion_wrap}>
              <SuggestionCards className={styles.suggestion} />
            </div>
          </section>
        </div>
        <HomePageFooter />
      </div>
    </>
  );
};

export default HomeContents;
