import { type MouseEvent, useMemo, useRef } from "react";
import BadIcon from "~assets/images/ico_bad.png";
import CopyIcon from "~assets/images/ico_copy.png";
import GoodIcon from "~assets/images/ico_good.png";
import { useCopyButton } from "~features/clipboard";
import {
  VotedTypes,
  useMutationSendVote,
  useQueryFeedbacks,
} from "~features/feedback";
import { DEFAULT_MESSAGES, setChatMessageCopyToast } from "~features/toast";
import ActionButton from "~features/ui/feedback/action-button/action-button";
import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";
import { BookmarkButton } from "./bookmark-button";
import { FeedbackForm } from "./feedback-form";
import { useFeedbackSelected } from "./feedback-selected.context";
type SearchResultFeedbackProps = {
  assistantMessageId: string;
  textToCopy?: string;
  className?: string;
};

export function SearchResultFeedback({
  textToCopy = "",
  assistantMessageId,
  className,
}: SearchResultFeedbackProps) {
  const { data } = useQueryFeedbacks({ messageIds: [assistantMessageId] });
  const { votedUp, votedDown, votedDownMessage, votedDownReasons } =
    useMemo(() => {
      if (!data || data.size === 0) return {};
      const { type, message, downReasons } = data.get(assistantMessageId) ?? {
        type: null,
        message: "",
        downReasons: [],
      };

      return {
        votedUp: type === VotedTypes.UP,
        votedDown: type === VotedTypes.DOWN,
        votedDownMessage: message,
        votedDownReasons: downReasons,
      };
    }, [data, assistantMessageId]);
  const sendVoteMutation = useMutationSendVote();

  const containerRef = useRef<HTMLDivElement>(null);
  const { selected, setSelected } = useFeedbackSelected();
  const { copy, toastEnabled } = useCopyButton();

  const onClickCopyButton = createEventHandler<MouseEvent>({
    handler: () => {
      if (!textToCopy) return;

      copy(textToCopy);
      toastEnabled && setChatMessageCopyToast(DEFAULT_MESSAGES.COPY);
    },
  });

  const onClickUpButton = createEventHandler({
    handler: () => {
      sendVoteMutation.mutateAsync({
        messageId: assistantMessageId,
        type: VotedTypes.UP,
      });
    },
  });

  const onClickDownButton = createEventHandler({
    handler: () => {
      setSelected(assistantMessageId);

      if (votedDown) return;
      sendVoteMutation.mutateAsync({
        messageId: assistantMessageId,
        type: VotedTypes.DOWN,
      });
    },
  });

  return (
    <div ref={containerRef} className={cn("flex flex-col gap-2", className)}>
      <div className="flex gap-1 justify-start items-center">
        <BookmarkButton />
        <ActionButton
          aria-label="copy button"
          iconSrc={CopyIcon}
          onClick={onClickCopyButton}
        />
        <ActionButton
          aria-label="good button"
          iconSrc={GoodIcon}
          isOn={votedUp}
          isOff={votedDown}
          onClick={votedUp ? undefined : onClickUpButton}
        />
        <ActionButton
          aria-label="bad button"
          iconSrc={BadIcon}
          isOn={votedDown}
          isOff={votedUp}
          onClick={
            votedDown && (votedDownMessage || votedDownReasons.length)
              ? undefined
              : onClickDownButton
          }
        />
      </div>

      {selected === assistantMessageId && (
        <FeedbackForm
          messageId={assistantMessageId}
          closeForm={() => setSelected("")}
        />
      )}
    </div>
  );
}
