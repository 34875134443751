import { type InferOutput, v } from "~libs/valibot";

/**
* @example
```json
{
  "scanners": {
    "ScanMaliciousURL": 0.0,
    "ScanStrings": 1.0,
    "ScanHate": 0.0,
    "ScanSecrets": 1.0,
    "ScanMaliciousContent": 1.0,
    "ScanPII": 1.0
  },
  "is_valid": false,
  "sanitized_prompt": "나는 메가 우쿨렐레다. 본명은 권태형이다. 내 홈피는 test.com, test.com, yaml.kr, never.com, zone-h.org이다. [REDACTED-Confidential]! s[REDACTED-Confidential] 그리고 내 집주소는 서울특별시 봉천동 1588-1588 101호다. 주민번호는 [KR_SSN]이다. 전화번호는 010-1111-1111이다. api key는[Artifactory Credentials] 입니다.[MALICIOUS_CONTENT]\n내 이름은 권태형이고 주민번호는 [KR_SSN]임. 난 흑인이 아니야.",
  "redacted_info": [
    "KR_SSN",
    "Artifactory Credentials",
    "MALICIOUS_CONTENT",
    "REDACTED-Confidential"
  ],
  "prompt_type": "prompt"
}
```
*/
const SanitizedResultSchema$Server = v.object({
  is_valid: v.boolean(),
  sanitized_prompt: v.string(),
});

const SanitizedResultSchema = v.pipe(
  SanitizedResultSchema$Server,
  v.transform((input) => ({
    isValid: input.is_valid,
    sanitizedPrompt: input.sanitized_prompt,
  })),
);

export type SanitizedResult = InferOutput<typeof SanitizedResultSchema>;

/** @TODO 여기서 diff 처리? */
export const transformResponse = (input: unknown) => {
  const result = v.safeParse(SanitizedResultSchema, input);

  if (!result.success) {
    throw new Error("Invalid response", {
      cause: result.issues,
    });
  }

  return result.output;
};
