/**
 * @link
 * - prod: https://api.alan.est.ai/auth/sign-in?state=https://alan.est.ai/
 * - stage: https://stage-api.alan.est.ai/auth/sign-in?state=http://stage.alan.est.ai/
 * - dev: https://dev-api.alan.est.ai/auth/sign-in?state=http://dev.alan.est.ai/
 */
const API_URL = `${import.meta.env.VITE_API_BASE_URL}/auth/sign-in`;

export const redirectToOAuthLogin = (
  nextPage = window.location.origin.concat(window.location.search),
) => {
  const redirectUrl = new window.URL(API_URL);
  redirectUrl.searchParams.set("callback", nextPage);
  window.location.assign(redirectUrl.href);
};
