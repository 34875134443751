import { createFileRoute } from "@tanstack/react-router";
import { useEffect } from "react";
import { DEFAULT_TITLE } from "~constants/page-title";
import { WithNativeAccessToken, userQueryOptions } from "~features/auth";
import { useGuestModeSetup } from "~features/guest-mode";
import { personasQueryOptions, usePersonas } from "~features/personas";
import { SearchLayout } from "~features/search/layouts";
import { IsPendingContext } from "~features/search/use-is-pending.context";
import { WithSanitizerContext } from "~features/xllm";
import { HeaderHome } from "~layouts/header";
import { HomeContents } from "~layouts/home";

export const Route = createFileRoute("/")({
  component: () => (
    <WithNativeAccessToken>
      <HomePage />
    </WithNativeAccessToken>
  ),
  beforeLoad: async ({ context: { queryClient } }) => {
    return queryClient
      .ensureQueryData(userQueryOptions)
      .then((result) => {
        return { isLoggedIn: result.id.length > 0 };
      })
      .catch((error) => {
        console.error("[HomeRoute.beforeLoad]\n", error);
        return { isLoggedIn: false };
      });
  },
  loader: ({ context: { queryClient, isLoggedIn } }) => {
    if (!isLoggedIn) {
      return;
    }
    queryClient.ensureQueryData(personasQueryOptions);
  },
});

function HomePage() {
  const { persona, setPersona, isPending, searchDefault } = usePersonas();

  const { isLoggedIn } = Route.useRouteContext();
  useGuestModeSetup({ isLoggedIn });

  useEffect(() => {
    document.title = DEFAULT_TITLE;
  }, []);

  useEffect(() => {
    if (isPending || !searchDefault) {
      return;
    }

    if (persona.id === searchDefault.id) return;

    setPersona(searchDefault);
  }, [searchDefault, setPersona, isPending]);

  return (
    <SearchLayout isHome>
      <IsPendingContext.Provider value={{ isPending: false }}>
        <WithSanitizerContext key={"main"}>
          <HeaderHome />

          <HomeContents />
        </WithSanitizerContext>
      </IsPendingContext.Provider>
    </SearchLayout>
  );
}
