import { MESSAGE_STATUS$SERVER } from "./constants.server";

export enum MESSAGE_USER_ROLE {
  ASSISTANT = "assistant",
  USER = "user",
  // START = "start",
}

export enum MESSAGE_STATUS {
  /** @description message post ~ saved 사이 */
  // INIT = "init",
  // LOADING = "loading",
  SAVED = "saved",
  START_AGENT = "start-agent",

  TOOL_ACTION = "tool-action",
  REASONING = "reasoning",

  IN_ANSWER = "in-answer",
  CONTINUE_START_AGENT = "continue-start-agent",
  FINISH_ANSWER = "finish-answer",
  PAUSED_BY_LENGTH = "paused-by-length",

  CANCEL = "cancel",
  ERROR = "error",
  ERROR_CONTENT_FILTERED = "error-content-filter",

  RELATED_QUESTIONS = "related-questions",

  // USER_MESSAGE = "user-message",
}

export const MESSAGE_ERROR_CONTENTS = {
  [MESSAGE_STATUS$SERVER.CONTENT_FILTER]:
    "OpenAI의 컨텐츠 정책을 위반한 발화로 감지되었습니다. 해당 질문에는 답변할 수 없습니다.",
  [MESSAGE_STATUS$SERVER.CANCELED]: "답변이 취소되었어요.",
  [MESSAGE_STATUS$SERVER.ERROR]:
    "답변을 준비하다가 문제가 생겼어요. 다시 질문해 주세요.",
} as const;
