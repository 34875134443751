import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";
import styles from "./toggle-button.module.scss";

type CheckedColor = "green";

export const ToggleButton: React.FC<
  {
    isChecked: boolean;
    toggleFn: () => void;
    title?: string;
    img?: string;
    checkedColor?: CheckedColor;
    className?: string;
  } & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  isChecked,
  title,
  img,
  checkedColor,
  className,
  toggleFn,
  ...props
}) => {
  const onClick = createEventHandler({ handler: toggleFn });

  return (
    <button
      type="button"
      className={cn(styles.toggle_wrap, className)}
      onClick={onClick}
      {...props}
    >
      {(title || img) && (
        <div className={styles.toggle_label}>
          {img && <img src={img} draggable={false} alt="" />}
          {title && <p>{title}</p>}
        </div>
      )}
      <div
        className={cn(
          styles.toggle_container,
          checkedColor && styles[checkedColor],
          { [styles.checked]: isChecked },
        )}
      >
        <i className={styles.toggle_circle} />
      </div>
    </button>
  );
};
