import { v } from "~libs/valibot";

export type Channel = {
  id: string;
  personaId: string;
  title: string | null;
  userId: string;
  /** @todo 검색 개편 전 후 version - 네이밍을.. */
  v: string;
  xllmEnabled: boolean;
  isShared: boolean;
  isSharing: boolean;
};

/**
 * @example
 * 
 * @see {@link https://func08-api-estsoft-algpt-stage.azurewebsites.net/api/v1/channels/66d1332bb6af94b7ef74e61e}
 * 
 ```json
 {
    "_id": "66d1332bb6af94b7ef74e61e",
    "title": "최근 일주일 간 팔란티어 주요 이슈: 미육군 AI 계약, HD현대 협력, 주가 상승과 AI 수혜 전망",
    "user_id": "65af73b6583a64a91bd6e002",
    "persona_id": "6480922a1ec61303770dc724",
    "createdAt": "2024-08-30T02:49:15.044000",
    "updatedAt": "2024-08-30T02:49:15.044000"
  }
 ```
 */
const ChannelResponseSchema = v.object({
  _id: v.string(),
  title: v.nullable(v.string()),
  user_id: v.string(),
  persona_id: v.string(),
  updatedAt: v.string(),
  shared: v.optional(v.boolean(), false),

  options: v.optional(
    v.object({
      xllm_enabled: v.optional(v.boolean(), false),
    }),
    {
      xllm_enabled: false,
    },
  ),
});

const ChannelSchema = v.pipe(
  ChannelResponseSchema,

  v.transform((response) => ({
    id: response._id,
    personaId: response.persona_id,
    title: response.title,
    userId: response.user_id,
    v: getVersionByUpdated(response.updatedAt),
    xllmEnabled: response.options.xllm_enabled,
    isShared: response.shared,
  })),
);

// TODO: 검색 개편 전 후 version
const getVersionByUpdated = (updatedAt: string) => updatedAt;

export const transformResponse = (
  response: unknown,
  userId: string,
): Channel => {
  const parseResult = v.parse(ChannelSchema, response, { abortEarly: true });
  return Object.assign({}, parseResult, {
    isShared: parseResult.isShared && parseResult.userId !== userId,
    isSharing: parseResult.isShared && parseResult.userId === userId,
  });
};
