export { MessagesSchemas$Api } from "./schemas.api";
export type { Message$Api } from "./schemas.api";

import { MESSAGE_STATUS } from "../constants";
import {
  CancelMessagePattern$Socket,
  parseCancelMessage$Socket,
} from "./schema.socket.cancel";
import {
  ContentFilterErrorMessagePattern$Socket,
  parseContentFilterErrorMessage$Socket,
} from "./schema.socket.content-filtered";
import {
  ContinueStartAgentMessagePattern$Socket,
  parseContinueStartAgentMessage$Socket,
} from "./schema.socket.continue-start-agent";
import {
  ErrorMessagePattern$Socket,
  parseErrorMessage$Socket,
} from "./schema.socket.error";
import {
  FinishAnswerMessagePattern$Socket,
  parseFinishAnswerMessage$Socket,
} from "./schema.socket.finish-answer";
import {
  InAnswerMessagePattern$Socket,
  parseInAnswerMessage$Socket,
} from "./schema.socket.in-answer";
import {
  PausedByLengthMessagePattern$Socket,
  parsePausedByLengthMessage$Socket,
} from "./schema.socket.paused-by-length";
import {
  ReasoningMessagePattern$Socket,
  parseReasoningMessage$Socket,
} from "./schema.socket.reasoning";
import {
  RelatedQuestionsMessagePattern$Socket,
  parseRelatedQuestionsMessage$Socket,
} from "./schema.socket.related-questions";
import {
  SavedMessagePattern$Socket,
  parseSavedMessage$Socket,
} from "./schema.socket.saved";
import {
  StartAgentMessagePattern$Socket,
  parseStartAgentMessage$Socket,
} from "./schema.socket.start-agent";
import {
  ToolActionMessagePattern$Socket,
  parseToolActionMessage$Socket,
} from "./schema.socket.tool-action";

export const SocketMessagePatterns = {
  [MESSAGE_STATUS.IN_ANSWER]: InAnswerMessagePattern$Socket,
  [MESSAGE_STATUS.SAVED]: SavedMessagePattern$Socket,
  [MESSAGE_STATUS.START_AGENT]: StartAgentMessagePattern$Socket,
  [MESSAGE_STATUS.CONTINUE_START_AGENT]:
    ContinueStartAgentMessagePattern$Socket,
  [MESSAGE_STATUS.TOOL_ACTION]: ToolActionMessagePattern$Socket,
  [MESSAGE_STATUS.REASONING]: ReasoningMessagePattern$Socket,
  [MESSAGE_STATUS.FINISH_ANSWER]: FinishAnswerMessagePattern$Socket,
  [MESSAGE_STATUS.PAUSED_BY_LENGTH]: PausedByLengthMessagePattern$Socket,
  [MESSAGE_STATUS.RELATED_QUESTIONS]: RelatedQuestionsMessagePattern$Socket,
  [MESSAGE_STATUS.CANCEL]: CancelMessagePattern$Socket,

  [MESSAGE_STATUS.ERROR]: ErrorMessagePattern$Socket,
  [MESSAGE_STATUS.ERROR_CONTENT_FILTERED]:
    ContentFilterErrorMessagePattern$Socket,
} as const;

export const SocketMessageParsers = {
  [MESSAGE_STATUS.IN_ANSWER]: parseInAnswerMessage$Socket,
  [MESSAGE_STATUS.SAVED]: parseSavedMessage$Socket,
  [MESSAGE_STATUS.START_AGENT]: parseStartAgentMessage$Socket,
  [MESSAGE_STATUS.CONTINUE_START_AGENT]: parseContinueStartAgentMessage$Socket,
  [MESSAGE_STATUS.TOOL_ACTION]: parseToolActionMessage$Socket,
  [MESSAGE_STATUS.REASONING]: parseReasoningMessage$Socket,
  [MESSAGE_STATUS.FINISH_ANSWER]: parseFinishAnswerMessage$Socket,
  [MESSAGE_STATUS.PAUSED_BY_LENGTH]: parsePausedByLengthMessage$Socket,
  [MESSAGE_STATUS.RELATED_QUESTIONS]: parseRelatedQuestionsMessage$Socket,
  [MESSAGE_STATUS.CANCEL]: parseCancelMessage$Socket,

  [MESSAGE_STATUS.ERROR]: parseErrorMessage$Socket,
  [MESSAGE_STATUS.ERROR_CONTENT_FILTERED]:
    parseContentFilterErrorMessage$Socket,
} as const;
