import { useMutationSanitizePrompt } from "./apis";
import { useSanitizerContext } from "./sanitizer.context";

export const xllmHooks = {
  useMutationSanitizePrompt,
  useSanitizerContext,
};

export { WithSanitizerContext } from "./sanitizer.context";
export { SanitizerModal } from "./sanitizer-modal";
