import { HTTPError } from "ky";
import { ERROR_TYPES, EXCEPTION_PATHS } from "./constants";

export const isKyHttpError = (error: unknown): error is HTTPError =>
  error instanceof HTTPError;

/**
 * @todo 응답 그대로 받지 말고, 상태코드/응답데이터에 따라 객체 생성
 */

export const parseKyHttpError = async <Message = Record<string, string>>(
  error: HTTPError,
) => {
  return {
    hasError: true,
    errorType: ERROR_TYPES.KyHttpError,
    data: {
      status: { code: error.response.status, text: error.response.statusText },
      response: (await error.response.json()) as Message,
    },
  };
};

export const redirectToErrorPage$web = () => {
  /** @todo refactor - 함수 분리 */
  const currentPath = window.location.pathname;
  if (
    currentPath.includes(EXCEPTION_PATHS.SERVER_ERROR) ||
    currentPath.includes(EXCEPTION_PATHS.NOT_FOUND) ||
    currentPath.includes(EXCEPTION_PATHS.SERVER_MAINTENANCE)
  )
    return;
  window.location.href = EXCEPTION_PATHS.SERVER_ERROR;
};
