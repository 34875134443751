const ALTOOLS_LOGIN_URL = import.meta.env.VITE_ALTOOLS_LOGIN_URL;
const ALTOOLS_MANAGER_URL = import.meta.env.VITE_ALTOOLS_MANAGER_URL;

// errors
// system? error 0000 ~ 0999
export const ERROR_SERVICE = {
  code: "0000",
  message: "서비스가 원활하지 않습니다. (000)",
} as const;
export const ERROR_NETWORK = {
  code: "0001",
  message: "네트워크가 원활하지 않습니다.",
} as const;

// user error 1000 ~ 1999
export const ERROR_UNKNOWN_USER = {
  code: "1000",
  message: "서비스가 원활하지 않습니다. (001)",
} as const;
export const ERROR_USER_INFO = {
  code: "1001",
  message: "사용자 정보가 없습니다.",
} as const;

// persona error 2000 ~ 2999
export const ERROR_UNKNOWN_PERSONA = {
  code: "2000",
  message: "서비스가 원활하지 않습니다. (002)",
} as const;
export const ERROR_PERSONA = {
  code: "20001",
  message: "Persona 정보가 없습니다.",
} as const;

// channel error 3000 ~ 3999
export const ERROR_UNKNOWN_CHANNEL = {
  code: "3000",
  message: "서비스가 원활하지 않습니다. (003)",
} as const;
export const ERROR_CHANNEL = {
  code: "3001",
  message: "채널 정보를 가져오는데 실패했습니다.",
} as const;
export const ERROR_CHANNEL_NOT_FOUND = {
  code: "3002",
  message: "채널 정보가 없습니다.",
} as const;

// user message error 4000 ~ 4999
export const ERROR_UNKNOWN_MESSAGE = {
  code: "4000",
  message: "서비스가 원활하지 않습니다. (004)",
} as const;
export const ERROR_MESSAGE_USAGE = {
  code: "4001",
  message: "오늘의 질문 개수를 모두 사용하셨습니다.",
} as const;
export const ERROR_MESSAGE_MAX_LENGTH = {
  code: "4002",
  message: "한번에 질문 가능한 글자 수는 최대 500자 입니다.",
} as const;
export const ERROR_LIMIT_MESSAGES_IN_CHANNEL = {
  code: "4003",
  message:
    "한 채널에 질문 가능한 수를 초과하였습니다.\n질문은 최대 50개 까지 가능 합니다.",
} as const;
export const ERROR_SENDING_UNAUTHORIZED = {
  code: "4004",
  message: "메시지를 보낼 권한이 없습니다.",
} as const;

// answer message error 5000 ~ 5999
export const ERROR_UNKNOWN_ANSWER = {
  code: "5000",
  message: "서비스가 원활하지 않습니다. (005)",
} as const;

// answer error 6000 ~ 6999
export const ERROR_UNKNOWN_HISTORIES = {
  code: "5000",
  message: "서비스가 원활하지 않습니다. (006)",
} as const;

// app protocol error 7000 ~ 7999
export const ERROR_UNKNOWN_PROTOCOL = {
  code: "7000",
  message: "알 수 없는 오류",
} as const;
export const REQUIRE_LOGIN = {
  code: "7001",
  message: "로그인이 필요합니다.",
  landingInfo: { url: ALTOOLS_LOGIN_URL, title: "로그인" },
} as const;
export const REQUIRE_ALMANAGER = {
  code: "7002",
  message: "알매니저 설치되어 있어야 합니다.",
  landingInfo: { url: ALTOOLS_MANAGER_URL, title: "알매니저 다운로드" },
} as const;

// cancel api
export const CANCEL_API = { code: "9999", message: "" } as const;
