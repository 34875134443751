import { useMemo } from "react";
import { MarkdownRenderer } from "~features/markdown-renderer";
import { useMessageIntersectionObserver } from "~features/messages/intersection";
import messagesStyles from "~features/messages/messages.module.scss";
import { MessageTitle } from "~features/messages/ui";
import { Status } from "~features/util-types/status";
import { WithSanitizerContext } from "~features/xllm";
import { cn } from "~utils/class-names";
import type { SearchResult as SearchResultTypes } from "../schemas";
import { SearchResultFeedback } from "./feedback";
import { SearchResultImages } from "./images";
import { ResultInnerBox } from "./inner-box";
import { SearchResultRelatedQuestions } from "./questions";
import styles from "./result.module.scss";
import { ResultSection } from "./section";
import { SearchResultToolSteps } from "./tool-steps";
import { SearchResultVideos } from "./videos";
import { SearchResultWebSources } from "./web-sources";

type SearchResultProps = SearchResultTypes.Item;

export function SearchResult(searchResult: SearchResultProps) {
  const { isLoading, isError } = useMemo(
    () => ({
      isLoading:
        searchResult.status === Status.INIT ||
        searchResult.status === Status.LOADING,
      isError: searchResult.status === Status.ERROR,
    }),
    [searchResult.status],
  );

  const { divRef } = useMessageIntersectionObserver(
    searchResult.messageId?.user ?? "",
  );

  if (isError) {
    // TODO: 디자인 확인, 컴포넌트 분리
    return (
      <ResultSection ref={divRef}>
        <ResultInnerBox className="">
          <MessageTitle>{searchResult.title}</MessageTitle>
        </ResultInnerBox>

        <ResultInnerBox>
          <p className={styles.error_message}>{searchResult.content}</p>
        </ResultInnerBox>
      </ResultSection>
    );
  }

  return (
    <ResultSection ref={divRef} className={cn(isLoading && styles.is_loading)}>
      <ResultInnerBox className="">
        {/* 이전 링크가 있는 결과화면일때 
        <div className={styles.btn_link_wrap}>
          <LinkButton
            link="https://www.aitimes.com/news/articleView.html?idxno=167952"
            title="AI Times"
          />
        </div>*/}
        <MessageTitle>{searchResult.title}</MessageTitle>

        <SearchResultFeedback
          assistantMessageId={searchResult.messageId?.assistant ?? ""}
          textToCopy={searchResult.content}
        />
      </ResultInnerBox>

      <ResultInnerBox className="">
        <SearchResultToolSteps
          title="답변 과정"
          toolSteps={searchResult.toolSteps}
          reasoning={searchResult.reasoning}
          searchStatus={searchResult.status}
        />
      </ResultInnerBox>

      {searchResult.content && (
        <ResultInnerBox
          className={cn(
            styles.markdown_wrap,
            isLoading && messagesStyles.in_answer,
          )}
        >
          <MarkdownRenderer contents={searchResult.content} />
        </ResultInnerBox>
      )}
      {searchResult.sources && searchResult.sources.total > 0 && (
        <ResultInnerBox className="">
          <SearchResultWebSources
            title="출처 웹문서"
            total={searchResult.sources.total}
            items={searchResult.sources.items}
          />
        </ResultInnerBox>
      )}
      {searchResult.images && searchResult.images.total > 0 && (
        <ResultInnerBox className="">
          <SearchResultImages
            title="이미지"
            total={searchResult.images.total}
            items={searchResult.images.items}
          />
        </ResultInnerBox>
      )}
      {searchResult.videos && searchResult.videos.total > 0 && (
        <ResultInnerBox className="">
          <SearchResultVideos
            total={searchResult.videos.total}
            items={searchResult.videos.items}
          />
        </ResultInnerBox>
      )}
      {searchResult.questions && (
        <ResultInnerBox>
          <WithSanitizerContext
            key={`message-${searchResult.messageId?.assistant ?? ""}`}
          >
            <SearchResultRelatedQuestions
              title="관련된 질문"
              questions={searchResult.questions ?? []}
            />
          </WithSanitizerContext>
        </ResultInnerBox>
      )}
    </ResultSection>
  );
}
