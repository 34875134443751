import {
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

type IntersectingMessagesContext = {
  messageIds: Set<string>;
  addMessageId: (messageId: string) => void;
  removeMessageId: (messageId: string) => void;
};

const IntersectingMessagesContext =
  createContext<IntersectingMessagesContext | null>(null);

export const useIntersectingMessagesContext = () => {
  const context = useContext(IntersectingMessagesContext);
  if (!context) {
    throw new Error(
      "useIntersectingMessagesContext must be used within a IntersectingMessagesContextProvider",
    );
  }
  return context;
};

export function WithIntersectingMessagesContext({
  children,
}: PropsWithChildren) {
  const [messageIds, setMessageIds] = useState(new Set<string>());

  const addMessageId = useCallback((messageId: string) => {
    setMessageIds((prev) => {
      const newMessageIds = new Set(prev);
      newMessageIds.add(messageId);
      return newMessageIds;
    });
  }, []);

  const removeMessageId = useCallback((messageId: string) => {
    setMessageIds((prev) => {
      const newMessageIds = new Set(prev);
      newMessageIds.delete(messageId);
      return newMessageIds;
    });
  }, []);

  return (
    <IntersectingMessagesContext.Provider
      value={{
        messageIds,
        addMessageId,
        removeMessageId,
      }}
    >
      {children}
    </IntersectingMessagesContext.Provider>
  );
}

export const useMessageIntersectionObserver = (messageId: string) => {
  const divRef = useRef<HTMLDivElement>(null);
  const { addMessageId, removeMessageId } = useIntersectingMessagesContext();

  useEffect(() => {
    if (!divRef.current) return;
    if (messageId.length === 0) return;

    const intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        entry.isIntersecting
          ? addMessageId(messageId)
          : removeMessageId(messageId);
      });
    });
    intersectionObserver.observe(divRef.current);

    return () => {
      intersectionObserver.disconnect();
    };
  }, [messageId, addMessageId, removeMessageId]);

  return {
    divRef,
  };
};
