import { type InferOutput, v } from "~libs/valibot";

export type ChannelId = InferOutput<typeof ChannelIdSchema>;

const ChannelIdSchema$Server = v.object({
  /** @description 채널 생성 성공 여부 - 실패하면 schema validation 에러 */
  result: v.literal(true),
  inserted_id: v.pipe(v.string(), v.minLength(1)),
});

const ChannelIdSchema = v.pipe(
  ChannelIdSchema$Server,
  v.transform(({ inserted_id }) => ({ channelId: inserted_id })),
);

export const transformResponse = (response: unknown) =>
  v.parse(ChannelIdSchema, response, { abortEarly: true });
