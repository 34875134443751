import {
  FloatingFocusManager,
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import type { PropsWithChildren } from "react";
import { useState } from "react";
import { useNativeBridge } from "~clients/native-bridge";
import {
  redirectToOAuthLogin,
  useMutationSignOut,
  useUser,
} from "~features/auth";
import { useGuestModeStore } from "~features/guest-mode";
import { DEFAULT_GUIDE_TITLE, DefaultGuideContent } from "~features/tooltip";
import MenuBox from "~features/ui/popup-menu/menu-box/menu-box";
import { useUserAgent } from "~features/user-agent";
import { createEventHandler } from "~utils/create-event-handler";
import { Button } from "../button";
import UserImg from "./images/ico_user.png";
import styles from "./user.module.scss";

type UserIconProps = {
  displayName: string;
  thumbnailUrl?: string;
  onClick?: React.MouseEventHandler;
};

export function UserIcon({ displayName, thumbnailUrl = "" }: UserIconProps) {
  return (
    <div className={styles.icon_user}>
      {thumbnailUrl ? (
        <img src={thumbnailUrl} alt="user icon" />
      ) : displayName ? (
        displayName
      ) : (
        <img src={UserImg} alt="default icon" />
      )}
    </div>
  );
}

type SideBarUserProps = {
  displayName: string;
  thumbnailUrl?: string;
};

function UserFullName({ displayName }: SideBarUserProps) {
  return <span>{displayName}님</span>;
}

export function LoginButton() {
  const { isWeb } = useUserAgent();
  const { nativeBridge } = useNativeBridge();

  const onClick = createEventHandler({
    handler: () => {
      isWeb ? redirectToOAuthLogin() : nativeBridge?.requestLogin();
    },
  });

  return (
    <>
      <div className={styles.login_box}>
        <div>
          <p className={styles.title}>
            똑똑한 실시간 AI 검색,
            <br />
            Alan을 만나보세요.
          </p>
          <p className={styles.detail}>
            회원가입하고 더 많은 검색과
            <br />더 많은 기능을 경험하세요.
          </p>
        </div>
        <Button
          overrideClass={styles.btn_login}
          fullWidth={true}
          text="로그인"
          color="primary"
          onClick={onClick}
        />
      </div>
    </>
  );
}

type GuideButtonProps = {
  onClick: () => void;
};

function GuideButton({ onClick }: GuideButtonProps) {
  return (
    <button type="button" className={styles.menu_item} onClick={onClick}>
      <span>활용 가이드</span>
    </button>
  );
}

function SignOutButton() {
  const signOutMutation = useMutationSignOut();

  const onClickLogoutButton = createEventHandler({
    handler: () => {
      signOutMutation.mutateAsync().then(() => {
        window.location.replace("/");
      });
    },
  });

  return (
    <button
      type="button"
      onClick={onClickLogoutButton}
      className={styles.menu_item}
    >
      <span>로그아웃</span>
    </button>
  );
}

export function UserMenu(props: PropsWithChildren) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isGuideOpen, setIsGuideOpen] = useState(false);
  const { isWeb } = useUserAgent();
  const { enabled: isGuestMode } = useGuestModeStore();

  const onClickMenuButton = createEventHandler({
    handler: () => {
      setIsMenuOpen((prev) => !prev);
      setIsGuideOpen(false);
    },
  });

  const { refs, context, floatingStyles } = useFloating({
    open: isMenuOpen,
    onOpenChange: setIsMenuOpen,
    placement: "top-start",
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({ mainAxis: 16, crossAxis: 0 }),
      flip({ fallbackAxisSideDirection: "end" }),
      shift(),
    ],
  });

  const dismiss = useDismiss(context, {});
  const role = useRole(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    dismiss,
    role,
  ]);

  return (
    <div className={styles.popup_menu}>
      <button
        ref={refs.setReference}
        {...getReferenceProps()}
        onClick={onClickMenuButton}
        className={styles.btn_popup_menu}
      >
        {props.children}
      </button>

      {isMenuOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <MenuBox
            ref={refs.setFloating}
            padding="0"
            floatingStyles={floatingStyles}
            {...getFloatingProps()}
          >
            {!isGuideOpen ? (
              <ul className={styles.menu_list}>
                <li>
                  <GuideButton onClick={() => setIsGuideOpen(true)} />
                </li>
                {isWeb && !isGuestMode && (
                  <li>
                    <SignOutButton />
                  </li>
                )}
              </ul>
            ) : (
              <div className={styles.guide}>
                <div className={styles.title}>{DEFAULT_GUIDE_TITLE}</div>
                <DefaultGuideContent />
              </div>
            )}
          </MenuBox>
        </FloatingFocusManager>
      )}
    </div>
  );
}

export function User() {
  const { isLoggedIn, user } = useUser();
  const { isMobile } = useUserAgent();
  const { enabled: isGuestMode } = useGuestModeStore();

  if (isGuestMode || (!isMobile && !isLoggedIn)) {
    return <LoginButton />;
  }

  return (
    <div className={styles.user_box}>
      <UserMenu>
        <div className={styles.user_info}>
          <UserIcon displayName={user.name[0]} />
          {isLoggedIn && user.name[0] && (
            <UserFullName displayName={user.name} />
          )}
        </div>
      </UserMenu>
    </div>
  );
}
