const ROOT_KEYS = {
  AUTH: "auth",
  HISTORY: "history",
  CHANNELS: "channels",
  CONFIGS: "configs",
  SUMMARY: "summary",
  YOUTUBE: "youtube",
  PERSONAS: "personas",
  SOCKET: "socket",
  SEARCH: "search",
} as const;

export const QUERY_KEYS = {
  AUTH: {
    ROOT: [ROOT_KEYS.AUTH],
    USER: [ROOT_KEYS.AUTH, "user"],
  },

  CHANNELS: {
    getChannelQueryKey: (channelId: string) => [ROOT_KEYS.CHANNELS, channelId],
    getSocketUrlQueryKey: (channelId: string) => [
      ROOT_KEYS.CHANNELS,
      channelId,
      "socket",
    ],
    getChannelMessagesQueryKey: (channelId: string) => [
      ROOT_KEYS.CHANNELS,
      channelId,
      "messages",
    ],
  },

  HISTORY: {
    ROOT: [ROOT_KEYS.HISTORY],

    getHistoryQueryKey: ({
      searchQuery = "",
      size = 50,
      page = 0,
    }: { searchQuery?: string; size?: number; page?: number }) => [
      ROOT_KEYS.HISTORY,
      searchQuery,
      size,
      page,
    ],
  },

  CONFIGS: {
    ROOT: [ROOT_KEYS.CONFIGS],
  },

  SUMMARY: {
    ROOT_YOUTUBE: [ROOT_KEYS.SUMMARY, ROOT_KEYS.YOUTUBE],
    getYoutubeResultQueryKey: (channelId: string) => [
      ROOT_KEYS.SUMMARY,
      ROOT_KEYS.YOUTUBE,
      channelId,
    ],
    getYoutubeChatQueryKey: (channelId: string) => [
      ROOT_KEYS.SUMMARY,
      ROOT_KEYS.YOUTUBE,
      channelId,
      "chat",
    ],
  },

  PERSONAS: {
    LIST: [ROOT_KEYS.PERSONAS, "list"],
    getQueryKeyByUser: (userId: string) => [ROOT_KEYS.PERSONAS, "list", userId],
  },

  SOCKET: {
    getChannelSocketQueryKey: (channelId: string) => [
      ROOT_KEYS.SOCKET,
      channelId,
    ],
  },

  SEARCH: {
    RECOMMENDATIONS: [ROOT_KEYS.SEARCH, "recommendations"],
    getCompletionQueryKey: (keyword: string) => [
      ROOT_KEYS.SEARCH,
      "completions",
      keyword,
    ],
  },
} as const;
