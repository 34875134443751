import { Button } from "~features/ui/button";
import styles from "./back-button.module.scss";

export interface BackButtonProps {
  onClick?: () => void;
}

const BackButton: React.FC<BackButtonProps> = (props) => {
  return (
    <Button
      {...props}
      overrideClass={styles.btn_back}
      onClick={props.onClick}
      size="large"
      ButtonType="onlyIcon"
      aria-label="back button"
      iconBefore={
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M23.5 12.5L16 20L23.5 27.5" stroke="black" strokeWidth="2" />
        </svg>
      }
    />
  );
};

export default BackButton;
