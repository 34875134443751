import FacebookIcon from "./images/img_share_facebook.png";
import KakaoTalkIcon from "./images/img_share_kakao.png";
import ThreadsIcon from "./images/img_share_threads.png";
import XIcon from "./images/img_share_x.png";
import type { ShareToKakaoTalkArgs } from "./share-to-kakao-talk";
import { getSharableUrl } from "./utils";

export enum SnsTypes {
  KAKAO = "kakao",
  FACEBOOK = "facebook",
  X = "x",
  THREAD = "thread",
}

type SnsShareButtonAttributes = {
  Icon: string;
  title: string;
  getUrl: (args?: ShareToKakaoTalkArgs) => string;
};

export const ATTRIBUTES_BY_SNS_TYPE: Record<
  SnsTypes,
  SnsShareButtonAttributes
> = {
  [SnsTypes.KAKAO]: {
    Icon: KakaoTalkIcon,
    title: "카카오톡",
    getUrl: (args) => {
      if (!args) return "";

      const url = new URL(
        window.location.origin.concat("/redirect/share/kakaotalk"),
      );
      url.searchParams.set("title", args.title);
      url.searchParams.set("description", args.description);
      url.searchParams.set("imageUrl", args.imageUrl);
      url.searchParams.set(
        "isYoutubePersona",
        args.isYoutubePersona.toString(),
      );
      url.searchParams.set("linkUrl", getSharableUrl());

      return url.toString();
    },
  },
  [SnsTypes.FACEBOOK]: {
    Icon: FacebookIcon,
    title: "페이스북",
    getUrl: () => {
      const url = new URL("https://www.facebook.com/sharer/sharer.php");
      url.searchParams.set("u", getSharableUrl());
      return url.toString();
    },
  },
  [SnsTypes.X]: {
    Icon: XIcon,
    title: "X",
    getUrl: () => {
      const url = new URL("https://twitter.com/intent/tweet");
      url.searchParams.set("url", getSharableUrl());
      return url.toString();
    },
  },
  [SnsTypes.THREAD]: {
    Icon: ThreadsIcon,
    title: "쓰레드",
    getUrl: () => {
      const url = new URL("https://threads.net/intent/post");
      url.searchParams.set("url", getSharableUrl());
      return url.toString();
    },
  },
} as const;
