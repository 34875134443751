import { create } from "zustand";

type FingerprintStore = {
  fingerprintId: string;
  setFingerprintId: (fingerprintId: string) => void;
};

export const useFingerprintStore = create<FingerprintStore>((set) => ({
  fingerprintId: "",
  setFingerprintId: (fingerprintId) => set({ fingerprintId }),
}));
