import { useParams } from "@tanstack/react-router";
import { type PropsWithChildren, useEffect } from "react";
import {
  LoginSuggestionMainModal,
  LoginSuggestionSidebarModal,
  useGuestModeStore,
} from "~features/guest-mode";
import { ToastsCoreLayout } from "~features/toast";
import { SideBar } from "~features/ui/side-bar";
import { MainLayout } from "~layouts/main";

type SearchLayoutProps = PropsWithChildren<{
  isHome?: boolean;
}>;

export function SearchLayout({ children, isHome = false }: SearchLayoutProps) {
  const { enabled } = useGuestModeStore();

  return (
    <>
      <SideBar />
      <MainLayout isHome={isHome}>{children}</MainLayout>
      <ToastsCoreLayout />
      {enabled && <GuestModeModals />}
    </>
  );
}

function GuestModeModals() {
  const { channelId = "" } = useParams({ strict: false });
  const { showMainModal, showSidebarModal, setShowSidebarModal } =
    useGuestModeStore();

  useEffect(() => {
    setShowSidebarModal(true);
  }, [setShowSidebarModal]);

  return (
    <>
      {showMainModal && <LoginSuggestionMainModal />}
      {showSidebarModal && <LoginSuggestionSidebarModal key={channelId} />}
    </>
  );
}
