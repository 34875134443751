import { useNavigate, useSearch } from "@tanstack/react-router";
import {
  type MutableRefObject,
  type PropsWithChildren,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from "react";
import { WithIntersectingMessagesContext } from "~features/messages/intersection";
import {
  useListScrollEvent,
  useListScrollEventContext,
} from "~features/messages/scroll";
import { WebSourcesContext } from "~features/messages/web-sources";
import { SideToc, type TocItemData, getTocItems } from "~features/ui/side-toc";
import { Status } from "~features/util-types/status";
import { cn } from "~utils/class-names";
import {
  FeedbackSelectedContext,
  SearchResult,
  SearchStreaming,
  WebSourceBottomSheet,
} from "./result";
import { useIsPendingContext } from "./use-is-pending.context";
import { useQuerySearch } from "./use-query-search";

import styles from "./result/result.module.scss";

type SearchResultsProps = PropsWithChildren<{
  channelId: string;
  scrollToChild: (tocItem: Omit<TocItemData, "title">) => void;
}>;

export const SearchResults = forwardRef<HTMLDivElement, SearchResultsProps>(
  function SearchResults({ channelId, scrollToChild }, ref) {
    const navigate = useNavigate();
    const { data: searchResults } = useQuerySearch(channelId);
    const { pendingItem: streamingMessage } = useIsPendingContext();
    const { setDistance } = useListScrollEventContext();

    const tocItems = useMemo(
      () => getTocItems(searchResults?.items ?? []),
      [searchResults],
    );
    const [selectedFeedbackId, setSelectedFeedbackId] = useState("");

    const webSources = useMemo(
      () =>
        new Map(
          searchResults?.items
            ?.flatMap(({ sources }) => sources?.items ?? [])
            .map((webSource) => [webSource.id, webSource]),
        ),
      [searchResults],
    );

    const notificationMessageId = useSearch({
      from: "/search/$channelId",
      select: ({ message_id = "" }) => message_id,
    });

    /**
     * @description 알림 진입시 scrolling
     * @todo Refactor - hook 분리
     */
    useEffect(() => {
      if (notificationMessageId.length === 0 || !searchResults) return;

      const targetMessageId =
        searchResults.items.find(
          (item) => item.messageId?.assistant === notificationMessageId,
        )?.messageId?.user ?? "";
      if (!targetMessageId) return;

      navigate({
        to: ".",
        search: {},
        replace: true,
      });

      window.setTimeout(() => {
        scrollToChild({ messageId: targetMessageId, options: {} });
        setDistance(-30);
      }, 300);
    }, [
      searchResults,
      notificationMessageId,
      scrollToChild,
      setDistance,
      navigate,
    ]);

    useListScrollEvent({
      scrollElementRef: ref as MutableRefObject<HTMLDivElement | null>,
    });

    return (
      <>
        <WithIntersectingMessagesContext>
          <div ref={ref} className={cn(styles.result_wrapper)}>
            <WebSourcesContext.Provider value={{ webSources }}>
              <FeedbackSelectedContext.Provider
                value={{
                  selected: selectedFeedbackId,
                  setSelected: setSelectedFeedbackId,
                }}
              >
                {searchResults?.items
                  ?.filter(
                    (item) =>
                      item.status !== Status.INIT &&
                      item.status !== Status.LOADING &&
                      item.status !== Status.PAUSED_BY_LENGTH,
                  )
                  .map((item) => (
                    <SearchResult key={`search-result-${item.id}`} {...item} />
                  ))}
              </FeedbackSelectedContext.Provider>

              {streamingMessage && (
                <SearchStreaming
                  channelId={channelId}
                  initSearch={streamingMessage}
                />
              )}
            </WebSourcesContext.Provider>
          </div>

          <SideToc items={tocItems} onClickTocItem={scrollToChild} />
        </WithIntersectingMessagesContext>

        <WebSourceBottomSheet />
      </>
    );
  },
);
