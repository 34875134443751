import {
  type UseQueryOptions,
  queryOptions,
  useQuery,
} from "@tanstack/react-query";
import { useMemo } from "react";
import { KyClient } from "~clients/fetch";
import { TIMES } from "~constants/times";
import { useUser } from "~features/auth";
import { QUERY_KEYS } from "~features/providers/tanstack-query";
import { type Channel, transformResponse } from "./channel.schema";

export const useQueryChannel = (channelId: string) => {
  const { user } = useUser();

  const queryOption = useMemo(
    () =>
      Object.assign(
        {},
        {
          enabled: channelId.length > 0 && user.id?.length > 0,
        } as UseQueryOptions<Channel>,
        getChannelQueryOptions({ channelId, userId: user.id ?? "" }),
      ),
    [channelId, user.id],
  );

  return useQuery(queryOption);
};

type GetChannelQueryOptionsArgs = {
  channelId: string;
  userId: string;
};

export const getChannelQueryOptions = ({
  channelId,
  userId,
}: GetChannelQueryOptionsArgs) =>
  queryOptions({
    queryKey: QUERY_KEYS.CHANNELS.getChannelQueryKey(channelId),
    queryFn: () =>
      fetchChannel(channelId).then((result) =>
        transformResponse(result, userId),
      ),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: TIMES.SEC * 5,
    retry: false,
  });

/**
 * example
 * -  @see {@link https://func08-api-estsoft-algpt-stage.azurewebsites.net/api/v1/channels/66d1332bb6af94b7ef74e61e}
 *
 * @todo
 * - 에러 핸들링 추가
 */
const fetchChannel = async (channelId: string) =>
  await KyClient.get(`channels/${channelId}`).json();
