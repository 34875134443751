import { queryOptions, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { KyClient } from "~clients/fetch";
import { TIMES } from "~constants/times";
import { useUser } from "~features/auth";
import { QUERY_KEYS } from "~features/providers/tanstack-query";
import { logger } from "~utils/logger";
import { parsePersonasResponse } from "./personas.schemas";
const fetchPersonas = async () =>
  KyClient.get("personas")
    .json()
    .catch((error) => {
      logger.error({
        scope: "useQueryPersonas.fetchPersonas.notKyHttpError",
        message: error.message,
        data: error,
      });

      return [];
    });

export const personasQueryOptions = queryOptions({
  queryKey: QUERY_KEYS.PERSONAS.LIST,
  queryFn: () => fetchPersonas().then(parsePersonasResponse),
  staleTime: TIMES.MIN * 10,
  refetchInterval: TIMES.MIN * 10,
  retry: false,
  placeholderData: [],
});

export const useQueryPersonas = () => {
  const { user } = useUser();
  const queryOption = useMemo(
    () =>
      Object.assign({}, personasQueryOptions, {
        queryKey: QUERY_KEYS.PERSONAS.getQueryKeyByUser(user.id ?? ""),
      }),
    [user.id],
  );

  return useQuery(queryOption);
};
