import type { PropsWithChildren } from "react";
import { AppDownloadButtons } from "~features/ui/app-download-button";
import { Footer } from "./footer";
import styles from "./home-page-footer.module.scss";

export function HomePageFooter() {
  return (
    <Footer className={styles.container}>
      <div className={styles.app_links}>
        <AppDownloadButtons isHome />
      </div>

      <div className={styles.info_links}>
        <FooterOutLink href="https://altools.co.kr/service/notice">
          공지사항
        </FooterOutLink>

        <FooterOutLink href="https://alan.estsoft.ai/">앨런 소개</FooterOutLink>
      </div>
    </Footer>
  );
}

function FooterOutLink({
  href,
  children,
}: PropsWithChildren<{ href: string }>) {
  return (
    <a href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
}
