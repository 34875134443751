import { type InferOutput, v } from "~libs/valibot";

export type SendMessageRequest = InferOutput<typeof SendMessageRequestSchema>;

const SendMessageRequestSchema = v.object({
  message: v.string(),
  channelId: v.string(),
  personaId: v.string(),
  userId: v.pipe(v.string(), v.nonEmpty()),
});

const SendMessageRequestSchema$Api = v.pipe(
  SendMessageRequestSchema,

  v.transform((input) => ({
    channel_id: input.channelId,
    content: input.message,
    user_id: input.userId,
    persona_id: input.personaId,
  })),
);

export const transformSendMessageRequest = (input: SendMessageRequest) => {
  const parseResult = v.safeParse(SendMessageRequestSchema$Api, input, {
    abortEarly: true,
  });
  if (!parseResult.success) {
    throw new Error(
      `[transformSendMessageRequests] Invalid arguments\n${parseResult.issues.toString()}`,
    );
  }

  return parseResult.output;
};

enum SendMessageResponseTypes {
  IN_MY_CHANNEL = "normal",
  CHANNEL_COPIED = "copy_channel",
}

const SendMessageResponseSchema = v.pipe(
  v.object({
    type: v.enum(SendMessageResponseTypes),
    data: v.object({
      new_channel_id: v.optional(v.string(), ""),
    }),
  }),

  v.transform((result) => ({
    type: result.type,
    isChannelCopied: result.type === SendMessageResponseTypes.CHANNEL_COPIED,
    copiedChannelId: result.data.new_channel_id,
  })),
);

export type SendMessageResponse = InferOutput<typeof SendMessageResponseSchema>;

export const transformSendMessageResponse = (input: unknown) => {
  const parseResult = v.safeParse(SendMessageResponseSchema, input, {
    abortEarly: true,
  });
  if (!parseResult.success) {
    throw new Error(
      `[transformSendMessageResponse] Invalid arguments\n${parseResult.issues.toString()}`,
    );
  }

  return parseResult.output;
};
