import BookmarkIcon from "~assets/images/ico_bookmark.png";
import Button from "~features/ui/button/button";
import styles from "./bookmark-button.module.scss";

export function BookmarkButton() {
  return (
    <Button
      overrideClass={styles.btn_bookmark}
      ButtonType="onlyIcon"
      size="medium"
      iconBefore={<img src={BookmarkIcon} alt="icon" />}
    />
  );
}
