import { type InferOutput, v } from "~libs/valibot";

/**
 * @example
  ```json
  {
    "type": "system",
    "event": "connected",
    "userId": null,
    "connectionId": "n_lItS2IyElSY9fQP48W1gRu7ObAw02"
  }    
  ```
 */
export type ConnectedSocketMessage = InferOutput<typeof Connected>;

const Connected = v.object({
  type: v.literal("system"),
  event: v.literal("connected"),
});

export const isConnectedMessage = (message: unknown) =>
  v.safeParse(Connected, message).success;

/**
 * @example
  ```json
  {
    "type": "ack",
    "ackId": 1721131109274,
    "success": true
  }
  ```
 */
export type AckSocketMessage = InferOutput<typeof Ack>;

const Ack = v.object({
  type: v.literal("ack"),
  success: v.literal(true),
});

export const isAckMessage = (message: unknown) =>
  v.safeParse(Ack, message).success;

export type SystemSocketMessages = ConnectedSocketMessage | AckSocketMessage;
