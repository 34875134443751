import { type Change, diffWords } from "diff";
import { createPortal } from "react-dom";
import XllmIcon from "~assets/images/ico_xllm.svg";
import modalStyles from "~features/modals/modal.module.scss";
import { cn } from "~utils/class-names";
import { useSanitizerContext } from "../sanitizer.context";
import styles from "./sanitizer-modal.module.scss";

const getTextClassName = (part: Change) => {
  if (part.removed) return styles.filtered_text;
  return "";
};

type SanitizerModalProps = {
  rawText: string;
  setRawText: (text: string) => void;
  sendPrompt: (prompt: string) => Promise<void>;
};

export function SanitizerModal({ rawText, sendPrompt }: SanitizerModalProps) {
  const { sanitizerResult, setIsModalOpen } = useSanitizerContext();

  const onClickApplySanitized = () => {
    sendPrompt(sanitizerResult?.sanitizedPrompt ?? "");
    setIsModalOpen(false);
  };

  const onClickApplyRaw = () => {
    sendPrompt(rawText);
    setIsModalOpen(false);
  };

  const onClickCancel = () => {
    setIsModalOpen(false);
  };

  const diffResult = diffWords(
    rawText,
    sanitizerResult?.sanitizedPrompt || "",
  ).filter((part) => !part.added);

  return createPortal(
    <div className={cn(modalStyles.modal_wrap, modalStyles.update)}>
      <div className={styles.modal}>
        <div className={styles.modal_contents}>
          <div className={styles.top_wrap}>
            <h3 className={styles.title}>xLLM 탐지</h3>
            <p>
              입력하신 내용에 중요한 정보가 포함됐어요!
              <br />
              익명 처리하여 검색하면 더 안전해요.
            </p>
          </div>

          <div className={styles.text_box}>
            <p>
              {diffResult.map((part, idx) => (
                <span
                  key={`${part.value}-${idx}`}
                  className={getTextClassName(part)}
                >
                  {part.value}
                </span>
              ))}
            </p>
          </div>
          <div className={styles.btn_wrap}>
            <button type="button" onClick={onClickApplyRaw}>
              <span>그대로 검색</span>
            </button>
            <button
              type="button"
              className={styles.black}
              onClick={onClickApplySanitized}
            >
              <span>익명 처리하여 검색</span>
            </button>
          </div>

          <button
            type="button"
            className={styles.btn_input_cancle}
            onClick={onClickCancel}
          >
            <span>입력 취소하기</span>
          </button>
        </div>

        <div className={cn(modalStyles.xllm_box, modalStyles.gray)}>
          <p className={modalStyles.text_p4}>
            LLM 정보 유출 차단은 &nbsp;
            <span className={modalStyles.ico_xllm}>
              <img src={XllmIcon} alt="AlYac xLLM" />
            </span>
            과 함께합니다.
          </p>
        </div>
      </div>
    </div>,
    document.body,
  );
}
