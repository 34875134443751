import { getFingerprint, setOption } from "@thumbmarkjs/thumbmarkjs";
import { logger } from "~utils/logger";

type GenerateFingerprintArgs = { includeData?: boolean } | undefined;

type GenerateFingerprintResult = {
  fpId: string;
  fpData?: unknown;
};

const EMPTY_RESULT: GenerateFingerprintResult = {
  fpId: "",
  fpData: undefined,
} as const;

export const generateFingerprint = async ({
  includeData = false,
}: GenerateFingerprintArgs = {}): Promise<GenerateFingerprintResult> => {
  const stored = checkLocalStorage();
  if (stored?.id && stored.id.length > 0) {
    return {
      fpId: stored.id,
      fpData: undefined,
    };
  }

  setOption("timeout", 3_000);
  setOption("logging", false);

  if (!includeData) {
    return getFingerprint(false)
      .then((fpId) => {
        saveLocalStorage(fpId);

        return { fpId, fpData: undefined };
      })
      .catch((error) => {
        logger.error({
          scope: "generateFingerprint",
          message: error.message,
          stack: error.stack,
        });

        return EMPTY_RESULT;
      });
  }

  return getFingerprint(true)
    .then(({ hash, data }) => {
      saveLocalStorage(hash);

      return {
        fpId: hash,
        fpData: data,
      };
    })
    .catch((error) => {
      logger.error({
        scope: "generateFingerprint",
        message: error.message,
        stack: error.stack,
      });

      return EMPTY_RESULT;
    });
};

const FINGERPRINT_STORAGE_KEY = "alan.guest";

const checkLocalStorage = () => {
  try {
    const stored = window.localStorage.getItem(FINGERPRINT_STORAGE_KEY);
    if (!stored) return undefined;

    return JSON.parse(stored) as { id: string };
  } catch (error) {
    logger.error({
      scope: "checkLocalStorage",
      data: error as unknown as Error,
    });

    return undefined;
  }
};

const saveLocalStorage = (fpId: string) => {
  try {
    window.localStorage.setItem(
      FINGERPRINT_STORAGE_KEY,
      JSON.stringify({ id: fpId }),
    );
  } catch (error) {
    logger.error({
      scope: "saveLocalStorage",
      data: error as unknown as Error,
    });
  }
};
