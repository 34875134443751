import { type InferOutput, v } from "~libs/valibot";

export type Persona = {
  id: string;
  name: string;
  theme: string;
  description: string;
};

/**
 * @example
 * @see {@link /mocks/handlers/personas.json}
 */
export type PersonasResponse = InferOutput<typeof PersonasSchema$Response>;
const PersonasSchema$Response = v.object({
  personas: v.array(
    v.object({
      _id: v.string(),
      visible: v.optional(v.boolean()),
      theme: v.string(),
      name: v.string(),
      description: v.string(),
      // type: v.string(),
      // avatar: v.optional(v.string()),
      // suggestedQuestions: v.array(v.string()),
    }),
  ),
  total: v.number(),
});

const PersonasSchema = v.pipe(
  PersonasSchema$Response,

  v.transform((response) =>
    response.personas
      .filter((persona) => persona.visible ?? true)
      .map(
        (persona): Persona => ({
          id: persona._id,
          name: persona.name,
          theme: persona.theme,
          description: persona.description,
        }),
      ),
  ),
);

export const parsePersonasResponse = (response: unknown) =>
  v.parse(PersonasSchema, response, { abortEarly: true });
