import { TanStackQueryProvider } from "./tanstack-query";
import { TanStackRouterProvider } from "./tanstack-router";

export function Providers() {
  return (
    <>
      <TanStackQueryProvider>
        <TanStackRouterProvider />
      </TanStackQueryProvider>
    </>
  );
}
