import { type InferOutput, v } from "~libs/valibot";
import { logger } from "~utils/logger";
import { guestModeApiFetcher } from "../fetcher";

const EnrollDeviceSchema = v.object({
  fingerprintId: v.string(),
});

const EnrollDeviceSchema$Request = v.pipe(
  EnrollDeviceSchema,
  v.transform((input) => ({
    device_id: input.fingerprintId,
    platform: "web",
    version: import.meta.env.VITE_VERSION,
  })),
);

type EnrollDeviceRequest = InferOutput<typeof EnrollDeviceSchema>;

const transformRequest = (input: EnrollDeviceRequest) => {
  const result = v.safeParse(EnrollDeviceSchema$Request, input);
  if (!result.success) {
    throw new Error("Invalid request");
  }
  return result.output;
};

export const enrollDevice = async (enrollDeviceRequest: EnrollDeviceRequest) =>
  guestModeApiFetcher
    .post("device-info", {
      json: transformRequest(enrollDeviceRequest),
    })
    .then((res) => ({ isOk: res.ok }))
    .catch((error) => {
      logger.error({
        scope: "enrollDevice",
        message: error.message,
        stack: error.stack,
      });

      return { isOk: false };
    });
