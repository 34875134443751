import { useMutation } from "@tanstack/react-query";
import { logger } from "~utils/logger";
import { xllmClient } from "./fetcher";
import { transformResponse } from "./schemas";

const fetchHealthCheck = async () => {
  try {
    const response = await xllmClient.get("sanitizer/health_check");
    if (!response.ok) {
      throw new Error("API response not ok");
    }

    return { isOk: true };
  } catch (e) {
    logger.error({
      scope: "[Xllm.Api.fetchHealthCheck]",
      data: e as unknown as Error,
    });

    return { isOk: false };
  }
};

const sanitizePrompt = async (prompt: string) => {
  try {
    const { isOk } = await fetchHealthCheck();
    if (!isOk) {
      return { isSuccess: false };
    }

    const response = await xllmClient
      .post("sanitizer/prompt", {
        json: {
          prompt,
          config: [],
        },
      })
      .json()
      .then(transformResponse);

    return { isSuccess: true, data: response };
  } catch (e) {
    logger.error({
      scope: "[Xllm.Api.sanitizePrompt]",
      data: e as unknown as Error,
    });
    return { isSuccess: false, error: e };
  }
};

export const useMutationSanitizePrompt = () => {
  return useMutation({
    mutationFn: sanitizePrompt,
  });
};
