import { createFileRoute, redirect, useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";
import { useUser } from "~features/auth";
import { useQueryChannel } from "~features/channel";
import { useGuestModeSetup } from "~features/guest-mode";
import { usePersonas } from "~features/personas";

export const Route = createFileRoute("/channels/$channelId")({
  component: Page,
  beforeLoad: async ({ params }) => {
    if (params.channelId === "new") {
      throw redirect({ to: "/", search: (prev) => prev });
    }
  },
});

function Page() {
  const { channelId } = Route.useParams();

  const { isLoggedIn } = useUser();
  useGuestModeSetup({ isLoggedIn });

  const { isSuccess: isChannelQuerySuccess, data: channel } =
    useQueryChannel(channelId);
  const { isSuccess: isPersonasQuerySuccess, youtubePersonaId } = usePersonas();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isChannelQuerySuccess || !isPersonasQuerySuccess) {
      return;
    }

    navigate({
      to:
        youtubePersonaId === channel.personaId
          ? "/summary/youtube/$channelId"
          : "/search/$channelId",
      params: { channelId },
      search: (prev) => prev,
    });
  }, [
    channelId,
    isChannelQuerySuccess,
    channel?.personaId,
    isPersonasQuerySuccess,
    youtubePersonaId,
    navigate,
  ]);

  return <></>;
}
