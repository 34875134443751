import { match } from "ts-pattern";
import type { User } from "~features/auth";

type Request = {
  prompt: string;
  channelId: string;
  personaId: string;
  user: User;
};

export enum ResultActions {
  VALID = "VALID",
  PROMPT_EMPTY = "PROMPT_EMPTY",
  CHANNEL_ID_EMPTY = "CHANNEL_ID_EMPTY",
  PERSONA_ID_EMPTY = "PERSONA_ID_EMPTY",
  USER_ID_EMPTY = "USER_ID_EMPTY",
  USER_DISABLED = "USER_DISABLED",
  USER_WAITING = "USER_WAITING",
  USER_USAGE_LIMIT_EXCEEDED = "USER_USAGE_LIMIT_EXCEEDED",
}

export type Result = {
  isValid: boolean;
  action: ResultActions;
};

const validateRequestCore = <
  RequestExtend extends Pick<Request, "prompt" | "user">,
>(
  request: RequestExtend,
) =>
  match(request)
    .when(isEmptyPrompt, () => ({
      isValid: false,
      action: ResultActions.PROMPT_EMPTY,
    }))
    .when(isEmptyUserId, () => ({
      isValid: false,
      action: ResultActions.USER_ID_EMPTY,
    }))
    .when(isUserDisabled, () => ({
      isValid: false,
      action: ResultActions.USER_DISABLED,
    }))
    .when(isUserWaiting, () => ({
      isValid: false,
      action: ResultActions.USER_WAITING,
    }))
    .when(isUserUsageLimitExceeded, () => ({
      isValid: false,
      action: ResultActions.USER_USAGE_LIMIT_EXCEEDED,
    }));

export const validateRequestWithNewChannel = (
  request: Pick<Request, "prompt" | "user">,
): Result =>
  validateRequestCore(request).otherwise(() => ({
    isValid: true,
    action: ResultActions.VALID,
  }));

export const validateRequestInChannel = (request: Request): Result =>
  validateRequestCore(request)
    .when(isEmptyChannelId, () => ({
      isValid: false,
      action: ResultActions.CHANNEL_ID_EMPTY,
    }))
    .when(isEmptyPersonaId, () => ({
      isValid: false,
      action: ResultActions.PERSONA_ID_EMPTY,
    }))
    .otherwise(() => ({
      isValid: true,
      action: ResultActions.VALID,
    }));

const isEmptyPrompt = ({ prompt }: Pick<Request, "prompt">) =>
  prompt.trim().length === 0;

const isEmptyChannelId = ({ channelId }: Pick<Request, "channelId">) =>
  channelId.length === 0;

const isEmptyPersonaId = ({ personaId }: Pick<Request, "personaId">) =>
  personaId.length === 0;

const isEmptyUserId = ({ user }: Pick<Request, "user">) => user.id.length === 0;

const isUserDisabled = ({ user }: Pick<Request, "user">) => !user.enabled;

const isUserWaiting = ({ user }: Pick<Request, "user">) => user.waiting;

const isUserUsageLimitExceeded = ({ user }: Pick<Request, "user">) =>
  user.usageCount >= user.usageLimit;
