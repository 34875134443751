import {
  type FormEvent,
  type MouseEvent,
  type MouseEventHandler,
  useRef,
  useState,
} from "react";
import {
  useMutationSendFeedbackMessage,
  useQueryRatingMessages,
} from "~features/feedback";
import { Button } from "~features/ui/button";
import { createEventHandler } from "~utils/create-event-handler";

const FEEDBACK_MESSAGE_PLACEHOLDER =
  "더 좋은 답변을 드릴 수 있도록 아쉬운 이유를 알려주세요! (선택 사항)";

const FORM_TITLE = "어떤 점이 마음에 들지 않으셨나요?";

const FEEDBACK_SUBMITTED_MESSAGE = "소중한 의견을 제공해 주셔서 감사합니다!";

const FORM_INPUT_NAMES = {
  REASON: "feedback-reason",
  MESSAGE: "feedback-message",
} as const;

type FeedbackFormProps = {
  messageId: string;
  closeForm: () => void;
  onSubmit?: (e: MouseEvent<HTMLButtonElement>) => void;
};

export function FeedbackForm({
  messageId,
  closeForm,
  onSubmit,
}: FeedbackFormProps) {
  {
    const { data: ratingReasons } = useQueryRatingMessages();

    const formRef = useRef<HTMLFormElement | null>(null);

    const sendFeedbackMutation = useMutationSendFeedbackMessage();
    const [submitAvailable, setSubmitAvailable] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const onClickCloseButton = createEventHandler<MouseEvent>({
      handler: () => {
        closeForm();
      },
    });

    const onChangeForm = createEventHandler<FormEvent>({
      preventDefault: false,
      handler: () => {
        if (!formRef.current) return;
        const formData = new FormData(formRef.current);
        const isValidFormData = formData.has(FORM_INPUT_NAMES.REASON);
        setSubmitAvailable(isValidFormData);
      },
    });

    const onClickSubmitButton = createEventHandler<MouseEvent>({
      handler: () => {
        submitFeedback();
      },
    });

    const onSubmit = createEventHandler<FormEvent>({
      handler: (e) => {
        e.preventDefault();
        submitFeedback();
      },
    });

    const submitFeedback = async () => {
      if (!formRef.current) return;
      const formData = new FormData(formRef.current);
      sendFeedbackMutation.mutateAsync({
        messageId,
        reasonsIds: formData.getAll(FORM_INPUT_NAMES.REASON) as string[],
        feedbackMessage: formData.get(FORM_INPUT_NAMES.MESSAGE) as string,
      });

      setSubmitted(true);
    };

    return (
      <form
        ref={formRef}
        className="flex relative flex-col gap-4 p-8 rounded-lg border border-solid border-alan-gray-2"
        onChange={onChangeForm}
        onSubmit={onSubmit}
      >
        <CloseButton onClick={onClickCloseButton} />

        <h4 className="text-base font-bold text-alan-black-1">
          {submitted ? FEEDBACK_SUBMITTED_MESSAGE : FORM_TITLE}
        </h4>

        {!submitted && (
          <>
            <ul className="flex flex-col gap-2">
              {ratingReasons
                ?.filter((r) => r.enabled)
                .map((reason) => {
                  const htmlId = `feedback-${messageId}-reason-${reason._id}`;
                  return (
                    <li key={htmlId} className="flex gap-2 items-center">
                      <input
                        id={htmlId}
                        type="checkbox"
                        name={FORM_INPUT_NAMES.REASON}
                        value={reason._id}
                      />
                      <label htmlFor={htmlId}>{reason.message}</label>
                    </li>
                  );
                })}
            </ul>

            <textarea
              placeholder={FEEDBACK_MESSAGE_PLACEHOLDER}
              name={FORM_INPUT_NAMES.MESSAGE}
              rows={2}
              aria-label="feedback message"
              className="p-3 text-sm font-normal rounded-lg border border-solid border-alan-gray-2 bg-alan-gray-4 placeholder:text-alan-black-5"
            />

            <div className="flex justify-end w-full">
              <SubmitButton
                submitAvailable={submitAvailable}
                onClick={onClickSubmitButton}
                className={""}
              />
            </div>
          </>
        )}
      </form>
    );
  }
}

const CloseButton: React.FC<{ onClick?: React.MouseEventHandler }> = ({
  onClick,
}) => {
  return (
    <Button
      overrideClass={"absolute top-3 right-3"}
      ButtonType="onlyIcon"
      aria-label="close button"
      iconBefore={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path d="M1 15L15 1" stroke="#1A212C" strokeWidth="1.5" />
          <path d="M1 0.999999L15 15" stroke="#1A212C" strokeWidth="1.5" />
        </svg>
      }
      onClick={onClick}
    />
  );
};

type SubmitButtonProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  submitAvailable: boolean;
  className?: string;
};

const SubmitButton: React.FC<SubmitButtonProps> = ({
  onClick,
  submitAvailable,
  className,
}) => {
  return (
    <Button
      onClick={onClick}
      overrideClass={className}
      color={"black"}
      isDisabled={!submitAvailable}
      size="medium"
      aria-label="submit button"
      text="제출"
    />
  );
};
