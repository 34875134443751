import type { ComponentProps, PropsWithChildren } from "react";
import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";
import styles from "./card.module.scss";

type CardProps<C extends keyof JSX.IntrinsicElements = "li"> =
  ComponentProps<C> &
    PropsWithChildren<{
      onClick?: () => void;
      className?: string;
      as?: C;
    }> &
    React.ComponentPropsWithoutRef<"li">;

export function Card({
  as = "li",
  className,
  children,
  onClick,
  ...props
}: CardProps) {
  const Tag = as;
  const _onClick = createEventHandler({
    handler: () => {
      onClick?.();
    },
  });

  return (
    <Tag className={cn(styles.card, className)} onClick={_onClick} {...props}>
      {children}
    </Tag>
  );
}
