import { type ToastOptions, toast } from "react-toastify";
import { cn } from "~utils/class-names";
import { CONTAINER_IDS, TOAST_IDS } from "./constants";
import styles from "./toast-info.module.scss";

type SetToastArgs = {
  containerId: string;
  toastId?: string;
  className?: string;
};

export const createInfoToast = ({
  containerId,
  toastId,
  className = "",
}: SetToastArgs) => {
  const options: ToastOptions = {
    containerId,
    toastId,
    className,
  };

  return (message: string) => {
    toast.info(message, options);
  };
};

export const setDefaultInfoToast = createInfoToast({
  containerId: CONTAINER_IDS.BOTTOM,
  toastId: TOAST_IDS.COPY,
  className: cn(styles.info, styles.text_center),
});

export const setChatMessageCopyToast = createInfoToast({
  containerId: CONTAINER_IDS.BOTTOM,
  toastId: TOAST_IDS.COPY,
  className: cn(styles.info, styles.text_center, styles.copy, styles.youtube),
});

export const DEFAULT_MESSAGES = {
  COPY: "복사되었습니다.",
};
