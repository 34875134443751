import { type InferOutput, v } from "~libs/valibot";

/**
 * @example
 * ```json
 * {
     histories: [
      {
        "_id": "671736a50270a101f7090d64",
        "channel_id": "671736a50270a101f7090d63",
        "user_id": "65af73b6583a64a91bd6e002",
        "persona_id": "648092711ec61303770dc726",
        "title": "자전거 대여앱 요구 사항 정의서",
        "tags": ["자전거_대여","회원_가입","결제_시스템","푸시_알림","데이터_암호화"],
        "createdAt": "2024-10-22T05:22:45.585000",
        "updatedAt": "2024-10-22T05:22:45.585000"
      }
    ],
    total: 1
  }
 * ```
 */
export type HistoryResponse = InferOutput<typeof HistorySchema$Response>;

const HistorySchema$Response = v.object({
  histories: v.array(
    v.object({
      _id: v.string(),
      channel_id: v.string(),
      // user_id: v.string(),
      persona_id: v.string(),
      title: v.nullable(v.string(), ""),
      tags: v.nullable(v.array(v.string()), []),
      updatedAt: v.string(),
      content: v.nullable(v.string(), ""),
      shared: v.optional(v.boolean(), false),
    }),
  ),
  total: v.number(),
});

export type History = InferOutput<typeof HistorySchema>;
export type HistoryItems = History["items"];
export type HistoryItem = History["items"][number];

const HistorySchema = v.pipe(
  HistorySchema$Response,

  v.transform((historyResponse) => {
    return {
      items: historyResponse.histories
        .map((history) => ({
          id: history._id,
          channelId: history.channel_id,
          personaId: history.persona_id,
          title: history.title,
          content: history.content.slice(0, 300),
          tags: history.tags,
          updatedDateTime: getUpdateDateTime(history.updatedAt),
          updatedOriginal: history.updatedAt,
          isShared: history.shared,
        }))
        .sort((a, b) => {
          if (
            a.updatedDateTime === undefined ||
            b.updatedDateTime === undefined
          ) {
            return 0;
          }
          return b.updatedDateTime.getTime() - a.updatedDateTime.getTime();
        }),
      total: historyResponse.total,
    };
  }),
);

const getUpdateDateTime = (updatedAt: string) => {
  try {
    return new Date(updatedAt);
  } catch (error) {
    /** @todo 가능성은 거의 없지만 확인 필요 */
    console.error(
      `[getUpdateDateTime.parseError] original: ${updatedAt}\n`,
      error,
    );
    return new Date(0);
  }
};

export const parseHistoryResponse = (response: unknown) => {
  try {
    return v.parse(HistorySchema, response, { abortEarly: true });
  } catch (error) {
    console.error("[parseHistoryResponse]", error);
    return { items: [], total: 0 };
  }
};
