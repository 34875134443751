import { default as TeX } from "@matejmazur/react-katex";
import type { PropsWithChildren } from "react";

import "katex/dist/katex.min.css";
import styles from "./paragraph.module.scss";

type MarkdownParagraphProps = PropsWithChildren;

export function MarkdownParagraph(props: MarkdownParagraphProps) {
  if (Array.isArray(props.children)) {
    return (
      <div className={styles.p}>
        {props.children.map((child, i) => (
          <MarkdownParagraph key={i}>{child}</MarkdownParagraph>
        ))}
      </div>
    );
  }

  if (typeof props.children === "string" && texRegex.test(props.children)) {
    return props.children
      .match(texRegex)
      ?.map((matched) => (
        <MarkdownTex key={`markdown-p-${matched}`} text={matched} />
      ));
  }

  return props.children;
}

const texRegex = /\[ (.*?) \]/g;

function MarkdownTex({ text }: { text: string }) {
  return <TeX>{text}</TeX>;
}
