import { type PropsWithChildren, useMemo } from "react";
import { usePersonas } from "~features/personas";
import { useIsPendingContext } from "~features/search";
import {
  DeleteButton,
  SendingButton,
  SttMicButton,
} from "~features/ui/prompt/buttons";
import { xllmHooks } from "~features/xllm";
import { useWindowWidth } from "~hooks/use-window-width";
import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";
import SelectedModel from "./ai-model/ai-model";
import { usePromptContext } from "./context";
import { useRawTextContext } from "./raw-text.context";

import styles from "./buttons.module.scss";

type PromptButtonsProps = PropsWithChildren<{
  className?: string;
  ishideModel?: boolean;
}>;

export function PromptButtons({
  className,
  ishideModel,
  children,
}: PromptButtonsProps) {
  const rawTextContext = useRawTextContext();
  const promptContext = usePromptContext();
  const isPendingContext = useIsPendingContext();
  const { isMobileSmallSize: isMobileSize } = useWindowWidth();

  const { persona, searchR1Id } = usePersonas();
  const { checkSanitizer, isPendingSanitizer } =
    xllmHooks.useSanitizerContext();

  const onClickDeleteButton = createEventHandler({
    handler: () => {
      rawTextContext.setRawText("");
      promptContext.textareaRef.current?.focus();
    },
    stopPropagation: false,
  });

  const onClickSubmitButton = async () => {
    if (rawTextContext.rawText.length > 0 && persona.id === searchR1Id) {
      const { isOk } = await checkSanitizer(rawTextContext.rawText);
      if (!isOk) {
        return;
      }
    }

    promptContext.sendPrompt();
  };

  const isPending = useMemo(
    () => isPendingContext.isPending || isPendingSanitizer,
    [isPendingContext.isPending, isPendingSanitizer],
  );

  return isMobileSize ? (
    ishideModel ? (
      <div className={cn(styles.buttons, className)}>
        {rawTextContext.rawText.length > 0 && (
          <DeleteButton onClick={onClickDeleteButton} />
        )}

        {promptContext.isSttEnabled && <SttMicButton />}

        {children ?? (
          <SendingButton
            shape={isPending ? "stop" : "default"}
            onSubmit={onClickSubmitButton}
            onClick={promptContext.cancelPrompt}
          />
        )}
      </div>
    ) : (
      <div className={styles.buttons_wrap}>
        <div className={styles.model_wrap}>
          <SelectedModel />
        </div>

        <div className={cn(styles.buttons, className)}>
          {rawTextContext.rawText.length > 0 && (
            <DeleteButton onClick={onClickDeleteButton} />
          )}

          {promptContext.isSttEnabled && <SttMicButton />}

          {children ?? (
            <SendingButton
              shape={isPending ? "stop" : "default"}
              onSubmit={onClickSubmitButton}
              onClick={promptContext.cancelPrompt}
            />
          )}
        </div>
      </div>
    )
  ) : (
    <div className={cn(styles.buttons, className)}>
      {rawTextContext.rawText.length > 0 && (
        <DeleteButton onClick={onClickDeleteButton} />
      )}

      {!ishideModel && (
        <div className={styles.model_wrap}>
          <SelectedModel />
        </div>
      )}

      {promptContext.isSttEnabled && <SttMicButton />}

      {children ?? (
        <SendingButton
          shape={isPending ? "stop" : "default"}
          onSubmit={onClickSubmitButton}
          onClick={promptContext.cancelPrompt}
        />
      )}
    </div>
  );
}
