import type { MouseEvent, PropsWithChildren } from "react";
import styles from "./background.module.scss";

type BackgroundProps = PropsWithChildren<{
  closeBottomSheet: (e: MouseEvent) => void;
}>;

export function Background({ children, closeBottomSheet }: BackgroundProps) {
  return (
    <section className={styles.background} onClick={closeBottomSheet}>
      {children}
    </section>
  );
}
