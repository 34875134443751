import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useCallback } from "react";
import { match } from "ts-pattern";
import type { User } from "~features/auth";
import {
  type CreateChannelIdArgs,
  useMutationCreateChannelId,
} from "~features/channel";
import { usePersonas } from "~features/personas";
import { QUERY_KEYS } from "~features/providers/tanstack-query";
import type { SearchResult } from "~features/search/schemas";
import { Status } from "~features/util-types/status";
import type { YoutubeSummary } from "~features/youtube-summary/summary-result";
import { SUMMARY_RESULT_START } from "~features/youtube-summary/summary-result/youtube-summary.schema";
import type { CategorizedPrompt } from "../utils/categorize-prompt";
import { sendMessage } from "./send-message";
import { sendYoutubeUrl } from "./send-youtube-url";

export const useMutationSendPromptWithNewChannel = (user: User) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      prompt,
      personaId,
      channelId,
      isForYoutubeSummary,
    }: AfterNavigateArgs & {
      prompt: string;
      youtubeId?: string;
    }) => {
      if (isForYoutubeSummary) {
        return sendYoutubeUrl({ channelId, url: prompt });
      }

      return sendMessage({
        message: prompt,
        userId: user.id,
        channelId,
        personaId,
      });
    },

    onMutate: async ({
      prompt,
      channelId,
      youtubeId = "",
      isForYoutubeSummary,
    }) => {
      const queryKey = isForYoutubeSummary
        ? QUERY_KEYS.SUMMARY.getYoutubeResultQueryKey(channelId)
        : QUERY_KEYS.CHANNELS.getChannelMessagesQueryKey(channelId);

      const historyQueryKey = QUERY_KEYS.HISTORY.getHistoryQueryKey({
        page: 0,
      });

      await queryClient.cancelQueries({ queryKey });
      await queryClient.cancelQueries({ queryKey: historyQueryKey });

      queryClient.setQueryData<SearchResult.Page | YoutubeSummary>(
        queryKey,
        () => {
          if (isForYoutubeSummary) {
            return Object.assign({}, SUMMARY_RESULT_START, {
              video: { url: prompt, id: youtubeId },
            } as YoutubeSummary);
          }

          /** @todo 생성 함수 */
          return {
            items: [
              {
                id: "",
                requestId: "",
                messageId: { user: "", assistant: "" },
                status: Status.INIT,
                title: prompt,
                content: "",
                toolSteps: [
                  {
                    order: 0,
                    speak: "질문의 의도를 이해하고 있어요.",
                    status: Status.LOADING,
                  },
                ],
              },
            ],
            total: 1,
          } as SearchResult.Page;
        },
      );

      /** @todo 왜 여기서 에러? */
      // queryClient.setQueryData<History>(historyQueryKey, (prevHistory) => {
      //   const newHistory = cloneObject(prevHistory ?? { items: [], total: 0 });

      //   console.debug("prevHistory", prevHistory);
      //   console.debug("newHistory", newHistory);

      //   newHistory.items = [
      //     createNewHistory(channelId, personaId, new Date()),
      //   ].concat(newHistory.items);
      //   newHistory.total += 1;

      //   return newHistory;
      // });

      return {
        queryKey,
        historyQueryKey,
      };
    },

    onSettled: (_response, _result, _args, context) => {
      if (!context) return;
      queryClient.refetchQueries({ queryKey: QUERY_KEYS.AUTH.USER });
      queryClient.refetchQueries({ queryKey: context.queryKey });
      queryClient.refetchQueries({ queryKey: context.historyQueryKey });
    },
  });
};

type CreateChannelCallbackArgs = CreateChannelIdArgs & {
  isForYoutubeSummary: boolean;
};

type AfterNavigateArgs = {
  channelId: string;
} & CreateChannelCallbackArgs;

export const useCreateChannelAndNavigate = () => {
  const createChannelMutation = useMutationCreateChannelId();
  const navigate = useNavigate();
  const { youtubePersonaId, searchDefaultId } = usePersonas();

  const createChannelAndNavigate = useCallback(
    async ({
      personaId,
      xllmEnabled,
      isForYoutubeSummary,
    }: CreateChannelCallbackArgs) => {
      const navigateTo = isForYoutubeSummary
        ? "/summary/youtube/$channelId"
        : "/search/$channelId";

      /** @todo error handling */
      return createChannelMutation
        .mutateAsync({
          personaId: isForYoutubeSummary ? youtubePersonaId : personaId,
          xllmEnabled,
        })
        .then(({ channelId }) =>
          navigate({ to: navigateTo, params: { channelId } }).then(() => ({
            channelId,
            personaId,
            isForYoutubeSummary,
          })),
        );
    },
    [createChannelMutation, navigate, youtubePersonaId],
  );

  const getCreateChannelArgs = useCallback(
    ({
      personaId,
      hasYoutubeUrl,
      hasUrl,
      xllmEnabled = false,
    }: CreateChannelIdArgs &
      Pick<
        CategorizedPrompt,
        "hasYoutubeUrl" | "hasUrl"
      >): CreateChannelCallbackArgs =>
      match({ hasYoutubeUrl, hasUrl })
        .with({ hasYoutubeUrl: true }, () => ({
          personaId: youtubePersonaId,
          isForYoutubeSummary: true,
          xllmEnabled: false,
        }))
        .with({ hasUrl: true }, () => ({
          personaId: searchDefaultId,
          isForYoutubeSummary: false,
          xllmEnabled: false,
        }))
        .otherwise(() => ({
          personaId,
          isForYoutubeSummary: false,
          xllmEnabled,
        })),
    [youtubePersonaId, searchDefaultId],
  );

  return {
    createChannelAndNavigate,
    getCreateChannelArgs,
  };
};
