import { createFileRoute } from "@tanstack/react-router";
import { useNativeBridge } from "~clients/native-bridge";
import { EMPTY_USER, userQueryOptions } from "~features/auth";
import { useUserAgent } from "~features/user-agent";
import { TabsSearchSchema } from "~features/youtube-summary/tabs";
import { v } from "~libs/valibot";
import { logger } from "~utils/logger";

const validateSearch = v.object({
  message_id: v.optional(v.string()),
  ...TabsSearchSchema.entries,
});

export const Route = createFileRoute("/summary/youtube/$channelId")({
  validateSearch,

  beforeLoad: async ({ context: { queryClient } }) => {
    if (
      !useUserAgent.getState().isWeb &&
      !useNativeBridge.getState().nativeBridge
    )
      return { isLoggedIn: false, userId: "" };

    return await queryClient
      .ensureQueryData(userQueryOptions)
      .catch((error) => {
        if (error instanceof Error) {
          logger.error({
            scope: "SummaryYoutubeChannelRoute.beforeLoad.userQuery",
            message: error.message,
            stack: error.stack,
          });
        }

        return EMPTY_USER;
      })
      .then((result) => {
        const userId = result?.id ?? "";
        return { isLoggedIn: userId.length > 0, userId };
      });
  },

  onError: (error) => {
    logger.error({
      scope: "SummaryYoutubeChannelRoute.onError",
      message: error.message,
      stack: error.stack,
    });
  },
});
