import { createFileRoute, redirect } from "@tanstack/react-router";
import { useUserAgent } from "~features/user-agent";
import { v } from "~libs/valibot";

const searchParamsSchema = v.object({
  q: v.optional(v.string()),
  /** personaId */
  pid: v.optional(v.string()),
});

export const Route = createFileRoute("/search/")({
  component: () => <></>,
  validateSearch: searchParamsSchema,
  beforeLoad: async ({ search }) => {
    if (!search.q || !useUserAgent.getState().isWeb) {
      throw redirect({
        to: "/",
        search: (prev: Record<string, string | number | boolean>) => {
          const copied = Object.entries(prev).filter(([key]) => key !== "q");
          return Object.fromEntries(copied);
        },
      });
    }
  },
});
