import { useLocation } from "@tanstack/react-router";
import { match } from "ts-pattern";
import {
  ErrorPopup,
  NoticeModal,
  UpdateModal,
  useErrorInfo,
  useModal,
  useModalVisible,
} from "~features/modals";
import { ShareImage } from "~features/modals/images";

export function ModalsCoreLayout() {
  const { hasModalContent } = useModal();
  const { isModalVisible, isModalVisibleInSession, updateModalVisible } =
    useModalVisible();
  const { hasError } = useErrorInfo();

  const { pathname } = useLocation();

  return match({
    hasError,
    hasModalContent,
    isModalVisible,
    isModalVisibleInSession,
  })
    .with({ hasError: true }, () => <ErrorPopup />)
    .with({ hasModalContent: true }, () => <NoticeModal />)
    .with(
      { isModalVisible: true, isModalVisibleInSession: true },
      () =>
        !NO_MODAL_PATHS.has(pathname) && (
          <UpdateModal
            title="검색 결과 공유 기능 업데이트"
            details={
              "검색 결과를 다른 사람에게 손 쉽게 공유하고,\n공유 받은 검색 결과를 내 검색 기록에 추가할 수 있어요 🚀"
            }
            isTodayCloseBtn
            img={ShareImage}
            imgWidthSize="274px"
            onClickConfirm={() => {
              updateModalVisible(false, { useStorage: false });
            }}
          />
        ),
    )
    .otherwise(() => null);
}

const NO_MODAL_PATHS = new Set([
  "/redirect/share/kakaotalk",
  "/maintenance",
  "/onboarding/win",
]);
