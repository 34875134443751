import { type InferOutput, v } from "~libs/valibot";
import { logger } from "~utils/logger";
import { guestModeApiFetcher } from "../fetcher";

const GetAccessTokenSchema = v.object({
  fingerprintId: v.string(),
});

const GetAccessTokenSchema$Request = v.pipe(
  GetAccessTokenSchema,
  v.transform((input) => {
    const searchParams = new URLSearchParams();
    searchParams.set("client_id", input.fingerprintId);
    searchParams.set("grant_type", "implicit_grant");

    return searchParams;
  }),
);

type GetAccessTokenRequest = InferOutput<typeof GetAccessTokenSchema>;

const transformRequest = (input: GetAccessTokenRequest) => {
  const result = v.safeParse(GetAccessTokenSchema$Request, input);
  if (!result.success) {
    throw new Error("Invalid request");
  }
  return result.output;
};

const AccessTokenSchema$Response = v.object({
  access_token: v.string(),
  token_type: v.optional(v.string(), "bearer"),
  expires_in: v.union([v.number(), v.string()]),
});

const AccessTokenSchema = v.pipe(
  AccessTokenSchema$Response,
  v.transform((input) => {
    return {
      accessToken: input.access_token,
      tokenType: input.token_type,
      expiresIn: Number(input.expires_in),
    };
  }),
);

const transformResponse = (input: unknown) => v.parse(AccessTokenSchema, input);

export const getAccessToken = async (
  getAccessTokenRequest: GetAccessTokenRequest,
) =>
  guestModeApiFetcher
    .post("oauth2/token", {
      body: transformRequest(getAccessTokenRequest),
    })
    .json()
    .then(transformResponse)
    .catch((error) => {
      logger.error({
        scope: "getAccessToken",
        message: error.message,
        stack: error.stack,
      });

      return { accessToken: "", tokenType: "", expiresIn: 0 };
    });
