import { useMutation, useQueryClient } from "@tanstack/react-query";
import { KyClient } from "~clients/fetch";
import { useChannel, useQueryChannel } from "~features/channel";
import type { Channel } from "~features/channel/channel.schema";
import { QUERY_KEYS } from "~features/providers/tanstack-query";
import { logger } from "~utils/logger";

export const useMutationToggleShared = () => {
  const queryClient = useQueryClient();
  const { channelId } = useChannel();
  const { data: channel } = useQueryChannel(channelId);

  return useMutation({
    mutationFn: toggleShared({ channelId }),

    onMutate: async () => {
      if (!channel) return;

      const queryKey = QUERY_KEYS.CHANNELS.getChannelQueryKey(channelId);
      await queryClient.cancelQueries({ queryKey });

      const prevIsSharing = channel.isSharing ?? false;

      queryClient.setQueryData<Channel>(queryKey, (prev) => {
        if (!prev)
          return {
            id: channelId,
            isShared: false,
            isSharing: true,
          } as unknown as Channel;

        return {
          ...prev,
          isSharing: !prevIsSharing,
        };
      });

      return {
        queryKey,
        prevIsSharing,
      };
    },

    onSuccess: async (_data, _variables, context) => {
      if (!context.queryKey) return;

      queryClient.refetchQueries({ queryKey: context.queryKey });
    },

    onError: (_err, _variables, context) => {
      logger.error({
        scope: "useMutationToggleShared.onError",
        data: _err,
      });

      if (!context?.queryKey) return;

      queryClient.setQueryData<Channel>(context.queryKey, (prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          isSharing: context.prevIsSharing,
        };
      });
    },
  });
};

const createApiPath = (channelId: string) => `channels/${channelId}/share`;

/**
 * @see {@link https://stage-api.alan.est.ai/docs#/Channels/share_channel_api_v1_channels__channel_id__share_post}
 * @example `POST /api/v1/channels/{channel_id}/share`
 */
const toggleShared =
  ({ channelId }: { channelId: string }) =>
  async () =>
    KyClient.post(createApiPath(channelId)).json();
