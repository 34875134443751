import { type PropsWithChildren, useEffect } from "react";
import { MenuBox } from "~features/ui/popup-menu/menu-box";
import { useShareContext } from "./share-context";
import styles from "./share-modal.module.scss";

export function ShareModal({ children }: PropsWithChildren) {
  const { setIsShareModalOpen } = useShareContext();

  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      if ((e.target as HTMLElement).closest("[data-modal='share']") !== null)
        return;

      setIsShareModalOpen(false);
    };

    window.addEventListener("click", onClick);
    return () => {
      window.removeEventListener("click", onClick);
    };
  }, [setIsShareModalOpen]);

  return (
    <MenuBox
      overrideClass={styles.share_box}
      width="320px"
      padding="20px"
      data-modal="share"
    >
      {children}
    </MenuBox>
  );
}
