import {
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { useUser } from "~features/auth";
import { createInfoToast } from "~features/toast";
import { CONTAINER_IDS } from "~features/toast/constants";
import { useMutationSanitizePrompt } from "./apis";
import type { SanitizedResult } from "./schemas";

type SanitizerContextType = {
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
  toggleEnabled: () => void;
  sanitizerResult?: SanitizedResult;
  setSanitizerResult: Dispatch<SetStateAction<SanitizedResult | undefined>>;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  checkSanitizer: (prompt: string) => Promise<{ isOk: boolean }>;
  isPendingSanitizer: boolean;
  setIsPendingSanitizer: (isPending: boolean) => void;
};

const SanitizerContext = createContext<SanitizerContextType | undefined>(
  undefined,
);

export const WithSanitizerContext = ({ children }: PropsWithChildren) => {
  const [enabled, setEnabled] = useState(true);
  const { isLoggedIn } = useUser();

  const [isPendingSanitizer, setIsPendingSanitizer] = useState(false);
  const [sanitizerResult, setSanitizerResult] = useState<
    SanitizedResult | undefined
  >();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mutateAsync: sanitizePrompt } = useMutationSanitizePrompt();
  const checkSanitizer = useCallback(
    async (prompt: string) => {
      /** @todo Refactor - 조건문 정리 ts-pattern */
      if (!enabled || !isLoggedIn || prompt.trim().length === 0) {
        return { isOk: true };
      }

      setIsPendingSanitizer(true);
      const { isSuccess, data } = await sanitizePrompt(prompt);
      setIsPendingSanitizer(false);

      if (!isSuccess || !data) {
        createInfoToast({
          containerId: CONTAINER_IDS.BOTTOM,
          toastId: "xllm-health-check-failed",
        })(
          "xLLM 서버가 바빠서 일시적으로 처리하지 못했어요.\n다시 시도해주세요.",
        );

        return { isOk: false };
      }

      if (isSuccess && data?.isValid) {
        return { isOk: true };
      }

      setSanitizerResult(data);
      setIsModalOpen(true);
      return { isOk: false };
    },
    [enabled, isLoggedIn, sanitizePrompt],
  );

  return (
    <SanitizerContext.Provider
      value={{
        enabled,
        setEnabled,
        toggleEnabled: () => setEnabled((prev) => !prev),
        isPendingSanitizer,
        setIsPendingSanitizer,
        sanitizerResult,
        setSanitizerResult,
        isModalOpen,
        setIsModalOpen,
        checkSanitizer,
      }}
    >
      {children}
    </SanitizerContext.Provider>
  );
};

export const useSanitizerContext = () => {
  const context = useContext(SanitizerContext);
  if (!context) {
    throw new Error(
      "useSanitizerContext must be used within a WithSanitizerContext",
    );
  }
  return context;
};
