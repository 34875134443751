import { useMutation, useQueryClient } from "@tanstack/react-query";
import { KyClient, isKyHttpError } from "~clients/fetch";
import { QUERY_KEYS } from "~features/providers/tanstack-query";
import { setDefaultErrorToast } from "~features/toast";
import { v } from "~libs/valibot";
import { logger } from "~utils/logger";

export const useMutationCopyChannel = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: copyChannel,

    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: QUERY_KEYS.HISTORY.ROOT,
      });
    },

    onError: async (error) => {
      let errorMessage = "채널 복사하기에 실패했습니다";

      // TODO: error 전처리 function
      if (isKyHttpError(error)) {
        const { message = "" } = await error.response.json<{
          message?: string;
        }>();
        errorMessage += message.length > 0 ? `:\n${message}` : "";
      }

      setDefaultErrorToast(errorMessage);

      logger.error({
        scope: "useMutationCopyChannel",
        message: error.message,
        stack: error.stack,
      });
    },
  });
};

const CopyChannelResponseSchema = v.pipe(
  v.object({
    result: v.boolean(),
    data: v.optional(
      v.object({
        new_channel_id: v.string(),
      }),
      { new_channel_id: "" },
    ),
  }),

  v.transform((input) => ({
    isCopySuccess: input.result,
    newChannelId: input.data.new_channel_id,
  })),
);

const transformResponse = (response: unknown) =>
  v.parse(CopyChannelResponseSchema, response, { abortEarly: true });

const createApiPath = (channelId: string) => `channels/${channelId}/copy`;

const copyChannel = async (channelId: string) =>
  KyClient.post(createApiPath(channelId)).json().then(transformResponse);
