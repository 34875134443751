import { KyClient } from "~clients/fetch";
import { TIMES } from "~constants/times";
import {
  type SendMessageRequest,
  transformSendMessageRequest,
  transformSendMessageResponse,
} from "./send-message-options.schema";

const createMessageApiPath = (channelId: string) =>
  `channels/${channelId}/messages`;

export const sendMessage = async (request: SendMessageRequest) => {
  const transformed = transformSendMessageRequest(request);

  return KyClient.post(createMessageApiPath(request.channelId), {
    json: transformed,
    timeout: TIMES.SEC * 30,
    retry: 0,
  })
    .json()
    .then(transformSendMessageResponse);
};
