import { match } from "ts-pattern";
import { getFromNow } from "~features/date-time";
import {
  MESSAGE_ERROR_CONTENTS,
  MESSAGE_STATUS$SERVER,
  MESSAGE_STOP_REASON$SERVER,
} from "~features/messages/constants";
import type { Message$Api } from "~features/messages/schemas";
import { Status } from "~features/util-types/status";
import type { SearchResult } from "./search-results.types";

export const transformInitResult = (item: Message$Api): SearchResult.Item => {
  const { status, statusMessage } = transformStatusWithContent(
    item.type,
    item.stop_reason,
  );

  return {
    id: item._id,
    messageId: {
      user: item._id,
      assistant: "",
    },
    requestId: item.request_id,
    title: item.content,
    status,
    toolSteps: [],
    content: status === Status.ERROR ? statusMessage : "",
  };
};

export const transformStatusWithContent = (
  type: MESSAGE_STATUS$SERVER | null,
  stopReason: MESSAGE_STOP_REASON$SERVER | null,
) => {
  return match({ type, stopReason })
    .with({ stopReason: MESSAGE_STOP_REASON$SERVER.STOP }, () => ({
      status: Status.SUCCESS,
      statusMessage: "",
    }))
    .with(
      {
        type: MESSAGE_STATUS$SERVER.ANSWER_COMPLETE,
        stopReason: MESSAGE_STOP_REASON$SERVER.LENGTH,
      },
      () => ({
        status: Status.PAUSED_BY_LENGTH,
        statusMessage: "",
      }),
    )
    .with(
      { type: MESSAGE_STATUS$SERVER.CANCELED },
      {
        stopReason: MESSAGE_STOP_REASON$SERVER.CANCELED,
      },
      () => ({
        status: Status.ERROR,
        statusMessage: MESSAGE_ERROR_CONTENTS.canceled,
      }),
    )
    .with(
      { type: MESSAGE_STATUS$SERVER.CONTENT_FILTER },
      {
        stopReason: MESSAGE_STOP_REASON$SERVER.CONTENT_FILTER,
      },
      () => ({
        status: Status.ERROR,
        statusMessage: MESSAGE_ERROR_CONTENTS.content_filter,
      }),
    )
    .with({ type: MESSAGE_STATUS$SERVER.ERROR }, () => ({
      status: Status.ERROR,
      statusMessage: MESSAGE_ERROR_CONTENTS.error,
    }))
    .otherwise(() => ({
      status: Status.LOADING,
      statusMessage: "",
    }));
};

export const transformSteps = (
  actions$api: Message$Api["actions"],
  answerStatus: Status,
): SearchResult.ToolSteps[] => {
  const sorted = actions$api
    .filter(({ speak }) => !Array.isArray(speak))
    .sort(
      (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
    )
    .map(({ speak }, order) => ({
      order,
      status: Status.SUCCESS,
      speak: speak as string,
    }));

  if (answerStatus === Status.SUCCESS) {
    return sorted;
  }

  const lastIndex = sorted.length - 1;
  sorted[lastIndex].status = Status.LOADING;

  return sorted;
};

/**
 * @example
 * [1]
 * [^1^]
 * [1,2,3]
 */
export const sourceRegex = /\[(\^)?(\d+)(\s?\,\d+)*(\^)?\]/g;

export const transformReasoning = (
  reasoning: string,
  answerStatus: Status,
): SearchResult.ToolSteps[] => {
  const splitted = reasoning
    .trim()
    .replace(sourceRegex, "[(출처 $2)]()")
    .split("\n\n")
    .map((line) => line.trim())
    .filter((line) => line.length > 0);

  return splitted.map((speak, order) => ({
    speak,
    order,
    status: order === splitted.length - 1 ? answerStatus : Status.SUCCESS,
  }));
};

export const transformQuestions = (
  questions: Message$Api["suggest_questions"],
): SearchResult.Question[] => questions.map((question) => ({ question }));

export const transformWebSources = (
  sources: NonNullable<Message$Api["references"]>,
): SearchResult.WebSources => {
  const items = sources.sort((a, b) => Number(a.number) - Number(b.number));

  return {
    items: items.map((item) => {
      const id = String(
        typeof item.number === "string"
          ? Number.parseInt(item.number)
          : item.number,
      );
      const url = new URL(item.source);
      return {
        id,
        title: item.title,
        url: item.source,
        snippet: item.content,
        favicon: `https://www.google.com/s2/favicons?domain=${url.origin}`,
        source: url.host,
        updatedAt: getFromNow(item.date),
        thumbnailSrc: item.thumbnail ?? "",
      };
    }),
    total: items.length,
  };
};

export const transformVideos = (
  videos: NonNullable<Message$Api["videos"]>,
): SearchResult.VideoSources => {
  const items = videos.slice(0, 10);

  return {
    items: items.map((video) => ({
      id: video.video_id,
      title: video.title,
      source: video.channel_title,
      // TODO: 반응형으로 변경
      thumbnail: video.thumbnails[0].url,
      url: `https://youtube.com/watch?v=${video.video_id}`,
      videoId: video.video_id,
      updatedAt: video.publish_time ?? "",
    })),
    total: items.length,
  };
};

export const transformImages = (
  images: NonNullable<Message$Api["images"]>,
): SearchResult.ImageSources => {
  const items = images.slice(0, 10);

  return {
    items: items
      .sort((a, b) => a.number - b.number)
      .map((item) => {
        return {
          id: String(item.number),
          title: item.title,
          url: item.thumbnail_url,
          source: new URL(item.link).host,
          sourceUrl: item.link,
        };
      }),
    total: items.length,
  };
};
