import { useQuery } from "@tanstack/react-query";
import { KyClient } from "~clients/fetch";
import { TIMES } from "~constants/times";
import { useChannel } from "~features/channel";
import { QUERY_KEYS } from "~features/providers/tanstack-query";

export const useQuerySocketUrl = () => {
  const { channelId } = useChannel();

  return useQuery({
    enabled: channelId.length > 0,
    queryKey: QUERY_KEYS.SOCKET.getChannelSocketQueryKey(channelId),
    queryFn: () => fetchChannelSocketUrls(channelId).then(({ url }) => url),
    staleTime: TIMES.MIN * 1,
  });
};

/**
 * @example
 * ```json
 * {
      "baseUrl": "wss://pubsub-estsoft-algpt.webpubsub.azure.com/client/hubs/gepeto_dev",
      "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL3B1YnN1Yi1lc3Rzb2Z0LWFsZ3B0LndlYnB1YnN1Yi5henVyZS5jb20vY2xpZW50L2h1YnMvZ2VwZXRvX2RldiIsImlhdCI6MTcwODY3NDI3NiwiZXhwIjoxNzA4Njc0NTc2LCJyb2xlIjpbIndlYnB1YnN1Yi5qb2luTGVhdmVHcm91cC42NWQ4M2M5MWJjY2NlNzQzMjlhMzMzZTQiXX0.C0nWzsoa4-9MaRI6viscI55WgMIVoA7-h-YXPTXooOU",
      "url": "wss://pubsub-estsoft-algpt.webpubsub.azure.com/client/hubs/gepeto_dev?access_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL3B1YnN1Yi1lc3Rzb2Z0LWFsZ3B0LndlYnB1YnN1Yi5henVyZS5jb20vY2xpZW50L2h1YnMvZ2VwZXRvX2RldiIsImlhdCI6MTcwODY3NDI3NiwiZXhwIjoxNzA4Njc0NTc2LCJyb2xlIjpbIndlYnB1YnN1Yi5qb2luTGVhdmVHcm91cC42NWQ4M2M5MWJjY2NlNzQzMjlhMzMzZTQiXX0.C0nWzsoa4-9MaRI6viscI55WgMIVoA7-h-YXPTXooOU"
    }
 * ```
 */
export type TokenResponse = {
  baseUrl: string;
  token: string;
  url: string;
};

const fetchChannelSocketUrls = async (channelId: string) =>
  await KyClient.get("token/generate", {
    searchParams: { channelId },
  }).json<TokenResponse>();
