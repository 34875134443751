import { createFileRoute } from "@tanstack/react-router";
import { useNativeBridge } from "~clients/native-bridge";
import { EMPTY_USER, userQueryOptions } from "~features/auth";
import { useUserAgent } from "~features/user-agent";
import { v } from "~libs/valibot";
import { logger } from "~utils/logger";

const SearchResultSearchSchema = v.object({
  message_id: v.optional(v.string()),
});

export const Route = createFileRoute("/search/$channelId")({
  validateSearch: SearchResultSearchSchema,

  beforeLoad: async ({ context: { queryClient } }) => {
    if (
      !useUserAgent.getState().isWeb &&
      !useNativeBridge.getState().nativeBridge
    )
      return { isLoggedIn: false, userId: "" };

    return await queryClient
      .ensureQueryData(userQueryOptions)
      .catch((error) => {
        if (error instanceof Error) {
          logger.error({
            scope: "SearchResultChannelRoute.beforeLoad.userQuery",
            message: error.message,
            stack: error.stack,
          });
        }
        return EMPTY_USER;
      })
      .then((result) => {
        const userId = result?.id ?? "";
        return { isLoggedIn: userId.length > 0, userId };
      });
  },

  onError: (error) => {
    logger.error({
      scope: "SearchResultChannelRoute.onError",
      message: error.message,
      stack: error.stack,
    });
  },
});
