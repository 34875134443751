import Button from "~features/ui/button/button";
import { cn } from "~utils/class-names";
import styles from "./action-button.module.scss";

type ActionButtonProps = {
  iconSrc?: string;
  isOff?: boolean;
  isOn?: boolean;
  onClick?: React.MouseEventHandler;
};

const ActionButton: React.FC<ActionButtonProps> = (props) => {
  return (
    <Button
      overrideClass={cn(styles.btn_action, {
        [styles.off]: props.isOff,
        [styles.on]: props.isOn,
      })}
      ButtonType="onlyIcon"
      size="medium"
      iconBefore={<img src={props.iconSrc} alt="icon" />}
      onClick={props.onClick}
    />
  );
};

export default ActionButton;
