import { useCallback } from "react";
import { useUser } from "~features/auth";
import { usePersonas } from "~features/personas";
import {
  useCreateChannelAndNavigate,
  useMutationSendPromptWithNewChannel,
  useRequestValidation,
  validateRequestWithNewChannel,
} from "~features/prompt/mutations";
import { categorizePrompt } from "~features/prompt/utils";
import { useSendSearchTitle } from "~features/search";
import { useSidebar } from "~features/ui/side-bar";
import { xllmHooks } from "~features/xllm";

/** @todo 공통로직 분리 */
export const useSendSuggestion = () => {
  const { user } = useUser();
  const { matchValidationResult } = useRequestValidation();
  const { createChannelAndNavigate, getCreateChannelArgs } =
    useCreateChannelAndNavigate();
  const { mutate } = useMutationSendPromptWithNewChannel(user);
  const { requestNativeNotificationPermission } = useSendSearchTitle();
  const { persona } = usePersonas();

  const { enabled: xllmEnabled } = xllmHooks.useSanitizerContext();

  const { setIsOpen } = useSidebar();

  const sendSuggestion = useCallback(
    async (suggestion: string) => {
      const validationResult = validateRequestWithNewChannel({
        prompt: suggestion,
        user,
      });

      const categorized = categorizePrompt(suggestion);
      const createChannelArgs = getCreateChannelArgs({
        personaId: persona.id,
        hasUrl: categorized.hasUrl,
        hasYoutubeUrl: categorized.hasYoutubeUrl,
        xllmEnabled,
      });

      return matchValidationResult(validationResult, {
        withValid: () => {
          createChannelAndNavigate(createChannelArgs).then(
            ({ channelId, personaId, isForYoutubeSummary }) => {
              setIsOpen(false);

              mutate({
                prompt: suggestion,
                channelId,
                personaId,
                isForYoutubeSummary,
              });

              requestNativeNotificationPermission();
            },
          );
        },

        createOAuthRedirectUrl: () =>
          encodeURI(window.location.origin.concat(`/search?q=${suggestion}`)),
      });
    },
    [
      user,
      persona.id,
      xllmEnabled,
      getCreateChannelArgs,
      matchValidationResult,
      createChannelAndNavigate,
      mutate,
      requestNativeNotificationPermission,
      setIsOpen,
    ],
  );

  return {
    sendSuggestion,
  };
};
