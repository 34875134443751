import { useCallback } from "react";
import { PermissionNames, useNativeBridge } from "~clients/native-bridge";
import { useUserAgent } from "~features/user-agent";
import {
  getLocalStorageValue,
  setLocalStorageValue,
} from "~utils/localstorage-value";

const STORAGE_KEY = "gepeto.alreadyPermissionToPush";

/** @todo Refactor - RENAME */
export const useSendSearchTitle = () => {
  const { isIos } = useUserAgent();
  const { nativeBridge } = useNativeBridge();

  // TODO: 이것만 따로 분리?
  const requestNativeNotificationPermission = useCallback(() => {
    if (!nativeBridge) return;
    if (isIos) return;
    if (nativeBridge.hasPermission(PermissionNames.pushNotification)) return;

    const hasRequestedPermission = getLocalStorageValue<boolean | undefined>(
      STORAGE_KEY,
    );
    if (typeof hasRequestedPermission !== "undefined") return;

    nativeBridge
      .requestPermissionAsync({
        permission: PermissionNames.pushNotification,
        message: "답장이 왔을때 알려드릴까요?",
      })
      .then(({ granted }) => {
        setLocalStorageValue(STORAGE_KEY, granted);
      });
  }, [nativeBridge, isIos]);

  return {
    requestNativeNotificationPermission,
  };
};
